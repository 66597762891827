import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import DownArrow from '../../../entrypoints/images/chevron-right.svg'
import UpArrow from '../../../entrypoints/images/Path.svg'

const WeightToggle = ({ label, selectOptions, ...props}) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  let [err, setErr] = useState(meta.touched && meta.error)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    setErr(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  const handleClick = (e) => {
    setValue(e.target.value)
    setOpen(false)
  }

  let weightRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.parentNode && !e.target.parentNode.matches('.weight-dropdown')) {
        setOpen(false)
      }
    })
  }, [weightRef])

  return (
    <div className={'weight-dropdown relative font-reg w-full'} >
      <label htmlFor={props.id || props.name} className={`text-sm font-reg my-2 ${err && 'text-red-600 font-bold flex items-center'}`}>
        {label}
        {err &&
          <div className={'flex items-center'}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mx-2`} />
            <p className="text-sm font-reg text-red-600">{meta.error}</p>
          </div>
        }
      </label>
      <button
        type={'button'}
        onClick={(e) => { setOpen(!isOpen); e.target.focus() }}
        className={`weight-dropdown w-full h-10 flex justify-between items-center px-3 border ${err ? 'border-red-600' : 'border-gray'} bg-white text-sm font-reg`} id={props.id || props.name}
        ref={weightRef}
      >
        { field.value || "" }
        <img src={isOpen ? UpArrow : DownArrow} alt={isOpen ? 'close options' : 'open options'} className={'w-1/12 h-4 w-4'} />
      </button>
      {
        isOpen &&
        <div className={'weight-dropdown overflow-y-scroll border border-gray w-full bg-white flex flex-col absolute z-40 h-40'}>
          { selectOptions.map((opt) => {
            return (
              <button
                type={'button'}
                value={opt}
                className={`weight-dropdown h-10 w-full border border-gray font-reg flex items-center px-3 hover:border-2 hover:border-blue hover:text-almost-black hover:bg-transparent-blue text-medium-gray`}
                onClick={handleClick}
              >
                {opt}
              </button>
            )
          })}
        </div>
      }
    </div>
  )
}
export default WeightToggle
