import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

export default function CategoryCuration() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [topProducts, setTopProducts] = useState([])
  let urlRef = useRef(null)

  useEffect(() => {
    axios.get('/admin/top_sellers').then((res) => {
      setTopProducts(res.data.products)
    })
  }, [])

  const addToTag = (productId, tag) => {
    axios.post('/admin/add_to_tag', {
      product_id: productId,
      tag_name: tag,
    },
    {
      withCredentials: true,
    }).then((res) => {
      urlRef.current.value = ''
      setTopProducts(res.data.products)
    })
  }

  const removeFromTag = (productId, tag) => {
    axios.post('/admin/remove_from_tag', {
      product_id: productId,
      tag_name: tag,
    },
    {
      withCredentials: true,
    }).then((res) => {
      urlRef.current.value = ''
      setTopProducts(res.data.products)
    })
  }

  const addProduct = (product_url, e) => {
    let productId = product_url.split('/').pop()
    addToTag(productId, 'Top Sellers')
  }

  return(
    <div>
      <h1>Top Sellers</h1>
      <input className='w-80 ml-10 mt-5' type="text" id="product_url" ref={urlRef}/>
      <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={(e) => { addProduct(document.getElementById('product_url').value, e)} }>Add Product</button>
      <div className='grid grid-cols-12 w-full'>
        { topProducts.map((product) => (
          <>
            <div className='col-span-8 mt-5'>
              <div className='w-full h-10'>
                {product.name}
              </div>
              <hr className='mt-2'/>
            </div>
            <div className='col-span-2 mt-5'>
              <img src={product.photo_1_url} className='h-10'/>
                <hr className='mt-2'/>
            </div>
            <div className='col-span-2 mt-5'>
              <div className='w-full h-10'>
                <button className='ml-5 border border-1 h-10 px-5' onClick={(e) => { removeFromTag(product.id, 'Top Sellers')} }>Remove</button>
                <hr className='mt-2'/>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
