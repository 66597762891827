import React, { useEffect, useState } from 'react'
// this is formik form component that is a series of checkboxes to indicate which categories are selected
// this component is used in the CreateBrandProfile component

const CategoriesSelect = ({ categories, setFieldValue, values }) => {
  const [selectedCategories, setSelectedCategories] = useState([])
  useEffect(() => {
    if (values.categories) {
      setSelectedCategories(values.categories)
    }
  }, [values.categories])

  const handleSelect = (e) => {
    const selectedCategory = e.target.value
    let newSelectedCategories
    if (selectedCategories.includes(selectedCategory)) {
      newSelectedCategories = selectedCategories.filter(
        (category) => category !== selectedCategory
      )
    } else {
      newSelectedCategories = [...selectedCategories, selectedCategory]
    }
    setSelectedCategories(newSelectedCategories)
    setFieldValue('categories', newSelectedCategories)
  }

  return (
    <>
      <h2 className="text-sm font-reg mb-2">Which of the following categories do your products belong to?</h2>
    <div className="flex flex-wrap ml-1 mb-5">
      {categories.map((category) => (
        <div key={category} className="w-1/2">
          <label className="block text-sm font-reg">
            <input
              type="checkbox"
              value={category.id}
              checked={selectedCategories.includes(category.id)}
              onChange={handleSelect}
              className="mr-2"
            />
            {category}
          </label>
        </div>
      ))}
    </div>
    </>
  )
}
export default CategoriesSelect
