import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ref } from 'yup'
import Arrow from '../../../entrypoints/images/arrow-right.svg'
import HeadingText from "../molecules/HeadingText.tsx"
import TextInput from "../../molecules/inputs/TextInput.tsx"
import axios from 'axios'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'

const CreatePassword = (props) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const handleSubmit = (v) => {
    axios.put('/me', v).then(() => {
      props.submitted(true)
    })
  }

  return (
    <section className='w-full px-4'>
      <HeadingText text="create password" />
      <p className='text-center text-xs font-reg'>Minimum 8 characters</p>
      <Formik
        initialValues={{
          createPassword: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object({
          createPassword: Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(128, 'Maximum 128 characters')
            // .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=x*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/, 'Password must be between 8 and 32 characters with at least one letter, one number, and one special character.')
            .required('Required'),
          confirmPassword: Yup.string()
            .oneOf([ref("createPassword")], "Passwords do not match")
            .required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit({password: values.createPassword})
            setSubmitting(false)
          }, 400)
        }}
      >
        <Form>
          <TextInput
            label="Create Password*"
            name="createPassword"
            type="password"
            placeholder=""
          />

          <TextInput
            label="Confirm Password*"
            name="confirmPassword"
            type="password"
            placeholder=""
          />

          <div className={'w-80 mx-auto mt-6 mb-24'}>
            <CtaButton id={'create-password-button'} submit={true} >Create Password</CtaButton>
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default CreatePassword
