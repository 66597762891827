import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Paragraph1 from "~/components/atoms/text/Paragraph1"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons"

const UsRetailerMultiSelect = ({ selected, setSelected }: { selected?: string[], setSelected?: Function }) => {
  let [currentRetailers, setCurrentRetailers] = useState([])
  let [searchValue, setSearchValue] = useState('')
  let [visibleRetailers, setVisibleRetailers] = useState([])
  let [showExpandButton, setShowExpandButton] = useState(false)
  let [expanded, setExpanded] = useState(false)

  const checkboxStyles = "!appearance-none mr-2 border border-almost-black checked:border-blue checked:bg-blue"

  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content


  const getCurrentRetailerList = () => {
    axios.get('/profiles/us_retailers').then((r) => {
      setCurrentRetailers(r.data.current_retailers)
    })
  }

  const getVisibleRetailers = () => {
    let list = filterRetailers(currentRetailers)
    if (list.length > 5) {
      setShowExpandButton(true)
      list = !expanded ? clipRetailers(list) : list
    } else {
      setShowExpandButton(false)
    }
    setVisibleRetailers(list)
  }

  const filterRetailers = (arr) => {
    return arr.filter((r) => {
      return r.toLowerCase().includes(searchValue.toLowerCase())
    })
  }

  const clipRetailers = (arr) => {
    return arr.slice(0, 5)
  }

  const handleRetailerSelect = (retailer) => {
    if (selected.retailers.includes(retailer)) {
      setSelected(selected.retailers.filter((r) => r !== retailer))
    } else {
      setSelected([...selected.retailers, retailer])
    }
  }

  const checkAll = () => {
    selected.retailers.length !== currentRetailers.length
      ? setSelected(currentRetailers)
      : setSelected([])
  }

  useEffect(() => {
    currentRetailers.length && getVisibleRetailers()
  }, [searchValue, expanded, currentRetailers])

  useEffect(() => {
    getCurrentRetailerList()
  }, [])

  return (
    <React.Fragment>
      <div className={'flex'}>
        <input
          type={'search'}
          placeholder={'Search...'}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={`h-10 w-full mb-2 border-2 border-pink rounded-full font-reg text-almost-black focus:border-blue focus:outline-none focus:ring-transparent pl-6`}
        />
      </div>
      {
        !searchValue &&
        <div className={'w-fit flex items-center'}>
          <input
            type={'checkbox'}
            id={'retailer-checkbox-all'}
            checked={selected.retailers.length === currentRetailers?.length}
            onChange={checkAll}
            className={checkboxStyles}/>
          <label htmlFor={'retailer-checkbox-all'} className={'flex items-center'}>
            <p className={'text-almost-black text-sm font-bold'}>{ selected.retailers.length === currentRetailers?.length ? "Deselect All" : "Select All"}</p>
          </label>
        </div>
      }
      {
        visibleRetailers.map((retailerName, i) => {
        return (
          <div className={'flex items-center'}>
            <input
              type={'checkbox'}
              id={`retailer-checkbox-${i}`}
              checked={selected.retailers.includes(retailerName)}
              onChange={() => {
                handleRetailerSelect(retailerName)
              }}
              className={checkboxStyles}
            />
            <label htmlFor={`retailer-checkbox-${i}`}>
              <Paragraph1>{retailerName}</Paragraph1>
            </label>
          </div>
        )
        })
      }
      {
        showExpandButton &&
          <button onClick={() => { setExpanded(!expanded) }} className={`flex items-center border-b border-pink`}>
            <Paragraph1>{expanded ? "Show less" : "Show All"}</Paragraph1>
            <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} className={'ml-2 h-3 w-3 text-pink'} />
          </button>
      }
    </React.Fragment>
  )
}

export default UsRetailerMultiSelect
