import React from 'react'
import Kohls from "../entrypoints/images/kohls_logo.svg"
// import BigLots from "../entrypoints/images/ig_lots.jpeg"
import BigLots from "../entrypoints/images/Big_Lots_logo.png"
import Fye from "../entrypoints/images/fye.png"
import Heading2 from "../components/atoms/text/Header2.tsx"

const RetailerCards = () => {
  const retailers = [
    { name: `Kohl's`,
      logo: Kohls
    }, {
      name: 'Big Lots',
      logo: BigLots
    }, {
      name: 'FYE',
      logo: Fye
    }, {
      name: 'Announcing more soon!',
      logo: ''
    }, {
      name: 'Announcing more soon!',
      logo: ''
    }
  ]

  return retailers.map((r) => {
            return <article className={ "h-48 w-48 shrink-0 grid place-content-center" }>
                      { !r.logo && <Heading2 text={r.name} position={"text-center"} textColor={"dark"} /> }
                      <img src={r.logo} className={"w-full"} />
                    </article>
          })
}

export default RetailerCards
