import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextAreaInput from "~/components/onboarding/molecules/TextAreaInput"
import Checkbox from "../components/molecules/inputs/Checkbox.jsx"
import Header1 from '../components/atoms/text/Header1.tsx'
import Paragraph1 from "../components/atoms/text/Paragraph1.tsx"

const CreateReleaseNote = () => {

  let [checkboxErr, setCheckboxErr] = useState('')

  return (
    <section className={'w-3/4 lg:w-[1024px] mx-auto pt-4'}>
      <Header1>New Release Note</Header1>
      <Formik
        initialValues={{
          body: "",
          retailer: false,
          brand: false
        }}
        validationSchema={Yup.object({
          body: Yup.string()
            .required('Required'),
          retailer: Yup.boolean()
            .notRequired(),
          brand: Yup.boolean()
            .notRequired()
        })}
        onSubmit={(values, { resetForm } ) => {
          if (!values.retailer && !values.brand) {
            setCheckboxErr('Is this note for retailers, brands, or both?')
          } else {
            setTimeout(() => {
              axios.post("/release_notes", { release_notes: { body: values.body, retailer: values.retailer, brand: values.brand } }, { withCredentials: true }).then((res) => {
                alert('Release Note Created!')
                resetForm()
                setCheckboxErr('')
              })
            }, 400)
          }
      }}
      >
        {({ values }) => (
          <Form className={'my-6 flex flex-col'}>
            <TextAreaInput
              label={'Body:'}
              name={'body'}
            />
            <div className={`my-2 p-3 flex flex-col ${checkboxErr && "border-2 border-red-600"}`}>
              <Paragraph1 styles={'text-red-600'}>{checkboxErr}</Paragraph1>
              <Checkbox
                label={'Retailer:'}
                name={'retailer'}
                checked={values.retailer}
              />
              <Checkbox
                label={'Brand:'}
                name={'brand'}
                checked={values.brand}
              />
            </div>
            <button type={'submit'} >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default CreateReleaseNote
