import React from "react"
import { Link } from "react-router-dom"
import Logo from "../../../entrypoints/images/logo240.webp"

function LogoHeader() {
  return (
    <header className="grid place-content-center border-b-2 border-gray h-14">
      <Link to="/">
        <img src={Logo} alt={"made me buy it logo"} className='w-32'/>
      </Link>
    </header>
  )
}

export default LogoHeader
