import React, { useEffect, useContext, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { CurrentUserContext } from '../contexts/CurrentUser.js'
import BrandDashboard from '../components/dashboard/brand/BrandDashboard.jsx'
import RetailerDashboard from '../components/dashboard/retailer/RetailerDashboard.jsx'
import ProductsHome from '../components/organisms/ProductsHome.jsx'

const Dashboard = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)
  let [prodCount, setProdCount] = useState(null)
  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    if (currentUser?.brand) {
      axios.get('/stats').then((r) => {
        setProdCount(r.data.product_count)
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (!location.pathname.includes('csv_import') && !location.pathname.includes('/manage_products/new') && prodCount === 0) {
      navigate('/dashboard/manage_products')
    }
  }, [prodCount])

  return (
    <React.Fragment>
      { currentUser?.brand &&
        <section className={'w-[1024px] mb-6'}>
          <BrandDashboard />
        </section> }

      { currentUser?.retailer && <Outlet/> }
    </React.Fragment>
  )
}

export default Dashboard
