import React, {useEffect, useContext, useState} from 'react'
import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom'
import { CurrentUserContext } from '../../../contexts/CurrentUser.js'
import axios from 'axios'
import Nav from './organisms/DashboardNav.jsx'
import Stats from './Stats.jsx'
import Paragraph1 from '../../atoms/text/Paragraph1.tsx'
import Header1 from '../../atoms/text/Header1.tsx'
import EditIcon from '../../../entrypoints/images/edit-icon.svg'
import ConnectShopifyWidget from '../../molecules/ConnectShopifyWidget.tsx'

export default function BrandDashboard() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const location = useLocation()
  const { currentUser } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  // const showShopify = () => {
  //   return !currentUser.shopify_session_id && (location.pathname.includes('manage_products') || location.pathname.includes('brand_profile'))
  // }

  // useEffect(() => {
  //   showShopify()
  // }, [currentUser, location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (currentUser.status === 'provisional' || currentUser.status == 'denied') {
      navigate('/brand_inquiry/submitted')
      return
    } else if (currentUser.status === 'approved') {
      if (currentUser.brand?.description?.length === 0) {
        window.location.href = '/brand_inquiry/create_profile'
        //navigate('/brand_inquiry/create_profile')
        return
      }
    }
  },[])

    return(
      <section className={'w-full max-w-[1024px] mt-8'}>
        <header className={'h-14 w-full flex justify-between'}>
          <Header1 styles={'flex items-center'}>{currentUser?.brand?.name}</Header1>
          <Link to={'/dashboard/brand_profile'} className={'flex items-center'}>
            <img src={EditIcon} alt={'pen icon'} className={'h-6 w-6 mr-1.5'}/>
            <Paragraph1>Edit Profile</Paragraph1>
          </Link>
        </header>
        <Stats />
        <section className={`flex mt-6`}>
          <aside className={'w-1/3'}>
            <Nav  />
            {/*{*/}
            {/*  showShopify() && <ConnectShopifyWidget position={'w-11/12 mt-6'} />*/}
            {/*}*/}
          </aside>
          <Outlet />
        </section>
      </section>
    )
}
