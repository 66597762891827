import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import axios from 'axios'
import Paragraph1 from "../atoms/text/Paragraph1";

const PdfDownloader = ({ products }) => {
  let [generatingPdf, setGeneratingPdf] = useState(false)
  let [downloadingId, setDownloadingId] = useState(null)
  let [percentComplete, setPercentComplete] = useState(0)

  const generatePdf = () => {
    setGeneratingPdf(true)
    axios.post(
      '/pdf_downloads',
      {
        product_ids: products.map(p => p.id)
      },
      { withCredentials: true }
    ).then((res) => {
      setDownloadingId(res.data.id)
    })
  }

  const getPdfProgress = () => {
    axios.get('/pdf_downloads/' + downloadingId, { withCredentials: true }).then((res) => {
      setPercentComplete(res.data.percent_complete)
      if (!res.data.rendered) {
        setTimeout(() => {
          getPdfProgress()
        }, 200)
      } else {
        setDownloadingId(null)
        const link = document.createElement("a");
        link.href = res.data.aws_url
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setGeneratingPdf(false)
      }
    })
  }

  useEffect(() => {
    downloadingId && getPdfProgress(downloadingId)
  }, [downloadingId])

  return (
    <React.Fragment>
      {
        generatingPdf ?
          <div className={'h-12 w-60 flex flex-col items-center border-2 border-b-4 border-pink rounded-full bg-light-pink'}>
            <p className={'font-med text-dark-gray text-xs mb-0.5'}>{percentComplete == 100 ? "Preparing file for download" : "Generating PDF"}</p>
            <div className={'relative h-5 w-[90%] bg-white rounded-full flex justify-start items-center p-0.5'}>
              <div className={`h-full w-[${percentComplete}%] bg-pink rounded-full ${percentComplete == 100 && 'animate-pulse'}`}></div>
              <p className={`absolute right-3 font-med text-xs text-dark-gray ${percentComplete == 100 && "hidden"}`}>{`${percentComplete}%`}</p>
            </div>
          </div>
          :
          <div className={'h-12 w-60 border-b-4 border-blue rounded-full bg-blue'}>
            <button
              onClick={generatePdf}
              className={'h-full w-full flex items-center justify-center border-2 border-pink rounded-full bg-light-pink text-dark-gray text-xl font-reg transform -translate-y-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px'}
              id={'pdf-download-button'}
            >
              Download Product List
              <FontAwesomeIcon icon={faFilePdf} className={'text-pink ml-2'} />
            </button>
          </div>
      }
    </React.Fragment>
  )
}

export default PdfDownloader
