import React from 'react'
import Header2 from "../../components/atoms/text/Header2"
import Paragraph1 from "../../components/atoms/text/Paragraph1"

const CsvTemplateTable = () => {

  let templateData = [
    {
      column: 'UPC',
      description: 'The UPC identifier for each product.',
      required: true
    },
    {
      column: 'SKU',
      description: 'The SKU identifier for each product.',
      required: false
    },
    {
      column: 'Product Name',
      description: 'The title of the product. This will be displayed on search results and product pages.',
      required: true
    },
    {
      column: 'Unit MSRP',
      description: 'The individual suggested retail price for your product. In $9.99 or 9.99 format. Must be greater than 0.',
      required: true
    },
    {
      column: 'Unit Wholesale',
      description: 'The individual wholesale price for your product. In $9.99 or 9.99 format. Must be greater than 0. This will not be visible to retailers.',
      required: true
    },
    {
      column: 'Photo URL',
      description: 'A url to a photo of your product. For products with multiple photos, your csv should include individual rows for each product photo. At least one photo is required, and up to five are allowed. Files must be hosted with direct access to their raw content - services such as Google Drive, Imgur, or Ibb.co WILL NOT WORK, as they do not provide raw file access.',
      required: true
    },
    {
      column: 'Description',
      description: 'A minimum of 100 characters about your product.',
      required: true
    },
    {
      column: 'Length',
      description: 'Length of sellable unit in inches.',
      required: false
    },
    {
      column: 'Width',
      description: 'Width of sellable unit in inches.',
      required: false
    },
    {
      column: 'Height',
      description: 'Height of sellable unit in inches.',
      required: false
    },
    {
      column: 'Weight',
      description: "In pounds (lbs) OR ounces (oz) to one decimal place. Include only the number in this column and indicate the unit in the next column.",
      required: false
    },
    {
      column: 'Weight Unit',
      description: "Required for Weight value to be saved. 'lbs' or 'oz'",
      required: false
    },
    {
      column: 'Inner Quantity',
      description: 'If a carton contains multiple inner packs, the inner quantity is the number of sellable units in each inner pack. Otherwise, this value is 1.',
      required: false
    },
    {
      column: 'Number of Inners',
      description: 'If a carton contains multiple inner packs, this is the number of those packs. Otherwise, this is the number of sellable units in a carton.',
      required: false
    },
    {
      column: 'Outer Quantity',
      description: <React.Fragment><p>Total number of sellable units per carton.</p><p>Inner Quantity X Number of Inners = Outer Quantity.</p></React.Fragment>,
      required: false
    },
    {
      column: 'Lead Time Weeks',
      description: 'Estimated lead time in number of weeks.',
      required: false
    },
    {
      column: 'Category',
      description: 'Examples: Toys, Electronics, Home, Tools. These will not display but may be used to improve search functionality in future releases.',
      required: false
    }
  ]

  return (
    <article className={'w-11/12 mx-auto grid grid-cols-4 bg-white border border-gray rounded-2xl'}>
      <div className={'col-span-1 border-b border-r border-gray bg-light-gray px-3 py-2 rounded-tl-2xl'}>
        <Header2>Column</Header2>
      </div>
      <div className={'col-span-3 border-b border-gray bg-light-gray px-3 py-2 rounded-tr-2xl'} >
        <Header2>Description</Header2>
      </div>
      {templateData.map(({ column, description, required }) => (
        <React.Fragment>
          <div className={'col-span-1 flex border-b border-r border-gray p-3'}>
            <Paragraph1>{ column }</Paragraph1>
            { required ? <Paragraph1 styles={'text-pink'}>*</Paragraph1> : <Paragraph1 styles={''}>⁺</Paragraph1> }
          </div>
          <div className={'col-span-3 border-b border-gray p-3'} >
            <Paragraph1>{ description }</Paragraph1>
          </div>
        </React.Fragment>
      ))}
      <div className={'col-span-4 p-3'}>
        <p className={'font-reg text-pink'}>*Required: If a required field is missing, your product will be imported as provisional, and you will need to provide the missing data (in My Products) before it will be shown to retailers.</p>
        <p className={'font-reg'}>⁺Recommended: Products missing recommended fields will be published (visible to retailers), but retailers are more likely to find and buy your products if they include recommended fields.</p>
      </div>
    </article>
  )
}

export default CsvTemplateTable
