import React, { useRef, useState, useContext, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"
import axios from 'axios'
import SearchIcon from "~/entrypoints/images/magnifier.svg";
import X from "~/entrypoints/images/x.svg";
import Colors from "~/entrypoints/images/background-colors.svg";
import Paragraph1 from "~/components/atoms/text/Paragraph1";

const Searchbar = ({ width }: {width?: string}) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)

  let navigate = useNavigate()
  const searchBar = useRef()
  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  let [query, setQuery] = useState('')
  let [results, setResults] = useState([])

  let resetSearchBar = () => {
    searchBar.current.value = ''
    setSearchResultsOpen(false)
  }

  const getResults = () => {
    axios.post(`/marketplace/products/search`, {
      search: {
        name: query,
        description: query,
        brand: query
      }
    }).then((r) => {
      setResults(r.data.products)
    })
  }

  const submitQuery = (e) => {
    e.target.value = ''
    setQuery('')
    setResults([])
    resetSearchBar()
    navigate(`/all_products/search?query=${query}`)
  }

  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.value.length) {
      setSearchResultsOpen(true)
    } else {
      setSearchResultsOpen(false)
    }
    setQuery(e.target.value)
    e.key == 'Enter' && submitQuery(e)
    e.key == 'Escape' && setSearchResultsOpen(false)
  }

  const prepopSearch = () => {
    axios.post(`/marketplace/products/search`, {
      search: {
        query: ''
      }
    }).then((r) => {
      setResults(r.data.products)
    })
  }

  useEffect(() => {
    prepopSearch()
  }, [location])

  useEffect(() => {
    query && getResults()
  }, [query])

  useEffect(() => {
    setSearchResultsOpen(false)
    resetSearchBar()
  }, [location])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.parentNode && !e.target.parentNode.matches('.search-dropdown')) {
        setSearchResultsOpen(false)
      }
    })
  }, [searchBar])


  return (
    <div className={`search-dropdown h-12 w-full flex justify-center items-center relative`}>
      <input
        id={"header-search-bar"}
        type={"text"}
        placeholder={"Search..."}
        className={
          "h-full w-full border-2 border-pink rounded-full font-bold text-black placeholder-black focus:border-blue focus:outline-none focus:ring-transparent pl-6"
        }
        onKeyUp={(e) => {
          handleChange(e)
        }}
        ref={searchBar}
        onFocus={() => setSearchResultsOpen(true)}
      />
      <img src={SearchIcon} className={"absolute right-6 top-4"} />
      {results?.length && searchResultsOpen ? (
        <div
          className={
            "search-dropdown h-fit max-h-80 w-full border-x border-b border-gray bg-white absolute top-16 overflow-y-scroll z-40"
          }
        >
          <button
            className={"search-dropdown h-10 w-10 absolute top-0 right-0 z-40"}
            onClick={() => {
              setSearchResultsOpen(false)
            }}
          >
            {/*<span className={'text-sm text-gray-400 cursor-pointer mr-2'} onClick={() => {setSearchResultsOpen(false)}}>Close</span>*/}
            <img
              src={X}
              alt={"close search results"}
              className={"h-10 w-10"}
            />
          </button>
          {results?.map((product) => {
            return (
              <Link
                to={currentUser?.email ? `/all_products/${product.brand.id}/${product.id}` : `/sign_up`}
                onClick={resetSearchBar}
                className={"search-dropdown flex border-b border-gray hover:bg-light-pink"}
                id={'search-dropdown-product-link'}
              >
                <img
                  src={product.photo_1_url || Colors}
                  className={"h-14 w-14 mr-2"}
                />
                <div className={"flex flex-col justify-center"}>
                  <Paragraph1>{product.name}</Paragraph1>
                  <Paragraph1 styles={'text-medium-gray'}>{product.brand.name}</Paragraph1>
                </div>
              </Link>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default Searchbar
