import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import Header1 from "../../components/atoms/text/Header1.tsx"
import axios from 'axios'
import ProductCard from "../../components/molecules/ProductCard"
import { ProductsContext } from "../../contexts/Products.js"
import CtaButton from '../atoms/buttons/CtaButton.tsx'
import SmallModal from './modals/SmallModal.tsx'
import Paragraph1 from "~/components/atoms/text/Paragraph1";
import { CurrentUserContext } from "../../contexts/CurrentUser.js"
import ProductsSearchbar from '../organisms/headers/ProductsSearchbar.tsx'
import SortAndFilterBar from '~/components/SortAndFilterBar.jsx'
import PdfDownloader from '~/components/organisms/PdfDownloader.jsx'
import emojiButtons from '../../utils/emojiButtons.jsx'

const ProductsHome = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let {
    products, setProducts,
    favorites, setFavorites,
  } = useContext(ProductsContext)
  let { currentUser } = useContext(CurrentUserContext)

  let navigate = useNavigate()

  let [emojis, setEmojis] = useState(emojiButtons)
  let [selectedEmoji, setSelectedEmoji] = useState('All Products')
  let [query, setQuery] = useState(null)
  let [productCount, setProductCount] = useState(null)
  let [modalOpen, setModalOpen] = useState(false)
  let [headingText, setHeadingText] = useState('All Products')
  let [page, setPage] = useState(1)
  let [sort, setSort] = useState('newest')
  let [params, setParams] = useSearchParams()

  let defaultFilters = {
    msrp: {
      min: 0,
      max: 6,
    },
    followers: {
      min: 0,
      max: 3,
    },
    sales: {
      min: 0,
      max: 2,
    },
    leadtime: {
      min: 0,
      max: 6,
    },
    retailers: [],
  }

  let [filters, setFilters] = useState(defaultFilters)

  const filtersApplied = () => {
    return !(JSON.stringify(filters) === JSON.stringify(defaultFilters))
  }

  const handleSortChange = (value) => {
    if (value === 'msrp_cents_desc') {
      setSort('msrp_cents_desc')
    } else if (value === 'msrp_cents_asc') {
      setSort('msrp_cents_asc')
    } else if (value === 'newest') {
      setSort('newest')
    }
  }

  const handleEmojiClick = (e) => {
    setProductCount(null)
    setSelectedEmoji(e.target.value)
    navigate('/all_products?filter=' + e.target.value)
  }

  const handleCtaClick = () => {
    setModalOpen(true)
    axios.post('/submitted_favorites', { withCredentials: true })
  }

  const filtersParams = () => {
    let filterString = ''
    Object.keys(filters).forEach((key) => {
      if (key === 'retailers') {
        let arr = filters.retailers
        arr.forEach((el) => {
          filterString += `&retailers[]=${encodeURIComponent(el)}`
        })
      } else {
        filterString += `&filters_${key}_min=${filters[key].min}&filters_${key}_max=${filters[key].max}`
      }
    })
    return filterString
  }

  const getFavorites = () => {
    if (currentUser && currentUser.retailer?.id) {
      axios.get('/favorites').then((f) => {
        setProductCount(f.data.products.length)
        setHeadingText(`My Favorites: ${f.data.products.length} Product${f.data.products.length === 1 ? '' : 's'}`)
        let prods = f.data.products
        prods = prods.map((p) => {
          p.favorited = true
          return p
        })
        setProducts(prods)
      })
    }
  }

  useEffect(() => {
    setEmojis(emojiButtons(currentUser))
  }, [currentUser])

  useEffect(() => {
    let loadingProducts = true
    setProducts([])
    setProductCount(null)
    const loadTagged = async (tag) => {
      setQuery('')
      axios.post(`/marketplace/products/search?page=${page}&sort=${sort}${filtersParams()}`, { search: { tags: [tag]}}).then((pr) => {
        axios.get('/favorites').then((fr) => {
          let p = pr.data.products
          let f = fr.data.products
          if (currentUser && !!!currentUser.brand?.id) {
            p = p.map((prod) => {
              prod.favorited = f.map((fav) => fav.id).includes(prod.id)
              return prod
            })
            setFavorites(f)
          } else {
            setFavorites([])
          }
        })
        return pr.data
      }).then((productsResponse) => {
        if (loadingProducts) {
          if (filtersApplied()) {
            setHeadingText(`${productsResponse.count} Product${productsResponse.count === 1 ? '' : 's'}`)
            setProductCount(productsResponse.count)
          } else if (tag === 'All Products') {
            setHeadingText('All Products')
            setProductCount(null)
          } else {
            setHeadingText(`${tag}: ${productsResponse.count} Product${productsResponse.count === 1 ? '' : 's'}`)
            setProductCount(productsResponse.count)
          }
          if (page < productsResponse.total_pages + 1) {
            page = page + 1
            loadTagged(tag)
          }
          setProducts(products => [...products, ...productsResponse.products])
        }
      })
    }
    const getProductsWithFavorites = async () => {
      let productSort
      if (!!!sort.length) {
        productSort = 'newest'
      } else {
        productSort = sort
      }

      axios.post(`/marketplace/products/search?page=${page}&sort=${productSort}${filtersParams()}`).then((pr) => {
        axios.get('/favorites').then((fr) => {
          let p = pr.data.products
          let f = fr.data.products
          if (currentUser && !!!currentUser.brand?.id) {
            p = p.map((prod) => {
              prod.favorited = f.map((fav) => fav.id).includes(prod.id)
              return prod
            })
            setFavorites(f)
          } else {
            setFavorites([])
          }
        })
        return pr.data
      }).then((productsResponse) => {
        if (loadingProducts) {
          if (filtersApplied()) {
            setHeadingText(`${productsResponse.count} Product${productsResponse.count === 1 ? '' : 's'}`)
            setProductCount(productsResponse.count)
          } else {
            setProductCount(null)
            setHeadingText('All Products')
          }
          if (page < productsResponse.total_pages + 1) {
            page = page + 1
            getProductsWithFavorites()
          }
          setProducts(products => [...products, ...productsResponse.products])
        }
      })
    }

    const getSearchResultProducts = async (search) => {
      let productSort
      if (!!!sort.length) {
        productSort = 'newest'
      } else {
        productSort = sort
      }

      axios.post(
        `/marketplace/products/search?page=${page}&sort=${productSort}${filtersParams()}`,
        {
          search: {
            name: search,
            description: search,
            brand: search,
          }
        }
      ).then((pr) => {
        axios.get('/favorites').then((fr) => {
          let p = pr.data.products
          let f = fr.data.products
          if (currentUser && !!!currentUser.brand?.id) {
            p = p.map((prod) => {
              prod.favorited = f.map((fav) => fav.id).includes(prod.id)
              return prod
            })
            setFavorites(f)
          } else {
            setFavorites([])
          }
          return pr.data
        }).then((productsResponse) => {
          if (loadingProducts) {
            setProductCount(productsResponse.count)
            setHeadingText(`Search Results: ${productsResponse.count} Product${productsResponse.count === 1 ? '' : 's'}`)
            if (page < productsResponse.total_pages + 1) {
              page = page + 1
              getSearchResultProducts(search)
            }
            setProducts(products => [...products, ...productsResponse.products])
          }
        })
      })
    }

    if (params.get('filter')) {
      let filter = params.get('filter')
      setSelectedEmoji(filter)
      if (filter === 'My Favorites') {
        getFavorites()
        return
      } else if (filter === 'All Products') {
        getProductsWithFavorites()
      } else {
        loadTagged(filter)
      }
    } else if (params.get('query')) {
      setSelectedEmoji('All Products')
      let query = params.get('query')
      getSearchResultProducts(query)
    } else {
      setSelectedEmoji('All Products')
      getProductsWithFavorites()
    }

    return () => { loadingProducts = false }
  }, [params, sort, filters])

  return (
    <section className={'w-full pt-24 pr-8 pl-52'}>
      <header className={`fixed top-20 left-0 w-full py-4 px-8 flex ${ selectedEmoji === 'My Favorites' ? 'justify-start' : 'justify-between' } gap-3 lg:gap-x-0 z-50`}>
        <Header1 styles={'w-2/5 xl:w-1/3 flex mt-3'}>{ headingText }</Header1>
        {
          selectedEmoji === 'My Favorites' ?
            <div className={'w-3/5 xl:w-2/3 flex justify-start gap-x-2 xl:gap-x-4 mt-1.5'}>
              <div className={'w-60'}>
                <CtaButton id={'favorites-get-quote-button-top'} onclick={handleCtaClick}>Get Me a Quote</CtaButton>
              </div>
              { products?.length ? <PdfDownloader products={products} /> : null }
            </div>
            :
            <React.Fragment>
              <div className={'h-12 w-1/5 xl:w-1/3 rounded-full shadow-2xl mt-1.5'}>
                <ProductsSearchbar query={query} setQuery={setQuery} />
              </div>
              <div className={'w-2/5 xl:w-1/3 flex justify-end items-center'}>
                  <SortAndFilterBar filters={filters} setFilters={setFilters} sort={sort} handleSortChange={handleSortChange} numProducts={productCount} />
              </div>
            </React.Fragment>
        }
      </header>
      <aside className={'fixed top-40 left-0 pl-8 flex flex-col items-start mr-2 !overflow-y-auto h-[75vh] w-60'}>
        {
          emojis?.map((emoji) => {
            return (
              <button
                onClick={handleEmojiClick}
                value={emoji.tag}
                className={`font-reg text-base my-2 ${selectedEmoji === emoji.tag ? 'text-almost-black' : 'text-medium-gray hover:translate-x-1 hover:drop-shadow-lg' }`}>
                { emoji.text }
              </button>
            )
          })
        }
      </aside>
      <section className={'w-full flex justify-center flex-wrap gap-6 z-10'}>
        {
          products.map((p) => {
            return <ProductCard product={p} />
          })
        }
      </section>
      {
        modalOpen &&
        <SmallModal isOpen={modalOpen} setIsOpen={setModalOpen}>
          <div className={'flex flex-col items-center mt-12'}>
            <Header1 styles={'text-center'}>Your request has been submitted!</Header1>
            <Paragraph1 styles={'mt-4 mb-12 text-center'}>We will email you with details and pricing within 24 hours!</Paragraph1>
            <CtaButton id={'quote-modal-close'} onclick={() => setModalOpen(false)}>Back to My Favorites</CtaButton>
          </div>
        </SmallModal>
      }
    </section>
  )
}

export default ProductsHome
