import React, { useContext, useRef, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Paragraph1 from '../atoms/text/Paragraph1.tsx'
import Colors from '../../entrypoints/images/background-colors.svg'
import FormatMoney from "../../utils/FormatMoney"
import { CurrentUserContext } from "../../contexts/CurrentUser.js"
import { ProductsContext } from "../../contexts/Products.js"
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons'
import HeartSolid from "~/entrypoints/images/favorited-heart.svg";
import HeartOutline from "~/entrypoints/images/unfavorited-heart.svg";
import FavHeart from "~/entrypoints/images/favorited-heart.svg";
import UnfavHeart from "~/entrypoints/images/unfavorited-heart.svg";
import CardRequestForSample from "~/components/molecules/CardRequestForSample";

const ProductCard = ({product, removePick}: {product: object, removePick?: Function}) => {
  let { favorites, setFavorites } = useContext(ProductsContext)
  const { currentUser } = useContext(CurrentUserContext)
  let isMounted = useRef(false)
  let [imageLoading, setImageLoading] = useState(true)
  let location = useLocation()

  // const handleLoaded = () => {
  //   setImageLoading(false)
  // }

  useEffect(() => {
    //setImageLoading(true)
  }, [product])

  let favorite = () => {
    if (product.favorited) {
      axios.delete('/favorites/' + product.id, { withCredentials: true}).then(() => {
        product.favorited = false
        setFavorites(favorites.filter((f) => f.id !== product.id))
      })
    } else {
      axios.post(`/favorites`, {id: product.id}, {withCredentials: true}).then(() => {
        product.favorited = !product.favorited
        setFavorites([...favorites, product])
      })
    }
  }

  const handleDelete = () => {
    axios.delete(`/curated_picks/${currentUser.id}/${product.id}`, { withCredentials: true }).then(() => {
      removePick()
    })
  }

  const ellipsedName = (n) => {
    if (n.length > 40) {
      return n.slice(0, 40) + '...'
    } else {
      return n
    }
  }

  return (
    <section className={`h-fit w-48 shrink-0 flex flex-col bg-transparent-white border border-gray relative -z-100`} id={`product-${product.id}-card`}>
      { imageLoading &&
        <div className={'h-96 w-full grid place-content-center absolute top-0 left-0'}>
          <FontAwesomeIcon icon={faSpinner} spinPulse className={`h-14 w-14 text-pink`} />
        </div>
      }
      <div  className={`w-full flex flex-col ${imageLoading && 'invisible'}`}>
        {
          currentUser?.email && currentUser?.retailer && !location.pathname.includes('my_picks') &&
          <button className={'absolute -top-3 -right-3 h-10 w-10 bg-inherit grid place-content-center'} onClick={favorite} id={'product-card-favorite-button'}>
            {/*<img src={ product.favorited ? FavHeart : UnfavHeart } alt={ product.favorited ? 'favorited' : 'not favorited'} className={`${!product.favorited && 'hover:animate-ping'}`}/>*/}
            {
              product?.favorited ?
                <React.Fragment>
                  <img src={HeartSolid} alt={'remove from favorites'} title={'remove from favorites'} className={"h-10 w-10 z-40"}/>
                </React.Fragment>
                :
                <React.Fragment>
                  <img src={HeartSolid} alt={'add to favorites'} title={'add to favorites'} className={"h-10 w-10 opacity-0 hover:animate-ping absolute hover:opacity-75 z-40"}/>
                  <img src={HeartOutline} alt={'add to favorites'} title={'add to favorites'} className={"h-10 w-10 relative z-30"}/>
                </React.Fragment>
            }
          </button>
        }
        {
          location.pathname.includes('my_picks') &&
          <button
            className={'absolute -top-3 -right-3 h-8 w-8 shadow-xl rounded-full bg-white grid place-content-center'}
            onClick={handleDelete}
            title={'Not interested, remove from My Picks'}
          >
            <FontAwesomeIcon icon={faXmark} className={`h-5 w-5 text-red-600`} />
          </button>
        }
        { product && <Link to={`/all_products/${product.brand_id}/${product.id}`}>
          <div className={'h-44 w-full bg-white flex justify-center items-center overflow-clip '}>
            <img src={product.photo_1_url || Colors} alt={'brand image'} onLoad={() => setImageLoading(false)} loading={"lazy"} className={`w-full ${imageLoading ? 'image-loading invisible' : 'visible'}`}/>
          </div>
            <div className={'h-40 w-full p-3 flex flex-col justify-between border-b border-gray'}>
              <Paragraph1>{ellipsedName(product.name)}</Paragraph1>
              <div className={'flex flex-col'}>
                {
                  currentUser?.retailer && <Paragraph1 styles={'text-medium-gray'}>{(product.brand.curated && product.brand.name == 'MadeMeBuyIt') ? 'IMU: 70%' : 'IMU: ~60%'}</Paragraph1>
                }
                <Paragraph1 styles={'text-medium-gray'}>{`MSRP: $${FormatMoney(product.msrp_cents)}`}</Paragraph1>
              </div>
            </div>
        </Link> }
        { product && <Link to={`/all_brands/${product.brand_id}`}>
          <Paragraph1 styles={'w-full text-center p-3'}>{product.brand?.name}</Paragraph1>
        </Link> }
        { !!!currentUser.brand && (
        <div className={'p-2'} >
          <CardRequestForSample product={product}/>
        </div>
        )}
      </div>
    </section>
  )
}
export default ProductCard
