import React from 'react'

export default function Marquee() {
  const goToSamples = () => {
    window.location.href = '/samples'
  }

  return (
    <div className='grid grid-cols-12 w-full bg-white'>
      <div className='col-span-12 bg-gray-800 text-pink font-bold text-center py-2 mt-20 -mb-20 bg-transparent-blue'>
        <marquee scrollamount={7} className='mt-2 cursor-pointer' onClick={goToSamples}>
          NEW! Daily Leads Service! Get your product samples into buyers' hands! <span className='underline cursor-pointer' onClick={goToSamples}>Sign up now!</span>
        </marquee>
      </div>
    </div>
  )
}
