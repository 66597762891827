import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Header1 from "../../components/atoms/text/Header1.tsx"
import Paragraph1 from "../../components/atoms/text/Paragraph1.tsx"
import Header2 from "../../components/atoms/text/Header2.tsx"
import TikTok from '../../entrypoints/images/tiktok-icon.svg'
import Insta from '../../entrypoints/images/insta-icon.svg'
import ProductCard from "~/components/molecules/ProductCard";
import ProductCard from "../../components/molecules/ProductCard.tsx";
import RequestForSample from '../molecules/RequestForSample.tsx'
import { CurrentUserContext } from '../../contexts/CurrentUser.js'

const BrandStore = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const params = useParams()
  let [brand, setBrand] = useState()
  let [products, setProducts] = useState([])
  let [currentTag, setCurrentTag] = useState('All Products')
  let [retailersOpen, setRetailersOpen] = useState(false)

  const { currentUser } = useContext(CurrentUserContext)

  const filterButtons = [
    {
      text: '🛍️ All Products', tag: 'All Products'
    }, {
      text: '🦄 Top Sellers', tag: 'Top Sellers'
    },
    {
      text: '😜 Novelty',
      tag: 'Novelty'
    },
    {
      text: '💪🏻 Consumables',
      tag: 'Consumables'
    },
    {text: '💵 Under $10', tag: 'Under $10'},
    {text: '🤳 1M+ Followers', tag: '1M+ Followers'},
    {text: '🚀 $5M+ Sales', tag: '$5M+ Sales'},
    {
      text: '🧸 Toys & Games',
      tag: 'Toys'
    },
    {
      text: '🌊 Wellness',
      tag: 'Wellness'
    },
    {
      text: '🧴 Skin & Beauty',
      tag: 'Beauty'
    },
    {
      text: '🏠 Home',
      tag: 'Home'
    },
    {
      text: '🍼 Baby',
      tag: 'Baby'
    },
    {
      text: '🐶 Pets',
      tag: 'Pet'
    },
    {
      text: '🔨 Tools',
      tag: 'Tools'
    },
    { text: '⚡️ Electronics', tag: 'Electronics' },
    {
      text: '🖊️ Stationery', tag: 'Stationery'
    },
    {
      text: '🏋️ Exercise',
      tag: 'Exercise'
    },

  ]

  const makeProductFilters = () => {
    let styles = 'font-reg text-base my-2'
    let active = 'text-almost-black'
    let inactive = 'text-medium-gray'

    let pfs = filterButtons.map((f) => {
      return (
        <button onClick={() => setCurrentTag(f.tag)} className={currentTag === f.tag ? active + " " + styles : inactive + " " + styles}>
          {f.text}
        </button>
      )
    })

    return pfs
  }

  let [page, setPage] = useState(1)
  let [sort, setSort] = useState('newest')
  let [productCount, setProductCount] = useState(0)

  useEffect(() => {
    let loadingProducts = true

    const loadTagged = async (tag) => {
      axios.post(`/marketplace/products/search?page=${page}&sort=${sort}`, { search: { tags: [tag], brand_ids: [params.brand_id]}}).then((pr) => {
        setProductCount(pr.data.count)
        if (loadingProducts) {
          if (page === 1) {
            setProducts(pr.data.products)
          } else if (page < pr.data.total_pages + 1) {
            page = page + 1
            loadTagged(tag)
            setProducts(products => [...products, ...pr.data.products])
          }
        }
      })
    }

    if (currentTag !== 'All Products') {
      loadTagged(currentTag)
    } else {
      getAllProducts()
    }
    return () => { loadingProducts = false }
  }, [currentTag])

  const getAllProducts = () => {
    axios.get(`/marketplace/brands/${params.brand_id}`).then((r) => {
      setBrand(r.data)
      let prods = r.data.published_products.map((p) => {
        p.brand = r.data
        return p
      })
      if (!currentUser.email) {
        setProducts(prods)
        return
      }
      axios.get('/favorites').then((fr) => {
        let f = fr.data.products
        if (currentUser && !!!currentUser.brand?.id) {
          prods = prods.map((prod) => {
            prod.favorited = f.map((fav) => fav.id).includes(prod.id)
            return prod
          })
        }
        return prods
      }).then((favoritedProducts) => {
        setProducts(favoritedProducts)
      })
    })
  }

  useEffect(() => {
    getAllProducts()
    makeProductFilters()
  }, [])

  return (
    <section className={'h-full w-[1024px] bg-none mt-8'}>
      <header className={'flex text-almost-black text-sm font-reg'}>
        <Link to={'/marketplace'} className={'hover:underline'}>Home</Link>
        <p className={'mx-2'}>/</p>
        <p>{brand?.name}</p>
      </header>
      <section className={'pb-6 border-b border-white mt-4'}>
        <img src={brand?.banner_url} alt={'brand banner'} className={'h-[300px] w-[1165px] bg-white border-x border-gray'}/>
        <div className={'h-52 w-full flex mt-2'}>
          <div className={'h-full w-4/5 flex'}>
            <img src={brand?.logo_url} alt={'brand logo'} className={'h-52 w-52 shrink-0 bg-white border border-gray'}/>
            <section className={'w-full bg-white border border-gray mx-2 p-3 overflow-hidden'}>
              <div className={'w-full flex justify-between'}>
                <Header1>{brand?.name}</Header1>
                <div className={'flex'}>
                  { brand?.tiktok_handle?.length > 1 &&
                  <div className={'h-8 w-fit flex mr-2 border border-gray'}>
                    <Link to={`https://www.tiktok.com/${brand?.tiktok_handle}`} target={'_blank'} className={'h-full w-8 grid place-content-center border-r border-gray'}>
                      <img src={TikTok} alt={'tiktok logo'} className={'h-4 w-4'}/>
                    </Link>
                    <div className={'h-full p-2 grid place-content-center'}>
                      <p className={'font-reg text-sm text-almost-black'}>{brand?.tiktok_count}</p>
                    </div>
                  </div>
                  }
                  { brand?.insta_handle?.length > 1 &&
                  <div className={'h-8 w-fit flex mr-2 border border-gray'}>
                    <Link to={`https://www.instagram.com/${brand?.insta_handle}`} target={'_blank'} className={'h-full w-8 grid place-content-center border-r border-gray'}>
                      <img src={Insta} alt={'instagram logo'} className={'h-4 w-4'}/>
                    </Link>
                    <div className={'h-full p-2 grid place-content-center'}>
                      <p className={'font-reg text-sm text-almost-black'}>{brand?.instagram_count}</p>
                    </div>
                  </div>
                  }
                </div>
              </div>
              <Paragraph1 styles={'mt-2'}>{brand?.description}</Paragraph1>
            </section>
          </div>
          <aside className={'h-full w-1/5 bg-transparent-white p-2 border border-gray'}>
            { brand?.tiktok_handle?.length > 1 &&
            <Link to={`https://www.tiktok.com/${brand?.tiktok_handle}`} target={'_blank'} className={'flex flex-col items-center flex-wrap  border-b border-gray pb-1 mb-1'}>
              <Paragraph1 styles={'text-medium-gray'}>TikTok</Paragraph1>
              <Paragraph1>{brand?.tiktok_handle}</Paragraph1>
            </Link>
            }
            { brand?.insta_handle?.length > 1 &&
            <Link to={`https://www.instagram.com/${brand?.insta_handle}`} target={'_blank'} className={'flex flex-col items-center flex-wrap border-b border-gray pb-1 mb-1'}>
              <Paragraph1 styles={'text-medium-gray'}>Instagram</Paragraph1>
              <Paragraph1>{`@${brand?.insta_handle}`}</Paragraph1>
            </Link>
            }
            <div className={'flex flex-col items-center flex-wrap border-b border-gray pb-1 mb-1'}>
              <Paragraph1 styles={'text-medium-gray'}>Annual Sales</Paragraph1>
              <Paragraph1>{brand?.annual_sales}</Paragraph1>
            </div>
            <div className={'flex flex-col items-center flex-wrap pb-1 mb-1 relative'}>
              <Paragraph1 styles={'text-medium-gray'}>Sold in</Paragraph1>
              {
                !brand?.current_retailer_relationships.length ?
                  <div>
                    <Paragraph1>Undisclosed</Paragraph1>
                  </div>
                  : brand?.current_retailer_relationships.length === 1 ?
                    <div>
                      <Paragraph1>{brand?.current_retailer_relationships[0]}</Paragraph1>
                    </div>
                    :
                    <div className={'relative w-full flex justify-center items-end'} onMouseEnter={() => setRetailersOpen(true)} onMouseLeave={() => setRetailersOpen(false)} >
                      <Paragraph1 styles={'text-center underline'}>View retailers</Paragraph1>
                      {
                        retailersOpen &&
                        <div className={'absolute bottom-6 right-0 h-fit w-60 shadow-xl p-6 bg-white flex flex-col justify-center items-center'}>
                          <Header2>Sold in:</Header2>
                          {
                            brand?.current_retailer_relationships.map((crr) => {
                              return <Paragraph1>{crr}</Paragraph1>
                            })
                          }
                        </div>
                      }
                    </div>
              }
            </div>
          </aside>
        </div>
      </section>

      <section className={'my-6 flex'}>
        <aside className={'w-1/5 flex flex-col items-start'}>
          { makeProductFilters() }
        </aside>
        <section className={'w-4/5'}>
          <div className={'grid grid-cols-2'}>
            <div>
              <Header1 styles={'mb-6'}>{`All ${brand?.name} products`}</Header1>
            </div>
            { !!!currentUser?.brand && (
              <div className={'flex justify-end -mt-2 ml-24'}>
                <RequestForSample brandId={brand?.id}/>
              </div>
            )}
          </div>
          <div className={'w-full grid grid-cols-4 gap-x-4 gap-y-4'}>
            {
              products?.map((p) => {

                return <ProductCard product={p} />
              })
            }
          </div>
        </section>
      </section>
    </section>
  )
}

export default BrandStore
