import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form } from "formik"
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import Header1 from '../../atoms/text/Header1.tsx'
import Overline from '../../atoms/text/Overline.tsx'
import Paragraph1 from '../../atoms/text/Paragraph1.tsx'
import TextInput from '../../molecules/inputs/TextInput.tsx'
import Arrow from "../../../entrypoints/images/arrow-right.svg"
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"
import Auth from '../../../utils/Auth.js'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import axios from 'axios'
import SecondaryCtaLink from "~/components/atoms/buttons/SecondaryCtaLink";

const SignInModal = ({ setIsOpen }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const [error, setError] = useState(false)
  const [reset, setReset] = useState(false)
  const auth = Auth(setCurrentUser)
  const navigate = useNavigate()

  const logIn = (values, setSubmitting) => {
    auth.signIn(
      values.email,
      values.password
    ).then((e) => {
      if (e) {
        setError(true)
      }
    })
  }

  useEffect(() => {
    if (currentUser?.brand ) {
      setIsOpen(false)
      navigate('/dashboard')
    } else if (currentUser?.retailer) {
      setIsOpen(false)
      navigate('/marketplace')
    }
  }, [currentUser])

  return (
    <section className={'h-full w-full p-1 xs:p-2 sm:p-4 flex flex-col justify-between'}>
      <section className={'h-96 w-full flex flex-col justify-between'}>
        <header className={'flex flex-col items-center'}>
          <Overline>Welcome Back!</Overline>
          <Header1 styles={'text-center'}>Sign in to explore our marketplace</Header1>
        </header>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
            .email('Please enter a valid email address')
            .required('Required'),
            password: Yup.string()
            .required('Required')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              logIn(values, setSubmitting)
            }, 400);
          }}
        >
          {({ values }) => (
            <Form className={'h-64 flex flex-col justify-between'}>
              <div>
                <TextInput
                  label="Email Address*"
                  name="email"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <TextInput
                  label="Password*"
                  name="password"
                  type="password"
                  placeholder=""
                />
              </div>
              <p className='text-red-500 font-reg text-sm'>
                {error ? 'Incorrect email or password' : ''}
                {reset ? 'If there is an account associated with this email address, a link has been sent to reset the password' : ''}
              </p>
              <Link to={'/forgot_password'}>
                <Paragraph1 styles={'text-medium-gray'}>Forgot Password?</Paragraph1>
              </Link>
              <div className={'w-60 mx-auto'}>
                <CtaButton id={'sign-in-continue-button'} submit={true}>Continue</CtaButton>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <div className={'w-full mt-4 border-t-2 border-almost-black border-dashed'}>&nbsp;</div>

      <section className={'w-60 mx-auto flex flex-col items-center gap-y-4'}>
        <SecondaryCtaLink id={'sign-in-modal-brand-inquiry-link'} link={'/brand_inquiry'} >
          Become a Brand
        </SecondaryCtaLink>
        <SecondaryCtaLink id={'sign-in-modal-retailer-inquiry-link'} link={'/retailer_inquiry'} >
          Become a Retailer
        </SecondaryCtaLink>
      </section>
    </section>
  )
}

export default SignInModal
