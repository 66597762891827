import React, { useState, useContext, useEffect } from 'react'
import Overline from "../../components/atoms/text/Overline.tsx"
import CtaLink from '../atoms/buttons/CtaLink.tsx'
import NewStickerYellow from '../../entrypoints/images/new-sticker-yellow.svg'
import { CurrentUserContext } from "~/contexts/CurrentUser"
import Searchbar from '../organisms/headers/Searchbar.tsx'

const Banner = () => {

  let { currentUser } = useContext(CurrentUserContext)

  let [pics, setPics] = useState([
    "https://ik.imagekit.io/mmbi/static/dopeslimes-biglots-0.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/mrsbench-biglots-2.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/monkeworld-biglots-4.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/mrsbench-biglots-6.png?tr=w-452,h-800",
  ])
  let [pics1, setPics1] = useState([
    "https://ik.imagekit.io/mmbi/static/dopeslimes-kohls-1.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/dopeslimes-kohls-3.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/dopeslimes-kohls-5.png?tr=w-452,h-800",
    "https://ik.imagekit.io/mmbi/static/dopeslimes-kohls-7.png?tr=w-452,h-800",
  ])

  let [previousImageL, setPreviousImageL] = useState(pics[0])
  let [currentImageL, setCurrentImageL] = useState(pics[1])
  let [nextImageL, setNextImageL] = useState(pics[2])
  let [previousImageR, setPreviousImageR] = useState(pics1[0])
  let [currentImageR, setCurrentImageR] = useState(pics1[1])
  let [nextImageR, setNextImageR] = useState(pics1[2])

  let setImages = (arrL, arrR) => {
    setPreviousImageL(arrL[0])
    setCurrentImageL(arrL[1])
    setNextImageL(arrL[2])
    setPreviousImageR(arrR[0])
    setCurrentImageR(arrR[1])
    setNextImageR(arrR[2])
  }

  let switchPics = () => {
    let imagesL = pics
    let img = imagesL.shift()
    imagesL.push(img)
    setPics(imagesL)

    let imagesR = pics1
    let img1 = imagesR.shift()
    imagesR.push(img1)
    setPics1(imagesR)

    setImages(imagesL, imagesR)
  }

  let [moveUp, setMoveUp] = useState('transition-none translate-y-[0px]')
  let [moveDown, setMoveDown] = useState('transition-none translate-y-[0px]')

  let shiftPics = () => {
    setMoveUp('transition-transform duration-1000 -translate-y-[436px]')
    setMoveDown('transition-transform duration-1000 translate-y-[436px]')
    setTimeout(() => {
      switchPics()
      setMoveUp('transition-none translate-y-[0px]')
      setMoveDown('transition-none translate-y-[0px]')
    }, 1000)
  }

  let stickerPosition = 0
  let [stickerRotation, setStickerRotation] = useState('rotate-0')
  let animateSticker = () => {
    if (stickerPosition === 0) {
      stickerPosition = 1
      setStickerRotation('rotate-12')
    } else if (stickerPosition === 1) {
      stickerPosition = 0
      setStickerRotation('rotate-0')
    }
    setTimeout(() => {
      animateSticker()
    }, 500)
  }

  useEffect(() => {
    animateSticker()
    setInterval(() => {
      shiftPics()
    }, 3000)
  }, [])

  const goToSamples = (e) => {
    e.preventDefault()
    window.location.href = '/samples'
  }

  return (
    <div className={"h-[500px] w-full pb-8 pt-4 bg-white"}>
      <section className={"h-full w-[1024px] mx-auto flex "}>
        <div className={"h-full w-1/2 p-12"}>
          <Overline>Your one-stop shop for wholesale</Overline>
          <h1 className={"font-bold text-5xl text-almost-black"}>
            Discover Emerging Brands and Trending Products
          </h1>
          {
            currentUser?.retailer ?
              <div className={'w-full mt-6'}>
                <Searchbar />
              </div>
              :
              <div className={'mr-5'}>
                <div className={'my-4'}>
                  <CtaLink id={"banner-retailer-sign-up-link"} link={"/retailer_inquiry"}>Sign Up to Shop</CtaLink>
                </div>
                <CtaLink id={"banner-brand-sign-up-link"} link={"/brand_inquiry"}>Sell on MadeMeBuyIt</CtaLink>
                <div className={'my-4'} onClick={goToSamples}>
                  <CtaLink id={"banner-discovee-opportunities-link"}>Get Leads Daily</CtaLink><span className={'relative rounded-full font-bold px-4 py-1 text-md text-pink bg-yellow shadow-xl -top-[52px] left-[280px] border-4 border-blue border-dotted'}>New!</span>
                </div>
              </div>
          }
        </div>
        <div className={`h-[436px] w-[512px] rounded-3xl flex justify-center items-center shadow-2xl mt-4`}>
          <img src={NewStickerYellow} alt={'new'} className={`z-30 h-36 w-36 absolute translate-x-60 -translate-y-40 ${stickerRotation}`} />

          <div className={`h-[436px] w-[512px] rounded-3xl flex flex-col justify-center items-center overflow-hidden`}>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveDown}`}>
              <img src={nextImageL} className={`h-5/6 rounded-xl`} />
            </div>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveDown}`}>
              <img src={currentImageL} className={`h-5/6 rounded-xl`} />
            </div>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveDown}`}>
              <img src={previousImageL} className={`h-5/6 rounded-xl`} />
            </div>
          </div>

          <div className={`h-[436px] w-[512px] rounded-3xl flex flex-col justify-center items-center overflow-hidden`}>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveUp}`}>
              <img src={previousImageR} className={`h-5/6 rounded-xl`} />
            </div>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveUp}`}>
              <img src={currentImageR} className={`h-5/6 rounded-xl`} />
            </div>
            <div className={`h-full w-full object-contain flex justify-center items-center shrink-0 ${moveUp}`}>
              <img src={nextImageR} className={`h-5/6 rounded-xl`} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Banner
