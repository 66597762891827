import React, { useEffect, useState } from 'react'
import X from "~/entrypoints/images/x.svg"
import Overline from '../../atoms/text/Overline.tsx'
import Header2 from '../../atoms/text/Header2.tsx'
import CtaButton from '~/components/atoms/buttons/CtaButton.tsx'
import Paragraph1 from "~/components/atoms/text/Paragraph1";
import CurrentRetailerMultiSelect from "~/components/molecules/inputs/CurrentRetailerMultiSelect.tsx"

const FiltersModal = ({ isOpen, filters, setIsOpen, setMsrp, setFollowers, setSales, setLeadtime, setRetailers, sortValue, setSort, numProducts, reset }: { isOpen: boolean, filters: object, setIsOpen: Function, setMsrp: Function, setFollowers: Function, setSales: Function, setLeadtime: Function, setRetailers: Function, sortValue: string, setSort: Function, numProducts: string, reset: Function }) => {

  let [animate, setAnimate] = useState(false)
  let [isHidden, setIsHidden] = useState(true)

  const handleMsrpChange = (e) => {
    setMsrp(0, Number(e.target.value))
  }
  const handleFollowersChange = (e) => {
    setFollowers(Number(e.target.value), 3)
  }
  const handleSalesChange = (e) => {
    setSales(Number(e.target.value), 2)
  }
  const handleLeadtimeChange = (e) => {
    setLeadtime(Number(e.target.value), 6)
  }

  const handleSortChange = (e) => {
    setSort(e.target.value)
  }

  useEffect(() => {
    if (isOpen) {
      setIsHidden(false)
      setTimeout(() => {
        setAnimate(true)
      }, 10)
    } else {
      setAnimate(false)
      setTimeout(() => {
        setIsHidden(true)
      }, 510)
    }
  }, [isOpen])

  let radioStyles = "!appearance-none mr-2 border border-dark-gray transition-all checked:border-6 checked:border-blue checked:bg-blue focus:border-6 focus:border-blue focus:bg-blue"

  return (
    <div id={'all-filters-modal'} className={`h-full w-full fixed top-0 left-0 z-50 ${isHidden && 'hidden'}`} >
      <div className={`h-full w-full grid place-content-center transition-all duration-500 ${animate ? 'bg-transparent-gray' : 'bg-none'}`}>
        <div className={`rounded-3xl bg-blue transition-all duration-500 ${animate ? 'scale-100 translate-x-0 translate-y-0' : 'scale-0 -translate-x-[50vw] -translate-y-[50vh]'} `}>
          <div className={ "relative h-[720px] w-[480px] rounded-3xl bg-white backdrop-blur-xl p-3 transform translate-x-1.5 -translate-y-2" } >
            <button autoFocus={true} className={'absolute top-0 right-0 font-med text-xl p-2'} onClick={() => setIsOpen(false)}>
              <img src={X} alt={'close modal'} className={'h-10 w-10'} />
            </button>
            <header className={'w-full h-1/12 flex justify-center items-center border-b border-blue mt-4'}>
              <Overline styles={'mb-4'}>All Filters</Overline>
            </header>

            <section className={'pl-2 h-5/6 w-full grid grid-cols-2 gap-x-4'}>
              <article className={`border-b border-blue py-3`}>
                <Header2 styles={'mb-2'}>MSRP</Header2>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'<$5'} value={'1'} checked={filters.msrp.max === 1} onChange={handleMsrpChange} className={radioStyles}/>
                  <label htmlFor={'<$5'}>
                    <Paragraph1>{"<$5"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'<$10'} value={'2'} checked={filters.msrp.max === 2} onChange={handleMsrpChange} className={radioStyles} />
                  <label htmlFor={'<$10'}>
                    <Paragraph1>{"<$10"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'<$15'} value={'3'} checked={filters.msrp.max === 3} onChange={handleMsrpChange} className={radioStyles} />
                  <label htmlFor={'<$15'}>
                    <Paragraph1>{"<$15"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'<$20'} value={'4'} checked={filters.msrp.max === 4} onChange={handleMsrpChange} className={radioStyles} />
                  <label htmlFor={'<$20'}>
                    <Paragraph1>{"<$20"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'<$30'} value={'5'} checked={filters.msrp.max === 5} onChange={handleMsrpChange} className={radioStyles} />
                  <label htmlFor={'<$30'}>
                    <Paragraph1>{"<$30"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'msrp'} id={'any'} value={'6'} checked={filters.msrp.max === 6} onChange={handleMsrpChange} className={radioStyles}
                         defaultChecked={true} />
                  <label htmlFor={'any'}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>

              </article>
              <article className={`border-b border-blue py-3`}>
                <Header2 styles={'mb-2'}>TikTok Followers</Header2>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'followers'} id={'any'} value={'0'} checked={filters.followers.min === 0} onChange={handleFollowersChange} className={radioStyles}
                         defaultChecked={true} />
                  <label htmlFor={'any'}>
                    <Paragraph1>Any</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'followers'} id={'100K+'} value={'1'} checked={filters.followers.min === 1} onChange={handleFollowersChange} className={radioStyles} />
                  <label htmlFor={'100K+'}>
                    <Paragraph1>100K+</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'followers'} id={'500K+'} value={'2'} checked={filters.followers.min === 2} onChange={handleFollowersChange} className={radioStyles} />
                  <label htmlFor={'500K+'}>
                    <Paragraph1>500K+</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'followers'} id={'1M+'} value={'3'} checked={filters.followers.min === 3} onChange={handleFollowersChange} className={radioStyles} />
                  <label htmlFor={'1M+'}>
                    <Paragraph1>1M+</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-blue py-3`}>
                <Header2 styles={'mb-2'}>Annual Sales</Header2>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sales'} id={'any'} value={'0'} checked={filters.sales.min === 0} onChange={handleSalesChange} className={radioStyles}
                         defaultChecked={true} />
                  <label htmlFor={'any'}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sales'} id={'<1M'} value={'1'} checked={filters.sales.min === 1} onChange={handleSalesChange} className={radioStyles} />
                  <label htmlFor={'<1M'}>
                    <Paragraph1>{">$1M"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sales'} id={'<5M'} value={'2'} checked={filters.sales.min === 2} onChange={handleSalesChange} className={radioStyles} />
                  <label htmlFor={'<5M'}>
                    <Paragraph1>{">$5M"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`border-b border-blue py-3`}>
                <Header2 styles={'mb-2'}>Lead Time</Header2>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'leadtime'} id={'<2w'} value={'1'} checked={filters.leadtime.min === 1} onChange={handleLeadtimeChange} className={radioStyles} />
                  <label htmlFor={'<2w'}>
                    <Paragraph1>{"<2 weeks"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'leadtime'} id={'<4w'} value={'2'} checked={filters.leadtime.min === 2} onChange={handleLeadtimeChange} className={radioStyles} />
                  <label htmlFor={'<4w'}>
                    <Paragraph1>{"<4 weeks"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'leadtime'} id={'any'} value={'0'} checked={filters.leadtime.min === 0} onChange={handleLeadtimeChange} className={radioStyles} defaultChecked={true} />
                  <label htmlFor={'any'}>
                    <Paragraph1>{"Any"}</Paragraph1>
                  </label>
                </div>
              </article>
              <article className={`h-64 border-b border-blue py-3 overflow-x-visible overflow-y-scroll`}>
                <Header2 styles={'mb-2'}>Sold In Store</Header2>
                <CurrentRetailerMultiSelect selected={filters} setSelected={setRetailers} />
              </article>
              <article className={`h-64 border-b border-blue py-3`}>
                <Header2 styles={'mb-2'}>Sort By</Header2>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sort'} id={'newest'} value={'newest'} checked={sortValue === 'newest'} onChange={handleSortChange} className={radioStyles} defaultChecked={true} />
                  <label htmlFor={'newest'}>
                    <Paragraph1>{"Newest"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sort'} id={'msrp_cents_asc'} value={'msrp_cents_asc'} checked={sortValue === 'msrp_cents_asc'} onChange={handleSortChange} className={radioStyles} />
                  <label htmlFor={'msrp_cents_asc'}>
                    <Paragraph1>{"Price: low to high"}</Paragraph1>
                  </label>
                </div>
                <div className={'flex items-center'}>
                  <input type={'radio'} name={'sort'} id={'msrp_cents_desc'} value={'msrp_cents_desc'} checked={sortValue === 'msrp_cents_desc'} onChange={handleSortChange} className={radioStyles} />
                  <label htmlFor={'msrp_cents_desc'}>
                    <Paragraph1>{"Price: high to low"}</Paragraph1>
                  </label>
                </div>
              </article>
            </section>

            <footer className={'h-1/12 w-full flex justify-between items-center fixed bottom-0 left-0 p-3'}>
              <button className={'h-12 w-1/4 border-2 border-pink rounded-full'} onClick={reset}>
                <Paragraph1>Clear All</Paragraph1>
              </button>
              <div className={'w-1/2'}>
                <CtaButton id={'filter-modal-apply-button'} disabled={numProducts === 'No matching products'} onclick={() => setIsOpen(false)}>{ numProducts }</CtaButton>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltersModal
