import React, {useState, useEffect, useRef} from 'react'
import BrandSelector from './molecules/BrandSelector'
import Header1 from './atoms/text/Header1'
import ImageUpload from './onboarding/molecules/ImageUpload.tsx'
import axios from 'axios'
import { Formik, Form } from "formik"
import FollowersSelectInput from "./molecules/inputs/FollowersSelectInput.tsx"
import { followerCounts, makeOption } from "../utils/FormSelectOptions.jsx";
import tikTokIcon from '../entrypoints/images/tiktok-icon.svg'
import instaIcon from '../entrypoints/images/insta-icon.svg'

export default function BrandCuration() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector("[name=csrf-token]").content

  let [selectedBrand, setSelectedBrand] = useState(null)
  let [brands, setBrands] = useState([])
  let [saved, setSaved] = useState(false)
  let brandName = useRef(null)

  useEffect(() => {
    getBrands()
  }, [])

  useEffect(() => {
    if (!selectedBrand) {
      getBrands()
    }
  }, [selectedBrand])

  useEffect(() => {
    if (brandName.current.value) {
      setSelectedBrand(brands.find(brand => brand.name === brandName.current.value))
    }
  }, [brands])

  const getBrands = (name) => {
    axios.get('/admin/brands', { withCredentials: true }).then((response) => {
      setBrands(response.data)
    })
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: selectedBrand?.name,
          description: selectedBrand?.description,
          logo: selectedBrand?.logo,
          banner: selectedBrand?.banner,
          tiktok_handle: selectedBrand?.tiktok_handle,
          tiktok_count: selectedBrand?.tiktok_count,
          insta_handle: selectedBrand?.insta_handle,
          instagram_count: selectedBrand?.instagram_count,
        }}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          axios.put(`/admin/brands/${selectedBrand.id}`, { brand: {...values}},  { withCredentials: true }).then(() => {
            setSaved(true)
            setTimeout(() => setSaved(false), 2000)
          })
          setSubmitting(false)
          setSelectedBrand(null)
        }}
      >
        <Form>
          <BrandSelector brands={brands} setSelectedBrand={setSelectedBrand} selectedBrand={selectedBrand} />
          <br/>or<br/>
          <Header1>Create New Brand</Header1>
          <input type="text" ref={brandName} />
          <button onClick={(e) => {
            e.preventDefault();
            axios.post('/admin/brands', { brand: { name: brandName.current.value }}, { withCredentials: true }).then(() => {
              getBrands(brandName.current.value)
            })
          }}>Create</button>
          <br/>
          { selectedBrand && <div>
            <label>Name</label>
            <input type="text" value={selectedBrand.name} onChange={(e) => setSelectedBrand({...selectedBrand, name: e.target.value})} />
            <br/>
            <label>Description</label>
            <input type="text" value={selectedBrand.description} onChange={(e) => setSelectedBrand({...selectedBrand, description: e.target.value})} />
            <br/>
            <label>Logo</label>
            <ImageUpload
              name='logo'
              recommendedSize='400x400'
              initialPreview={selectedBrand.logo}
            />
            <br/>
            <label>Banner</label>
            <ImageUpload
              name='banner'
              recommendedSize='1165x300'
              initialPreview={selectedBrand.banner}
            />
            <br/>
            <label className='text-sm font-reg block mt-2 mb-px'>Tik Tok Handle</label>
            <article className={'w-full h-10 border-r sm:border-r-0 border-l border-t border-b border-gray flex items-center bg-white'}>
              <img src={tikTokIcon} alt={'tik tok logo'} className={'h-4 mx-4'} />
              <input type="text" value={selectedBrand.tiktok_handle} onChange={(e) => setSelectedBrand({...selectedBrand, tiktok_handle: e.target.value})} />
            </article>
            <br/>
            <FollowersSelectInput label="TikTok Follower Count" name="tiktok_count" selectOptions={followerCounts} />
            <br/>
            <br/>
            <label className='text-sm font-reg block mt-2 mb-px'>Instagram Handle</label>
            <article className={'w-full h-10 border-r sm:border-r-0  border-l border-t border-b border-gray flex items-center bg-white'}>
              <img src={instaIcon} alt={'instagram logo'} className={'h-4 mx-4'} />
              <input type="text" value={selectedBrand.insta_handle} onChange={(e) => setSelectedBrand({...selectedBrand, insta_handle: e.target.value})} />
            </article>
            <br/>
            <FollowersSelectInput label="Instagram Follower Count" name="instagram_count" selectOptions={followerCounts} />
            <br/>
            <button
              type="submit"
              id={'admin-brand-save-button'}
              className={`w-full h-12 bg-dark-gray text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed`}
            >
              <p className='w-fit'>Save Changes</p>
            </button>
          </div>
          }
        </Form>
      </Formik>
    </div>
  )
}
