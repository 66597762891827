import React from 'react'
import Overline from '../../atoms/text/Overline.tsx'
import Header1 from '../../atoms/text/Header1.tsx'
import Header2 from '../../atoms/text/Header2.tsx'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'

const BetterOnDesktop = ({ setDismiss }) => {


  return (
    <section className={'h-full w-full pt-2 pb-4 px-4'}>
      <header className={'flex flex-col items-center'}>
        <Overline styles={'my-4'}>Welcome Back!</Overline>
        <Header1 styles={'text-center'}>Our site is best experienced on a larger screen</Header1>
      </header>
      <Header2 styles={'my-4'}>A mobile version is coming soon, but for now, we recommend you switch over to a laptop or desktop device.</Header2>
      <div className={'w-full mt-4 border-t-2 border-almost-black border-dashed'}>&nbsp;</div>
      <CtaButton id={'better-on-desktop-dismiss-button'} onclick={() => setDismiss(true)}>Go to Site</CtaButton>
    </section>
  )
}

export default BetterOnDesktop
