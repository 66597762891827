import React from 'react'
import { Link } from 'react-router-dom'

const CtaButton = ({ id, link, children }: { id:string, link:string, children:any }) => {
  return (
    <div className={`h-12 w-full border-b-4 border-blue rounded-full bg-blue`}>
        <div className={`h-full w-full border-2 border-pink rounded-full bg-light-pink font-med text-dark-gray text-xl transform translate-x-px transitional-all grid place-content-center
        hover:transition-all hover:-translate-x-px hover:translate-y-px
        disabled:bg-gray disabled:text-almost-black disabled:border-none`}>
          <Link
            to={link || '/'}
            id={id}
          >
            { children }
          </Link>
        </div>
    </div>
  )
}

export default CtaButton
