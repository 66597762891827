import React, { useState, useEffect, useContext } from 'react'
import CtaButton from '../atoms/buttons/CtaButton.tsx'
import BigModal from '../molecules/BigModal.tsx'
import { Formik, Form } from "formik"
import * as Yup from 'yup'
import TextInput from "../molecules/inputs/TextInput"
import SelectInput from "~/components/onboarding/molecules/SelectInput"
import { makeOption, stateCodes } from '../../utils/FormSelectOptions.jsx'
import Header1 from "../../components/atoms/text/Header1.tsx"
import axios from 'axios'
import { CurrentUserContext } from '~/contexts/CurrentUser.js'
import Paragraph1 from "../../components/atoms/text/Paragraph1.tsx"

export default function RequestForSample({brandId, productId}) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [modalOpen, setModalOpen] = useState(false)
  let [successMsg, setSuccessMsg] = useState(null)
  let [dismissable, setDismissable] = useState(false)
  let [requested, setRequested] = useState(false)

  const { currentUser } = useContext(CurrentUserContext)

  useEffect(() => {
    if (brandId && currentUser.sample_requested_brand_ids?.includes(brandId)) {
      setRequested(true)
    }
    if (productId && currentUser.sample_requested_product_ids?.includes(productId)) {
      setRequested(true)
    }
  }, [brandId, productId])

  const handleRequestForSampleSubmit = (values) => {
    values.brand_id = brandId
    if (productId) {
      values.product_id = productId
    }
    axios.post(
      '/request_for_samples',
      {
        request_for_sample: values,
        withCredentials: true
      }
    ).then(() => {
      handleSuccess()
    }).catch(() => {
      handleError()
    })
  }

  const handleSuccess = () => {
    setSuccessMsg('Your request has been sent!')
    setDismissable(true)
  }

  const handleError = () => {
    setSuccessMsg('There was an error sending your request. Please try again.')
    setDismissable(false)
  }

  const handleDismiss = () => {
    setRequested(true)
    setModalOpen(false)
  }

  return (
    <>
      { !!!requested && (
        <>
          <CtaButton id={'sample-request-modal-entry'} onclick={() => { setModalOpen(true)}} >Request a Sample</CtaButton>
          <BigModal isOpen={modalOpen} setIsOpen={setModalOpen}>
            <Header1 styles={'mb-6 text-center'}>{`Request a Sample`}</Header1>
            <Formik
              initialValues={{
                name: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                postal_code: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
                address_1: Yup.string().required('Required'),
                address_2: Yup.string(),
                city: Yup.string().required('Required'),
                state: Yup.string().required('Required'),
                postal_code: Yup.string().required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleRequestForSampleSubmit(values)
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isValid }) => (
                <Form className={'w-full flex flex-col'}>
                  { successMsg && <p className={`h-12 w-full text-center pt-4 font-reg text-base text-pink`}>{successMsg}</p>}
                  { !dismissable ? <>
                    <TextInput name='name' label='Name' type="text" />
                    <TextInput name='address_1' label='Address 1' type="text" />
                    <TextInput name='address_2' label='Address 2' type="text" />
                    <TextInput name='city' label='City' type="text" />
                    <SelectInput name='state' label='State' selectOptions={stateCodes} />
                    <TextInput name='postal_code' label='Postal Code' type="text" />
                  </> : (null)
                  }

                  { !dismissable ? <>
                    <button
                      type="submit"
                      id='sample-request-submit'
                      disabled={!isValid}
                      className={`w-full h-12 bg-dark-gray text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed mb-2`}
                    >
                      <p className='w-fit'>Submit Request</p>
                    </button>
                  </>: <>
                    <button
                      type="dismiss"
                      id='sample-request-dismiss'
                      onClick={handleDismiss}
                      className={`w-full h-12 bg-dark-gray text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed mb-2`}
                    >
                      <p className='w-fit'>Dismiss</p>
                    </button>
                  </>
                  }
                </Form>
              )}
            </Formik>
          </BigModal>
        </>
      )}
      { requested && (
        <div className={'h-12 w-full border-b-4 border-blue rounded-full bg-blue'}>
          <button onClick={() => { setModalOpen(true)}} id={'sample-request-modal-entry'} className={'opacity-90 h-full w-full border-2 border-pink rounded-full bg-light-pink font-reg text-dark-gray text-xl transform translate-x-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px disabled:bg-gray disabled:text-almost-black disabled:border-none'} disabled>
            <Paragraph1 styles={"text-pink text-opacity-50"}>Sample Requested</Paragraph1>
          </button>
        </div>
      )}
    </>
  )
}
