import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Header1 from "../../../../components/atoms/text/Header1.tsx"
import FormatMoney from "../../../../utils/FormatMoney"
import { mockOrders } from '../../../../utils/mockData.js'

const ManageOrders = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [orders, setOrders] = useState([])

  const getMockOrders = () => {
    setOrders([])
  }

  const getOrderRows = () => {
    let orderRows = orders.map(o => {
      return (
        <tr className={"h-12 font-med text-sm text-medium-gray border-b border-gray"}>
          <td className={tableStyles.columnOne}>{o.order_number}</td>
          <td className={tableStyles.columnTwo}>{o.retailer}</td>
          <td className={tableStyles.columnThree}>{o.status}</td>
          <td className={tableStyles.columnFour}>{FormatMoney(o.total_cents)}</td>
          <td className={tableStyles.columnFive}>{o.last_updated}</td>
          <td className={tableStyles.columnSix}>
            <Link to={"/brand_dashboard"} className={"font-reg text-sm text-link-blue"}>
              View
            </Link>
          </td>
        </tr>
      )
    })
    return orderRows
  }

  let tableStyles = {
    columnOne: 'w-2/12 text-left pl-3',
    columnTwo: 'w-2 /12 text-left',
    columnThree: 'w-2/12 text-right',
    columnFour: 'w-2/12 text-right',
    columnFive: 'w-2/12 text-right',
    columnSix: 'w-2/12 text-right pr-3'
  }

  // const getOrders = () => {axios request}

  useEffect(() => {
    // getOrders()
    getMockOrders()
  },[])

  return (
    <section className={"w-2/3 flex flex-col"}>
      <Header1 styles={'mb-4'}>Manage PO's</Header1>
      <section className={'w-full bg-transparent-white border border-gray pb-12'}>
        {/*<header className={'h-16 w-full flex justify-between items-center border-b border-gray px-3'}>
        </header>*/}

        <table className={'w-full'}>
          <tr className={'h-12 w-11/12 bg-white font-med text-sm text-almost-black border-b border-gray'}>
            <th className={tableStyles.columnOne}>PO #</th>
            <th className={tableStyles.columnTwo}>Retailer</th>
            <th className={tableStyles.columnThree}>PO Status</th>
            <th className={tableStyles.columnFour}>PO Total</th>
            <th className={tableStyles.columnFive}>Last Updated</th>
            <th className={tableStyles.columnSix}>Actions</th>
          </tr>
          {getOrderRows()}
        </table>

      </section>
    </section>
  )
}

export default ManageOrders
