import React, { useRef, useEffect, useContext } from "react"
import X from '../../../entrypoints/images/x.svg'
import Overline from '../../atoms/text/Overline.tsx'
import Header1 from '../../atoms/text/Header1.tsx'
import Header3 from '../../atoms/text/Header3.tsx'
import Paragraph1 from '../../atoms/text/Paragraph1.tsx'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import axios from 'axios'


const ReleaseNotesModal = ({ isOpen, setIsOpen, notes }) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      const modalElement = modalRef.current
      modalElement.focus()
      //add any focusable HTML element you want to include to this string
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1]

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          setIsOpen(false);
        }
      }

      modalElement.addEventListener("keydown", handleTabKeyPress);
      document.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        modalElement.removeEventListener("keydown", handleTabKeyPress);
        document.removeEventListener("keydown", handleEscapeKeyPress)
      }
    }
  }, [isOpen, setIsOpen])

  const handleClick = () => {
    axios.put('/release_notes/dismiss', {}, { withCredentials: true })
    setIsOpen(false)
  }

  return (
    isOpen && (
      <div className={"h-full w-full fixed top-0 left-0 grid place-content-center bg-transparent-gray !z-[999]"} ref={modalRef} >
        <div className={`rounded-3xl bg-blue`}>
          <div className={ "relative h-[600px] w-[600px] rounded-3xl bg-white backdrop-blur-xl p-8 pt-4 transform translate-x-1.5 -translate-y-2" } >
            <div className={'w-full h-full overflow-y-scroll'}>
              <button  autoFocus={true} className={'absolute top-0 right-0 font-med text-xl p-2'} onClick={handleClick}>
                <img src={X} alt={'close modal'} className={'h-10 w-10'} />
              </button>
              <section className={'py-4 px-2'}>
                <Overline styles={'text-center mb-4'}>New Features!</Overline>
                <Header1 styles={'text-center mb-4'}>Please take a moment to check out these new features!</Header1>
                {
                  notes?.map((n) => {
                    return (
                      <article className={'mb-3'}>
                        <Header3>{(new Date(n.created_at)).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric'})}</Header3>
                        {
                          n.body.split('\n').map((t) => {
                            return (
                              <Paragraph1 styles={'mb-2'}>{t}</Paragraph1>
                            )
                          })
                        }
                      </article>
                    )
                  })
                }
                <div className={'w-80 mx-auto'}>
                  <CtaButton id={'release-notes-modal-ok'} onclick={handleClick}>Got it</CtaButton>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default ReleaseNotesModal

