import React, { useState } from 'react'
import { Formik, Form } from "formik"
import {useNavigate} from "react-router-dom"
import * as Yup from 'yup'
import { makeOption, productCategories, leadTimes } from '../../../utils/FormSelectOptions.jsx'
import ImageUpload from '../../../components/onboarding/molecules/ImageUpload.tsx'
import TextAreaInput from "../../../components/onboarding/molecules/TextAreaInput"
import TextInput from "../../molecules/inputs/TextInput"
import MoneyInput from "../../molecules/inputs/MoneyInput"
import ProfileSection from '../brand/molecules/ProfileSection.tsx'
import Header1 from "~/components/atoms/text/Header1";
import SelectInput from "~/components/onboarding/molecules/SelectInput"
import axios from 'axios'
import DecimalInput from "../../molecules/inputs/DecimalInput"
import WeightToggle from '../../molecules/inputs/WeightToggle.tsx'

const ProductNew = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [successMsg, setSuccessMsg] = useState(null)
  let [isDisabled, setIsDisabled] = useState(false)
  let [weightUnit, setWeightUnit] = useState("lbs")
  let [weightLabel, setWeightLabel] = useState("lbs")
  let navigate = useNavigate()

  const handleSubmit = (v) => {
    setIsDisabled(true)
    axios.post('/products', {
      product: {
        photo_1: v.photo_1,
        photo_2: v.photo_2,
        photo_3: v.photo_3,
        photo_4: v.photo_4,
        photo_5: v.photo_5,
        description: v.description,
        length: v.length,
        width: v.width,
        height: v.height,
        weight: v.weight,
        weight_unit: v.weight_unit,
        inner_count: v.inner_count,
        inner_quantity: v.inner_quantity,
        outer_quantity: v.outer_quantity,
        name: v.name,
        //category: v.category,
        sku: v.sku,
        upc: v.upc,
        wholesale_cents: v.wholesale_cents,
        msrp_cents: v.msrp_cents,
        lead_time_weeks: v.lead_time_weeks,
      }, withCredentials: true
    }).then(() => {
      handleSuccess()
    }).catch((error) => {
      setSuccessMsg(error.response.data.errors.join(', '))
    }).finally(() => {
      setIsDisabled(false)
    })
  }

  let testCdn = (v) => {
    if (!v) {
      return true
    }
    return !v.includes('drive.google.com') && !v.includes('imgur.com') && !v.includes('ibb.co')
  }

  const handleSuccess = () => {
    setSuccessMsg('Success!')
    setTimeout(() => {
      navigate('/dashboard/manage_products')
    }, 1000)
  }

  return (
    <section className={"w-2/3 flex flex-col"}>
      <Header1 styles={'mb-4'}>Add New Product</Header1>
      <Formik
        initialValues={{
          photo_1: '',
          photo_2: '',
          photo_3: '',
          photo_4: '',
          photo_5: '',
          description: '',
          length: '',
          height: '',
          width: '',
          weight: '',
          weight_unit: 'lbs',
          inner_count: '',
          inner_quantity: '',
          outer_quantity: '',
          name: '',
          //category: 'As Seen On TikTok',
          sku: '',
          upc: '',
          wholesale_cents: '',
          msrp_cents: '',
          lead_time_weeks: 1,
        }}
        validationSchema={Yup.object({
          photo_1: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .required('At least one image required'),
          photo_2: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_3: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_4: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          photo_5: Yup.string()
            .test('valid cdn', 'Photo URL cannot link to drive.google.com, imgur.com, or ibb.co', (value) => testCdn(value))
            .notRequired(),
          length: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          width: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          height: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          weight: Yup.number()
            .notRequired()
            .positive('Must be a positive number'),
          weight_unit: Yup.string()
            .notRequired(),
          inner_count: Yup.number().integer()
            .notRequired(),
          inner_quantity: Yup.number().integer()
            .notRequired(),
          outer_quantity: Yup.number().integer()
            .notRequired(),
          name: Yup.string()
            .required('Required'),
          //category: Yup.string()
          //  .required('Required'),
          sku: Yup.string()
            .notRequired(),
          upc: Yup.string()
            .required('Required'),
          wholesale_cents: Yup.number()
            .required('Required')
            .positive('Price must be greater than $0'),
          msrp_cents: Yup.number()
            .required('Required')
            .positive('Price must be greater than $0'),
          lead_time_weeks: Yup.number()
            .required('Required'),
        })}

        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values)
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isValid }) => (
          <Form
          >
            <ProfileSection headerText={'Product Details'}>

              <TextInput
                label="Product Title"
                name="name"
                type="text"
                placeholder=""
              />

              {/*
              <SelectInput label="Assign Category" name="category" selectOptions={productCategories} />
              */}

              <TextInput
                label="Product SKU"
                name="sku"
                type="text"
                placeholder=""
              />

              <TextInput
                label="Product UPC"
                name="upc"
                type="text"
                placeholder=""
              />

              <MoneyInput
                label="Unit Wholesale Price"
                name="wholesale_cents"
                placeholder=""
              />

              <MoneyInput
                label="Unit MSRP"
                name="msrp_cents"
                placeholder=""
              />

              <SelectInput
                label={"Estimated Lead Time (weeks)"}
                name={"lead_time_weeks"}
                selectOptions={leadTimes}
              />

              <TextAreaInput
                label="About this Product"
                name="description"
                placeholder="Please describe the product in at least 100 characters..."
                height='h-44'
              />

              <DecimalInput
                label="Length (inches)"
                name="length"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <DecimalInput
                label="Width (inches)"
                name="width"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <DecimalInput
                label="Height (inches)"
                name="height"
                unit=""
                precision={1}
                placeholder=""
                height='h-44'
              />

              <div className='col-span-12 grid grid-cols-2 gap-4'>
                <div>
                  <DecimalInput
                    label="Weight"
                    name="weight"
                    unit=""
                    precision={1}
                    placeholder=""
                    height='h-44'
                  />
                </div>

                <div>
                  <WeightToggle
                    selectOptions={["lbs", "oz"]}
                    name="weight_unit"
                    height='h-44'
                    label="Weight Unit"
                  />
                </div>
              </div>

              <TextInput
                label="Number of Inners"
                name="inner_count"
                type="number"
                placeholder=""
              />

              <TextInput
                label="Inner Quantity"
                name="inner_quantity"
                type="number"
                placeholder=""
              />

              <TextInput
                label="Outer Quantity"
                name="outer_quantity"
                type="number"
                placeholder=""
              />

              <p className={'w-full text-sm font-reg my-2'}>Product Images</p>
              <div className={'flex justify-between'}>
                <ImageUpload
                  name='photo_1'
                  recommendedSize={'400x400 pixels'}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_2'
                  recommendedSize={'400x400 pixels'}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_3'
                  recommendedSize={'400x400 pixels'}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_4'
                  recommendedSize={'400x400 pixels'}
                  width={'w-1/5'}
                />
                <ImageUpload
                  name='photo_5'
                  recommendedSize={'400x400 pixels'}
                  width={'w-1/5'}
                />
              </div>

              <button
                type="submit"
                id={'product-edit-submit'}
                disabled={!isValid || isDisabled}
                className={`w-full h-12 bg-dark-gray text-xs font-reg text-white flex justify-center items-center mt-6 disabled:bg-gray disabled:cursor-not-allowed`}
              >
                <p className='w-fit'>Create Product</p>
              </button>
              { <p className={`h-12 w-full text-center pt-4 font-reg text-base text-red-600`}>{ isValid ? "" : "Please check required fields"}</p> }
              { successMsg && <p className={`h-12 w-full text-center pt-4 font-reg text-base text-pink`}>{successMsg}</p>}
            </ProfileSection>
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default ProductNew
