import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import FormatMoney from '../../../utils/FormatMoney.js'
import Header2 from "../../../components/atoms/text/Header2.tsx";
import { mockOrders } from "../../../utils/mockData";

export default function DashboardOrdersWidget() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [orders, setOrders] = useState([])

  const getMockOrders = () => {
    setOrders([])
  }

  const getOrderRows = () => {
    let orderRows = orders.map(o => {
      return (
        <tr className={"h-12 font-med text-sm text-medium-gray border-b border-gray"}>
          <td className={tableStyles.columnOne}>{o.order_number}</td>
          <td className={tableStyles.columnTwo}>{o.retailer}</td>
          <td className={tableStyles.columnThree}>{o.status}</td>
          <td className={tableStyles.columnFour}>{`$${FormatMoney(o.total_cents)}`}</td>
          <td className={tableStyles.columnFive}>
            <Link to={"/brand_dashboard"} className={"font-reg text-sm text-link-blue"}>
              View
            </Link>
          </td>
        </tr>
      )
    })
    return orderRows
  }

  // const getOrders = () => {
  //   axios.get('/purchase_orders').then((r) => {
  //     setOrders(r.data.filter((po) => { console.log("PO Status", po.status);return po.status !== 'archived'}))
  //   })
  // }

  // const updateStatus = (id, status) => {
  //   axios.put(`/purchase_orders/${id}`, {
  //     purchase_order: {
  //       status: status
  //     }
  //   }).then((r) => {
  //     getOrders()
  //   })
  // }

  useEffect(() => {
    // getOrders()
    getMockOrders()
  },[])

  let tableStyles = {
    columnOne: 'w-2/12 text-left pl-3',
    columnTwo: 'w-3/12 text-left',
    columnThree: 'w-3/12 text-right',
    columnFour: 'w-2/12 text-right',
    columnFive: 'w-2/12 text-right pr-3'
  }

  return(
    <article className={'w-full bg-transparent-white border border-gray mt-4 pb-12'}>
      <header className={'h-12 w-full flex justify-between items-center border-b border-gray px-3'}>
        <Header2>New Orders</Header2>
        <Link to={'/dashboard'} className={'font-reg text-sm text-link-blue'}>View All Orders</Link>
      </header>
      <section className={'w-full'}>
        <table className={'w-full'}>
          <tr className={'h-12 w-11/12 bg-white font-med text-sm text-almost-black border-b border-gray'}>
            <th className={tableStyles.columnOne}>Order #</th>
            <th className={tableStyles.columnTwo}>Retailer</th>
            <th className={tableStyles.columnThree}>PO Status</th>
            <th className={tableStyles.columnFour}>PO Total</th>
            <th className={tableStyles.columnFive}>Actions</th>
          </tr>
          {getOrderRows()}
        </table>
      </section>
    </article>
  )
}
