import Cookies from "js-cookie"
import axios from "axios"
import { useNavigate } from "react-router-dom"

export default function Auth(setter) {
  const navigate = useNavigate()
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const checkAuth = async (path) => {
    let token = Cookies.get("bearerToken")
    if (token) {
      axios.get("/current_user.json", {
        params: {
          user: {
            token: token,
          },
        },
      }).then((response) => {
        if (response.data.errors) {
          setter({loading: false})
          return navigate('/')
        } else {
          return setter({...response.data, loading: false})
        }
      })
    } else {
      path ? navigate(path) : navigate('/')
    }
  }

  const signIn = async (email, password) => {
    try {
      await axios
      .post("/login", {
        user: {
          email: email,
          password: password,
        },
      }).then((r) => {
        let user = r.data.user
        setter(user)
        Cookies.set(
          "bearerToken",
          r.headers.authorization,
          { expires: 7 }
        )
      })
    } catch (error) {
      return error
    }
  }

  const logout = async (currentPath) => {
    axios
      .delete("/logout", {
        headers: { "Authorization": Cookies.get("bearerToken") },
      })
      .catch((error) => {
        Cookies.remove("bearerToken", currentPath)
        setter({loading: false})
      })
      .finally(() => {
        setter({loading: false})
        Cookies.remove("bearerToken", currentPath)
        navigate("/")
      })
  }

  return {
    checkAuth: checkAuth,
    signIn: signIn,
    logout: logout,
  }
}
