import React, { useContext, useState } from 'react'
import { Formik, Form } from "formik"
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import Header2 from '../../atoms/text/Header2.tsx'
import TextInput from '../../molecules/inputs/TextInput.tsx'
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import CtaLink from '../../atoms/buttons/CtaLink.tsx'
import axios from 'axios'
import Header3 from "~/components/atoms/text/Header3";
import Overline from "~/components/atoms/text/Overline";
import SecondaryCtaLink from "~/components/atoms/buttons/SecondaryCtaLink.tsx";

const SignInModal = ({ setIsOpen }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [reset, setReset] = useState(false)


  const resetPassword = async (email) => {
    setReset(true)
    axios.post('/password', {
      user: {
        email: email
      }
    })
  }


  return (
    <section className={'h-full w-full p-1 xs:p-2 sm:p-4 flex flex-col justify-between'}>
      <section className={'h-96 w-full flex flex-col justify-between'}>
        <header className={'flex flex-col items-center'}>
          <Overline styles={'mt-4'}>Forgot Password</Overline>
          <Header2 styles={'text-center'}>Enter your email address and we'll send you a link to reset your password</Header2>
        </header>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Please enter a valid email address')
              .required('Please enter a valid email address'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              resetPassword(values.email)
            }, 400);
          }}
        >
          <Form className={'h-44 flex flex-col justify-between'}>
            <div>
              <TextInput
                label="Email Address*"
                name="email"
                type="text"
                placeholder=""
                onFocus={() => setReset(false)}
              />
            </div>
            <p className='text-green-600 font-reg text-sm'>
              {reset ? 'Please check your email to continue' : ''}
            </p>
            <div className={'w-60 mx-auto flex justify-center mb-4'}>
              <CtaButton id={'sign-in-continue-button'} submit={true}>Send link</CtaButton>
            </div>
          </Form>
        </Formik>
        <div className={'w-60 mx-auto flex justify-center'}>
          <CtaLink id={'forgot-pass-modal-back-to-sign-in-button'} link={'/sign_in'} >Back to Sign In</CtaLink>
        </div>
      </section>

      <div className={'w-full mt-8 border-t-2 border-almost-black border-dashed'}>&nbsp;</div>

      <section className={'flex flex-col items-center'}>
        <Header3 styles={'mb-6'}>New to MadeMeBuyIt?</Header3>
        <section className={'flex flex-col items-center gap-y-4 w-60'}>
          <SecondaryCtaLink id={'sign-in-modal-brand-inquiry-link'} link={'/brand_inquiry'} >
            Become a Brand
          </SecondaryCtaLink>
          <SecondaryCtaLink id={'sign-in-modal-retailer-inquiry-link'} link={'/retailer_inquiry'} >
            Become a Retailer
          </SecondaryCtaLink>
        </section>
      </section>
    </section>
  )
}

export default SignInModal
