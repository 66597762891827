import React from 'react'
import { Link } from 'react-router-dom'
import HeadingText from "../../onboarding/molecules/HeadingText.tsx";
import Arrow from "../../../entrypoints/images/arrow-right.svg";
import ShopifyLogo from '../../../entrypoints/images/shopify-green.svg'
import UploadCsvWidget from '../../molecules/UploadCsvWidget.tsx'
const AddProductsForm = () => {
  return (
    <section className={'w-full px-3'}>
      <HeadingText text={'add products'} />
      {/*<p className='text-center text-xs font-reg mb-4'></p>*/}

      {/*
      <article className={'bg-transparent-white border border-gray p-5'}>
        <h2 className={'text-2xl font-reg text-almost-black'}>Connect to Shopify</h2>
        <p className='text-xs font-reg mb-4 text-dark-gray'>Link your Shopify account here and decide later which products to share</p>
        <a href='/api/auth' className={'flex justify-center border border-gray p-3'}>
          <img src={ShopifyLogo} alt='shopify logo' className='mr-2'/>
          <p className='text-base font-reg text-dark-gray'>Connect Shopify</p>
        </a>
      </article>
      */}

      <section className={'w-full grid place-items-center'}>
        <UploadCsvWidget position={'w-full my-4'} />
        <Link
          to="/dashboard/manage_products/new"
          className={"w-full flex justify-center border border-gray p-3 bg-white"}
        >
          <p className="text-sm font-reg text-dark-gray">+ Add Products Manually</p>
        </Link>
      </section>
      <div className={'w-full mt-8 mb-2 border-t-2 border-almost-black border-dashed'}>&nbsp;</div>
      <Link to={'/dashboard'} className='w-full h-12 bg-white border border-gray text-base font-reg text-dark-gray grid place-items-center mb-24'>
        <p className='w-fit text-sm font-reg text-dark-gray'>Skip & Create Brand Profile</p>
      </Link>
    </section>
  )
}

export default AddProductsForm
