import React, { useState, useEffect, useRef } from 'react'
import BrandSelector from './molecules/BrandSelector'
import Header1 from './atoms/text/Header1'
import { Formik, Form } from "formik"
import axios from 'axios'
import ImageUpload from './onboarding/molecules/ImageUpload.tsx'

export default function CuratedBrandsProducts() {
  let [selectedBrand, setSelectedBrand] = useState(null)
  let [brands, setBrands] = useState([])
  let [products, setProducts] = useState([])
  let [newProduct, setNewProduct] = useState(false)
  let [productData, setProductData] = useState({})

  useEffect(() => {
    getBrands()
  }, [])

  useEffect(() => {
    setProducts([])
    if (selectedBrand) {
      getProducts(selectedBrand)
    }
  }, [selectedBrand])


  const getBrands = () => {
    axios.get('/admin/brands', { withCredentials: true }).then((response) => {
      setBrands(response.data)
    })
  }

  const getProducts = (brand) => {
    axios.get(`/admin/brands/${brand.id}/products`, { withCredentials: true }).then((response) => {
      setProducts(response.data.products)
    })
  }

  const toggleNewProduct = () => {
    setNewProduct(!newProduct)
  }

  return (
    <Formik
      initialValues={productData}
      enableReinitialize={true}
      onSubmit={(values) => {
        axios.post(`/admin/brands/${selectedBrand.id}/products`, { product: values }, { withCredentials: true }).then((response) => {
          setProducts([...products, response.data])
          setProductData({})
        })
      }}
    >
    <Form>
      <BrandSelector brands={brands} setSelectedBrand={setSelectedBrand} selectedBrand={selectedBrand} />
      <br/>
      { selectedBrand &&
      <div onClick={toggleNewProduct}>
        <Header1>+ Add Product</Header1>
      </div>
      }
      { newProduct &&
      <div>
        <input type="text" placeholder="Product Name" onChange={(e) => setProductData({...productData, name: e.target.value})} />
        <input type="text" placeholder="Product Description" onChange={(e) => setProductData({...productData, description: e.target.value})} />
        <input type="text" placeholder="MSRP" onChange={(e) => setProductData({...productData, msrp_cents: e.target.value * 100})} />
        <input type="text" placeholder="Wholesale" onChange={(e) => setProductData({...productData, wholesale_cents: e.target.value * 100})} />
        <input type="text" placeholder="SKU" onChange={(e) => setProductData({...productData, sku: e.target.value})} />
        <input type="text" placeholder="UPC" onChange={(e) => setProductData({...productData, upc: e.target.value})} />
        <input type="text" placeholder="Weight" onChange={(e) => setProductData({...productData, weight: e.target.value})} />
        <input type="text" placeholder="Length" onChange={(e) => setProductData({...productData, length: e.target.value})} />
        <input type="text" placeholder="Width" onChange={(e) => setProductData({...productData, width: e.target.value})} />
        <input type="text" placeholder="Height" onChange={(e) => setProductData({...productData, height: e.target.value})} />
        <select placeholder='Weight Unit' onChange={(e) => setProductData({...productData, weight_unit: e.target.value})}>
          <option value='lbs' selected>lbs</option>
          <option value='oz'>oz</option>
        </select>
        <input type="text" placeholder="Inner Count" onChange={(e) => setProductData({...productData, inner_count: e.target.value})}/>
        <input type="text" placeholder="Inner Quantity" onChange={(e) => setProductData({...productData, inner_quantity: e.target.value})} />
        <input type="text" placeholder="Outer Quantity" onChange={(e) => setProductData({...productData, outer_quantity: e.target.value})} />
        <div className={'flex justify-between'}>
          <ImageUpload
            name='photo_1'
            recommendedSize='400x400'
            initialPreview={selectedBrand.photo_1}
          />
          <ImageUpload
            name='photo_2'
            recommendedSize='400x400'
            initialPreview={selectedBrand.photo_2}
          />
          <ImageUpload
            name='photo_3'
            recommendedSize='400x400'
            initialPreview={selectedBrand.photo_3}
          />
          <ImageUpload
            name='photo_4'
            recommendedSize='400x400'
            initialPreview={selectedBrand.photo_4}
          />
          <ImageUpload
            name='photo_5'
            recommendedSize='400x400'
            initialPreview={selectedBrand.photo_5}
          />
        </div>
        <button>Save</button>
      </div>
      }
      { selectedBrand && products.map((product) => (
        <div>
          <br/>
          <hr/>
          <div>name: <b>{product.name}</b></div>
          <div>description: <b>{product.description}</b></div>
          <div>msrp: <b>{product.msrp_cents / 100.0 }</b></div>
          <div>wholesale: <b>{product.wholesale_cents / 100.0 }</b></div>
          <div>sku: <b>{product.sku}</b></div>
          <div>upc: <b>{product.upc}</b></div>
          <div>weight: <b>{product.weight}</b></div>
          <div>length: <b>{product.length}</b></div>
          <div>width: <b>{product.width}</b></div>
          <div>height: <b>{product.height}</b></div>
          <div>weight unit: <b>{product.weight_unit}</b></div>
          <div>inner count: <b>{product.inner_count}</b></div>
          <div>inner quantity: <b>{product.inner_quantity}</b></div>
          <div>outer quantity: <b>{product.outer_quantity}</b></div>
          <div>{product.photo_1}</div>
          <div>{product.photo_2}</div>
          <div>{product.photo_3}</div>
          <div>{product.photo_4}</div>
          <div>{product.photo_5}</div>
        </div>
      ))}
    </Form>
    </Formik>
  )
}
