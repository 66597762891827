import React from 'react'
import { Link } from 'react-router-dom'
import Header1 from '../atoms/text/Header1.tsx';
import ProductCard from '../molecules/ProductCard.tsx'
import BrandCard from '../molecules/BrandCard.tsx'
const Deck = ({ headerText, linkText, linkPath, products, brands, cards }: { headerText: string, linkText?: string, linkPath?: string, products?: object, brands?: object, cards?: object }) => {
  const lgScreen = window.matchMedia("(min-width: 1024px)")
  const xlScreen = window.matchMedia("(min-width: 1280px)")

  let brandCards = brands?.map((brand, i) => {
    if (i < 5) {
      return (
        <BrandCard brand={brand}/>
      )
    }
  })

  let productCards = products?.map((product, i) => {
    let maxCards = (xlScreen.matches ? 5 : lgScreen.matches ? 4 : 3)
    if (i < maxCards) {
      return (
        <ProductCard product={product} />
      )
    }
  })

  return (
    <section className={'mb-6'}>
      <header className={'w-full flex justify-between mb-4'}>
        <Header1>{headerText}</Header1>
        {
          linkPath && <Link to={linkPath} className={'font-sm font-reg text-almost-black underline'}>{linkText || "Show All"}</Link>
        }
      </header>
      <section className={'max-w-full grid grid-flow-col auto-cols-min grid-rows-1 gap-x-4'}>
        {
          products ? productCards :
            brands ? brandCards :
              cards ? cards : null
        }
      </section>
    </section>
  )
}

export default Deck
