import React, { useState, useEffect, useContext } from 'react'
import Header1 from '../atoms/text/Header1.tsx'
import Header2 from '../atoms/text/Header2.tsx'
import ProductCard from '../molecules/ProductCard.tsx'
import CtaButton from '../atoms/buttons/CtaButton.tsx'
import axios from 'axios'
import SmallModal from "~/components/organisms/modals/SmallModal";
import Paragraph1 from "~/components/atoms/text/Paragraph1"
import PdfDownloader from "~/components/organisms/PdfDownloader";
import { CurrentUserContext } from "../../contexts/CurrentUser"


const MyPicks = () => {

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  let [products, setProducts] = useState([])
  let [modalOpen, setModalOpen] = useState(false)

  const getPicks = () => {
    axios.get('/curated_picks').then((cpr) => {
      setProducts(cpr.data.products)
      axios.put('/view_picks', { withCredentials: true}).then((r) => {
        axios.get('/me').then((r) => {
          setCurrentUser(r.data)
        }).catch((e) => {
          setCurrentUser({})
        })
      })
    })
  }

  useEffect(() => {
    getPicks()
  }, [])

  const handleQuote = () => {
    axios.post('/submitted_picks').then((res) => {
      setModalOpen(true)
    })
  }

  return (
    <section className={'h-full w-full mt-6 2xl:w-[1440px] flex flex-col items-center'}>
      <Header1 styles={''}>Curated Just for You</Header1>
      <Header2 styles={'my-2'}>Here are some products we think you'll love, refreshed weekly</Header2>
      <div className={'w-[500px] flex justify-between mb-6'}>
        <div className={'w-60'}>
          <CtaButton id={'my-picks-quote-button'} onclick={handleQuote}>Get Me a Quote</CtaButton>
        </div>
        { products?.length ? <PdfDownloader products={products} /> : null }
      </div>
      {
        products ?
          <div className={'flex flex-wrap justify-center gap-x-4 gap-y-4'}>
            {
              products.map((p) => {
                return <ProductCard product={p} removePick={getPicks} />
              })
            }
          </div>
          :
          <h1>No curated picks</h1>
      }
      {
        modalOpen &&
        <SmallModal isOpen={modalOpen} setIsOpen={setModalOpen}>
          <div className={'flex flex-col items-center mt-12'}>
            <Header1 styles={'text-center'}>Your request has been submitted!</Header1>
            <Paragraph1 styles={'mt-4 text-center'}>We will email you with details and pricing within 24 hours!</Paragraph1>
            <div className={'mt-12 w-60'}>
              <CtaButton id={'quote-modal-close'} onclick={() => setModalOpen(false)} >Back to My Picks</CtaButton>
            </div>
          </div>
        </SmallModal>
      }
    </section>
  )
}

export default MyPicks
