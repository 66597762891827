import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ref } from 'yup'
import Header1 from '../../atoms/text/Header1.tsx'
import TextInput from "../../molecules/inputs/TextInput.tsx"
import axios from 'axios'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import LogoHeader from '../../organisms/headers/LogoHeader.tsx'
import { CurrentUserContext } from '../../../contexts/CurrentUser.js'


const CreatePassword = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let navigate = useNavigate()
  const smScreen = window.matchMedia("(min-width: 640px)")
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  const handleSubmit = (v) => {
    axios.put('/me', v)
      .then(() => {
        axios.get('/me', {withCredentials: true})
          .then((res) => {
          setCurrentUser(res.data)
          smScreen.matches ? navigate('/welcome_retailers') : navigate('/')

          })
      })
  }

  return (
    <section className={'h-screen w-full px-4 flex flex-col'}>
      <LogoHeader />
      <main className='flex h-[calc(100%-56px)]'>
        <section className={'h-full w-full bg-[url("/entrypoints/images/background-colors.svg")] bg-contain bg-center bg-no-repeat overflow-y-scroll overflow-x-hidden'}>
          <section className='w-full md:w-[464px] mt-6 mx-auto'>
            <Header1 styles={'text-center'}>Create Password</Header1>
            <p className='text-center text-xs font-reg'>Minimum 8 characters</p>
            <Formik
              initialValues={{
                createPassword: '',
                confirmPassword: '',
              }}
              validationSchema={Yup.object({
                createPassword: Yup.string()
                  .min(8, 'Minimum 8 characters')
                  .max(128, 'Maximum 128 characters')
                  // .matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=x*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/, 'Password must be between 8 and 32 characters with at least one letter, one number, and one special character.')
                  .required('Required'),
                confirmPassword: Yup.string()
                  .oneOf([ref("createPassword")], "Passwords do not match")
                  .required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleSubmit({password: values.createPassword, status: 'password_created'})
                  setSubmitting(false)
                }, 400)
              }}
            >
              <Form>
                <TextInput
                  label="Create Password*"
                  name="createPassword"
                  type="password"
                  placeholder=""
                />

                <TextInput
                  label="Confirm Password*"
                  name="confirmPassword"
                  type="password"
                  placeholder=""
                />

                <div className={'mt-6 mb-24 w-full'}>
                  <CtaButton id={'create-password-button'} submit={true}>Create Password</CtaButton>
                </div>
              </Form>
            </Formik>
          </section>
        </section>
      </main>
    </section>
  )
}

export default CreatePassword
