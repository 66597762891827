import React, { useContext } from "react"
import { Link, useNavigate,useSearchParams, useLocation } from "react-router-dom"
import Logo from "../../../entrypoints/images/logo240.webp"
import UserSquare from '../../../entrypoints/images/user-square-pink.svg'
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"
import HeartOutline from '../../../entrypoints/images/unfavorited-heart.svg'
import HeartSolid from '../../../entrypoints/images/favorited-heart.svg'
import axios from 'axios'
import SecondaryCtaButton from "../../atoms/buttons/SecondaryCtaButton.tsx"
import NavHeader from '../headers/NavHeader.tsx'
import Marquee from '../../marquee'

const AccountHeader = ({ openSignInModal, signOut }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)
  const location = useLocation()
  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  const getHomeLinkPath = () => {
    if (currentUser?.brand) {
      return '/dashboard'
    }
    return '/'
  }

  const goToFavorites = () => {
    if (searchParams.get('filter') == 'My Favorites') {
      window.location.reload()
    } else {
      navigate('/dashboard/filters?filter=My Favorites')
    }
  }

  return (
    <React.Fragment>
      { location.pathname === '/' || location.pathname === '/dashboard' || location.pathname === '/marketplace' ? <Marquee /> : null }
      <header className="fixed top-0 w-screen flex border-b-2 border-gray h-20 px-2 xl:px-8 bg-white z-50">
        <Link to={getHomeLinkPath()} className={"h-full w-1/6 xl:w-1/5 flex items-center"}>
          <img
            src={Logo}
            alt={"made me buy it logo"}
            id={"header-logo"}
            className="w-52"
          />
        </Link>

        <div
          className={
            "h-full w-2/3 xl:w-3/5 flex flex-col justify-center justify-center px-3 relative"
          }
        >
          <NavHeader />
        </div>

        <div className={'h-full w-1/6 xl:w-1/5 flex items-center justify-end'}>
          {
            currentUser?.email ? (
              <div className={'w-52 flex justify-between items-center'}>
                {
                  currentUser?.retailer &&
                  <button className={'relative flex h-full w-full'} onClick={goToFavorites}>
                    <img src={HeartSolid} alt={'view favorites'} className={"h-10 w-10 opacity-0 hover:animate-ping absolute hover:opacity-75 z-40"}/>
                    <img src={HeartOutline} alt={'view favorites'} className={"h-10 w-10 relative z-30"}/>
                  </button>
                }
                <div className={'shrink-0 w-24 lg:w-32'}>
                  <SecondaryCtaButton id={"header-sign-out"} onclick={signOut} >
                    <img src={UserSquare} alt={"user-icon"} className={"h-6 w-6 mr-2 hidden lg:inline"} />
                    <p className="w-fit">Sign Out</p>
                  </SecondaryCtaButton>
                </div>
              </div>
            ) : (
              <div className={'w-24 lg:w-32'}>
                <SecondaryCtaButton id={'header-sign-in-button'} onclick={() => {openSignInModal()}}>
                  <img src={UserSquare} alt={"user-icon"} className={"h-6 w-6 mr-2 hidden lg:inline"} />
                  <p className="w-fit">Sign In</p>
                </SecondaryCtaButton>
              </div>
            )}
        </div>
      </header>
    </React.Fragment>
  )
}

export default AccountHeader
