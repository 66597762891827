import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Paragraph1 from "../../../../components/atoms/text/Paragraph1"
import LightArrow from "../../../../entrypoints/images/arrow-right.svg";
import DarkArrow from "../../../../entrypoints/images/arrow-right-dark.svg";
import ShopifyBagGreen from '../../../../entrypoints/images/shopify-green.svg'

const DashNavLink = ({ path, title, children }: {path: string, title?: string, children?: any}) => {
  const location = useLocation()
  const isActive = (path) => {
    return (location.pathname === '/dashboard' || location.pathname === '/dashboard/') ? !!(path === 'dashboard/overview') : !!(location.pathname.includes(path))
  }

  return (
    <NavLink
      to={`/${path}`}
      className={
        `w-full h-12 px-3 flex justify-between items-center border ${isActive(path) ? 'bg-dark-gray border-dark-gray' : 'bg-white border-gray'}`
      }
    >
      <div className={'flex'}>
        { children }
        { title && <Paragraph1 styles={isActive(path) ? 'text-white' : 'text-medium-gray' }>{ title }</Paragraph1> }
      </div>
      <img src={isActive(path) ? LightArrow : DarkArrow} alt={"right arrow"} className={""} />
    </NavLink>
  )
}

export default DashNavLink
