import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ProfileSection from '../molecules/ProfileSection.tsx'
import TextInput from '../../../molecules/inputs/TextInput.tsx'
import CtaButton from '../../../atoms/buttons/CtaButton.tsx'

const DashSettings = () => {
  return (
    <section className={"w-2/3 flex flex-col"}>
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          full_name: Yup.string()
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          password: Yup.string()
            .required('Required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            console.log(values)
            setSubmitting(false)
          }, 400)
        }}
      >
        <Form>
          <ProfileSection headerText={'Profile Details'} >
            <TextInput
              label={'Full Name'}
              name={'full_name'}
              type={'text'}
              placeholder={''}
            />
            <TextInput
              label={'Email Address'}
              name={'email'}
              type={'text'}
              placeholder={''}
            />
          </ProfileSection>
          <ProfileSection headerText={'Password & Security'} >
            <div className={'w-full flex items-end'}>
              <div className={'w-3/4'}>
                <TextInput
                  label={'Current Password'}
                  name={'password'}
                  type={'text'}
                  placeholder={''}
                />
              </div>
              <button className={'h-10 w-1/4 border border-gray text-sm font-reg text-dark-gray'}>Update Password</button>
            </div>
          </ProfileSection>

          <div className={'bg-transparent-white border-x border-b border-gray p-5 flex justify-center'}>
            <div className={'w-80'}>
              <CtaButton id={'brand-dash-settings-save-button'} submit={true}>Save Profile</CtaButton>
            </div>
          </div>

          </Form>
      </Formik>
    </section>
  )
}

export default DashSettings
