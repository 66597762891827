import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CurrentUserContext } from "../../../contexts/CurrentUser"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const NavHeader = () => {

  const location = useLocation()
  const active = 'text-almost-black border-b-2 border-pink '
  const inactive = 'text-medium-gray hover:border-b-2 hover:border-light-pink '
  const styles = 'text-sm lg:text-xl font-reg text-center h-full w-fit w-32 grid place-content-center '
  const { currentUser } = useContext(CurrentUserContext)
  let [isMarketplace, setMarketplace] = useState(null)
  let [isDashboard, setDashboard] = useState(null)
  let [newPicks, setNewPicks] = useState(null)

  useEffect(() => {
    setMarketplace(location.pathname.includes('marketplace'))
    setDashboard(location.pathname.includes('dashboard'))
    location.pathname.includes('my_picks') && setNewPicks(false)
  }, [location])

  useEffect(() => {
    setNewPicks(currentUser.new_picks)
  }, [currentUser])

 return (
   <header className='w-full h-full flex justify-center items-center bg-white'>
     <nav className={'w-full h-10 flex justify-between'}>
       {
         currentUser?.brand && (
           <React.Fragment>
             <NavLink to={'/'} className={({ isActive }) => isActive || isDashboard ? active + styles : inactive + styles } id={'nav-dashboard-link'}>
               My Dashboard
             </NavLink>
             <NavLink to={'/all_products?filter=All Products'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-all-products-link'}>
               All Products
             </NavLink>
             <NavLink to={'/marketplace'} className={({ isActive }) => isActive ? active + styles : inactive + styles }>
               Marketplace
             </NavLink>
             <NavLink to={'/samples'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-samples-link'} onClick={(e) => { e.preventDefault(); window.location.href = '/samples'}}>
               Samples
             </NavLink>
             <NavLink to={'/about_us'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-about-link'}>
               About Us
             </NavLink>
           </React.Fragment>
         )
       }
       {
         currentUser?.retailer && (
           <React.Fragment>
             <NavLink to={'/'} className={({ isActive }) => isActive || isMarketplace ? active + styles : inactive + styles }>
               Marketplace
             </NavLink>
             <NavLink to={'/all_products?filter=All Products'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-all-products-link'}>
               All Products
             </NavLink>
             <NavLink to={'/my_picks'} className={({ isActive }) => isActive ? active + styles + " relative" : inactive + styles + " relative" } id={'nav-about-link'}>
               My Picks
               {
                 newPicks &&
                 <React.Fragment>
                   <FontAwesomeIcon icon={faCircle} title='New curated picks!' className={`absolute top-2 -right-4 h-3 w-3 text-pink`} />
                   <FontAwesomeIcon icon={faCircle} className={`absolute top-2 -right-4 h-3 w-3 text-pink animate-ping`} />
                 </React.Fragment>
               }
             </NavLink>
             <NavLink to={'/dashboard/filters?filter=My Favorites'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-dashboard-link'}>
               My Favorites
             </NavLink>
           </React.Fragment>
         )
       }
       {
         (!currentUser?.email || (!currentUser?.retailer && !currentUser?.brand)) && (
           <React.Fragment>
             <NavLink to={'/'} className={({ isActive }) => isActive || isMarketplace ? active + styles : inactive + styles }>
               Marketplace
             </NavLink>
             <NavLink to={'/all_products?filter=All Products'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-all-products-link'}>
               All Products
             </NavLink>
             <NavLink to={'/sign_up'} className={ inactive + styles } id={'nav-dashboard-link'}>
               Dashboard
             </NavLink>
             <NavLink to={'/samples'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-samples-link'} onClick={(e) => { e.preventDefault(); window.location.href = '/samples'}}>
               Samples
             </NavLink>
             <NavLink to={'/about_us'} className={({ isActive }) => isActive ? active + styles : inactive + styles } id={'nav-about-link'}>
               About Us
             </NavLink>
           </React.Fragment>
         )
       }
     </nav>
   </header>
 )
}

export default NavHeader
