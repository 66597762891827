import React from "react"
import HeadingText from "~/components/onboarding/molecules/HeadingText.tsx";
import { Link } from 'react-router-dom'

function FormSubmitted() {
  return (
    <article className='h-fit border border-gray mt-8 md:mt-12 mx-8 pb-8 px-3 sm:px-8 bg-transparent-white backdrop-blur-xl flex flex-col items-center'>
      <HeadingText text='Thanks for your submission' />
      <p className='max-w-md text-center text-2xl font-reg mb-4'>We will review your application and you have been added to the waitlist.</p>
      <p className='max-w-md text-center text-2xl font-reg mb-4'>We will respond via email in 24 hours.</p>
      <Link to='/' className='w-60 sm:w-80 h-12 bg-dark-gray text-base font-reg text-white flex justify-center items-center'>
        Back to Home
      </Link>
    </article>
  )
}

export default FormSubmitted
