import React, { useState, useEffect } from 'react'
import Header1 from "../../components/atoms/text/Header1"
import BrandCard from '../molecules/BrandCard'
import axios from 'axios'

const BrandsHome = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [brands, setBrands] = useState([])

  useEffect(() => {
    axios.get('/brands?sort=newest').then((resp) => {
      setBrands(resp.data)
    })
  }, [])

  return (
    <section className={'h-full w-[1024px] bg-none'}>
      <section className={'mt-8 flex'}>
        {/*<aside className={'w-1/5 flex flex-col items-start'}>*/}
        {/*/!*  /!*  Add filters in the future *!/*!/*/}
        {/*</aside>*/}
        <section className={'w-full'}>
          <Header1 styles={'mb-4'}>All Brands</Header1>
          <div className={'w-full grid grid-cols-4 gap-x-4 gap-y-4'}>
            {
              brands.map((b) => {
                return <BrandCard brand={b} />
              })
            }
          </div>
        </section>
      </section>
    </section>
  )
}

export default BrandsHome
