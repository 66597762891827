import React from "react"

function Header1({ styles, children }: { styles?: string, children: string}) {

  return (
    <h1 className={`text-3xl text-almost-black font-bold uppercase ${styles}`}>
      { children }
    </h1>
  )
}

export default Header1
