import React, { useEffect, useState, useRef } from 'react'
import { useField } from 'formik'
import DownArrow from '../../../entrypoints/images/chevron-right.svg'
import UpArrow from '../../../entrypoints/images/Path.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons"

const FollowersSelectInput = ({ label, selectOptions, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const [options, setOptions] = useState([])
  const [isOpen, setOpen] = useState(false)
  let [err, setErr] = useState(meta.touched && meta.error)

  let inputRef = useRef(null)

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.parentNode && !e.target.parentNode.matches('.input-dropdown')) {
        setOpen(false)
      }
    })
  }, [inputRef])

  useEffect(() => {
    setErr(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  useEffect(() => {
    let opts = selectOptions?.map((o, i) => {
      return (
        <button
          type={'button'}
          value={o}
          className={`input-dropdown h-10 w-full border border-gray font-reg flex items-center px-3 hover:border-2 hover:border-blue hover:text-almost-black hover:bg-transparent-blue text-medium-gray`}
          onClick={handleClick}
        >
          { o }
        </button>
      )
    })
    setOptions(opts)
  }, [])

  const handleClick = (e) => {
    setValue(e.target.value)
    setOpen(false)
  }

  return (
    <div className={'relative input-dropdown relative font-reg w-full'} >
      <label htmlFor={props.id || props.name} className={`text-sm font-reg my-2 ${err && 'text-red-600 font-bold'}`}>
        {label}
      </label>
      <button
        type={'button'}
        onClick={(e) => { setOpen(!isOpen) }}
        className={`input-dropdown w-full h-10 flex justify-between items-center px-3 border ${err ? 'border-red-600' : 'border-gray'} bg-white text-sm font-reg`} id={props.id || props.name}
        ref={inputRef}
      >
        { field.value || "" }
        <img src={isOpen ? UpArrow : DownArrow} alt={isOpen ? 'close options' : 'open options'} className={'w-1/12 h-4 w-4'} />
      </button>
      {err &&
        <div className={'absolute flex items-center'}>
          <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mr-2`} />
          <p className="text-sm font-reg text-red-600">{meta.error}</p>
        </div>
      }
      {
        isOpen &&
        <div className={'input-dropdown overflow-y-scroll border border-gray w-full bg-white flex flex-col absolute z-40 h-40'}>
          { options }
        </div>
      }
    </div>
  )
}
export default FollowersSelectInput
