import React, { useEffect, useState, useContext } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import axios from 'axios'
import { CurrentUserContext } from '../contexts/CurrentUser.js'
import ReactGA from 'react-ga4'

export default function MagicSignIn() {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const navigate = useNavigate()

  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { token } = useParams()
  const location = useLocation()
  const smScreen = window.matchMedia("(min-width: 640px)")

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  }, [location])


  useEffect(() => {
    axios.get(`/magic_sign_in?magic_sauce=${token}`, {withCredentials: true}).then(()  => {
      axios.get('/me', {withCredentials: true}).then(
        (r) => {
          setCurrentUser(r.data)
          if (r.data.retailer) {
            r.data.status === 'approved' ? navigate('/retailers_create_password')
              : (r.data.status === 'password_created' && smScreen.matches) ? navigate('/welcome_retailers')
                : navigate('/')
          } else if (r.data.brand) {
            r.data.brand.description ? navigate('/') : navigate('/brand_inquiry/create_profile')
          }
        }
      )
    })
  },[])

  return (
    <section>
      Loading
    </section>
  )
}
