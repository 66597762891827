import React from 'react'

const CurrentTrends = () => {

  return (
    <React.Fragment>
      <section className={'w-[1024px] '}>
        <section className={'w-full flex justify-center pt-6'}>
          <h1 className={'font-reg text-almost-black text-4xl'}>Coming soon! 👀</h1>
        </section>
      </section>
    </React.Fragment>
  )
}

export default CurrentTrends
