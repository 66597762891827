import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from "~/entrypoints/images/magnifier.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
const Searchbar = ({ query, setQuery }) => {

  let navigate = useNavigate()
  const clearQuery = () => {
    setQuery('')
    navigate('/all_products')
  }

  const submitQuery = () => {
    navigate(`/all_products/search?query=${query}`)
  }

  const determineSubmit = (v) => {
    v === query && submitQuery()
  }

  const handleChange = (e) => {
    setTimeout(() => {
      determineSubmit(e.target.value)
    }, 500)
  }

  return (
    <div className={`h-12 w-full flex justify-center items-center relative`}>
      <input
        id={"products-search-bar"}
        type={"text"}
        placeholder={"Search..."}
        className={
          "h-full w-full border-2 border-pink rounded-full font-bold text-black placeholder-black focus:border-blue focus:outline-none focus:ring-transparent pl-6"
        }
        onKeyUp={(e) => { handleChange(e) }}
        value={query}
        onChange={(e) => { setQuery(e.target.value) }}
      />
      {
        query ?
          <button className={'absolute right-6 top-3'} onClick={clearQuery}>
            <FontAwesomeIcon icon={faX} className={`text-almost-black`} />
          </button>
          : <img src={SearchIcon} className={"absolute right-6 top-4"} />
      }
    </div>
  )
}

export default Searchbar
