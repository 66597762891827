import React from "react"

function Paragraph1({ styles, children }: { styles?: string, children: any }) {
  // const color = () => {
  //   return (textColor == 'light') ? 'text-white'
  //     : (activeStatus == 'active') ? 'text-almost-black'
  //     : 'text-medium-gray'
  // }

  return (
    <p className={`text-almost-black text-sm font-reg ${styles}`}>
      { children }
    </p>
  )
}

export default Paragraph1
