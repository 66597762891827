import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'


const UsRetailerMultiSelect = ({ selected, setSelected }: { selected?: string[], setSelected?: Function }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [usRetailers, setUsRetailers] = useState([])
  let [usRetailersLoading, setUsRetailersLoading] = useState(false)

  const getUSRetailerList = () => {
    axios.get('/profiles/us_retailers').then((r) => {
      let list = r.data.all_retailers.map((retailer) => {
        return { label: retailer, value: retailer }
      })
      setUsRetailers(list)
    })
  }

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions)
  }

  useEffect(() => {
    getUSRetailerList()
  }, [])

  useEffect(() => {
    usRetailers.length ? setUsRetailersLoading(false) : setUsRetailersLoading(true)
  }, [usRetailers])

  return (
    <Select
      name={'us-retailers-select'}
      value={selected}
      onChange={handleChange}
      isMulti
      isLoading={usRetailersLoading}
      options={usRetailers}
      classNamePrefix="react-select"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          "min-height": '40px',
          border: '1px solid #EAEAEA',
          'border-radius': '0px',
          'font-family': 'Poppins-Regular',
          'box-shadow': 'none',
          "control:hover": {
            border: '1px solid #EAEAEA',
          },
          "control:focus": {
            border: '1px solid #EAEAEA',
          }
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          'font-family': 'Poppins-Regular',
          color: '#050000',
          'background-color': state.isFocused ? 'rgba(19, 241, 255, 0.1)' : '#FFFFFF',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          margin: 'none',
        }),
        multiValue: (baseStyles, state) => ({
          ...baseStyles,
          margin: 'none',
          padding: 'none',
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          "input:focus": {
            'box-shadow': 'none',
          }
        })
      }}
    />
  )
}

export default UsRetailerMultiSelect
