import React, { useState, useEffect } from 'react'
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import Home from '../routes/home.tsx'
import Marketplace from '../components/organisms/Marketplace.tsx'
import CurrentTrends from '../components/organisms/CurrentTrends.tsx'
import Dashboard from '../routes/dashboard.tsx'
import Inquiry from "../routes/inquiry.tsx"
import MagicSignIn from '../routes/magic_sign_in.jsx'
import BrandStore from '../components/organisms/BrandStore.tsx'
import ProductDetails from './organisms/ProductDetails.tsx'
import ProductsHome from './organisms/ProductsHome.tsx'
import BrandsHome from './organisms/BrandsHome.tsx'
import FormSubmitted from '../components/onboarding/templates/FormSubmitted.tsx'
import RetailerInquiryForm from "../components/onboarding/templates/RetailerInquiryForm"
import BrandInquiryForm from "../components/onboarding/templates/BrandInquiryForm"
import BrandProfileForm from '../components/onboarding/templates/BrandProfileForm.tsx'
import Overview from '../components/dashboard/brand/organisms/DashOverview.jsx'
import ManageProducts from '../components/dashboard/brand/organisms/ManageProducts.jsx'
import ManageOrders from '../components/dashboard/brand/organisms/ManageOrders.jsx'
import DashProfile from './dashboard/brand/organisms/DashProfile.jsx'
import DashSettings from '../components/dashboard/brand/organisms/DashSettings.jsx'
import DailyLeadsSettings from '../components/dashboard/brand/organisms/DailyLeadsSettings.jsx'
import AboutUs from './organisms/about.tsx'
import ProductEdit from './dashboard/brand/ProductEdit.jsx'
// import ShopifyProductEdit from './dashboard/brand/ShopifyProductEdit.tsx'
import ProductNew from './dashboard/brand/ProductNew.tsx'
// import Shopify from './dashboard/brand/Shopify.jsx'
import CsvImport from './CsvImport.jsx'
import RetailerCreatePassword from './onboarding/retailer/RetailerCreatePassword.tsx'
import RetailerWelcome from './onboarding/retailer/RetailerWelcome.tsx'
import MyPicks from '../components/organisms/MyPicks.jsx'
import axios from 'axios'

const privateRoutes = () => {
  return (
    {
      element: <Home />,
      children: [
        {
          path: '/all_products',
          element: <ProductsHome />,
        },
        {
          path: '/all_products/search',
          element: <ProductsHome />
        },
        {
          path: '/all_products/filter/:filter',
          element: <ProductsHome />
        },
        {
          path: '/all_products/:brand_id/:product_id',
          element: <ProductDetails />
        },
        {
          path: '/all_brands',
          element: <BrandsHome />,
        },
        {
          path: '/all_brands/:brand_id',
          element: <BrandStore />
        },
        {
          path: '/current_trends',
          element: <CurrentTrends />
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
          children: [
            {
              index: true,
              element: <Overview />
            },
            {
              path: 'overview',
              element: <Overview />
            },
            {
              path: 'manage_products',
              element: <ManageProducts />
            },
            {
              path: 'manage_orders',
              element: <ManageOrders />
            },
            {
              path: 'brand_profile',
              element: <DashProfile />
            },
            {
              path: 'settings',
              element: <DashSettings />
            },
            {
              path: 'manage_products/:product_id',
              element: <ProductEdit />
            },
            {
              path: 'manage_products/new',
              element: <ProductNew />
            },
            {
              path: 'filters',
              element: <ProductsHome />
            },
            {
              path: 'csv_import',
              element: <CsvImport />
            },
            {
              path: 'daily_leads_settings',
              element: <DailyLeadsSettings />
            },
          ]
        },
        {
          path: 'my_picks',
          element: <MyPicks />
        }
      ]
    }
  )
}

const publicRoutes = () => {
  return (
    [
      {
        element: <Home />,
        children: [
          {
            path: '/all_products',
            element: <ProductsHome />,
          },
          {
            path: '/all_brands',
            element: <BrandsHome />,
          },
          {
            path: '/all_brands/:brand_id',
            element: <BrandStore />
          },
          {
            index: true,
            element: <Marketplace />,
          },
          {
            path: '/marketplace',
            element: <Marketplace />,
          },
          {
            path: 'about_us',
            element: <AboutUs />
          },
          {
            path: 'all_products/:id/*',
            element: <Navigate to="/sign_up" replace />
          },
          {
            path: '*',
            element: <Navigate to="/" replace />
          }
        ]
      },
      {
        path: '/sign_in',
        element: <Home />,
        children: [
          {
            index: true,
            element: <Marketplace />,
          },
        ]
      },
      {
        path: '/sign_up',
        element: <Home />,
        children: [
          {
            index: true,
            element: <Marketplace />,
          },
        ]
      },
      {
        path: '/forgot_password',
        element: <Home />,
        children: [
          {
            index: true,
            element: <Marketplace />
          }
        ]
      },
      {
        path: '/retailers_create_password',
        element: <RetailerCreatePassword/>
      },
      {
        path: '/welcome_retailers',
        element: <RetailerWelcome/>
      },
      {
        path: '/brand_inquiry',
        element: <Inquiry />,
        children: [
          {
            index: true,
            element:  <BrandInquiryForm />
          },
          {
            path: "submitted",
            element: <FormSubmitted />
          },
          {
            path: "create_profile",
            element: <BrandProfileForm />
          },
        ]
      },
      {
        path: "/magic/sign_in/:token",
        element: <MagicSignIn />,
      },
      {
        path: '/retailer_inquiry',
        element: <Inquiry />,
        children: [
          {
            index: true,
            element: <RetailerInquiryForm />
          },
          {
            path: "submitted",
            element: <FormSubmitted />
          },
        ]
      },
    ]
  )
}

import { CurrentUserContext } from '../contexts/CurrentUser'

const Navigation = () => {
  let [currentUser, setCurrentUser] = useState({loading: true})

  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

  const checkAuth = () => {
    axios.get('/me').then((r) => {
      setCurrentUser(r.data)
    }).catch((e) => {
      setCurrentUser({})
    })
  }

  useEffect(() => {
    checkAuth()
  }, [])

  const router = createBrowserRouter([
    currentUser.email ? privateRoutes() : {},
    ...publicRoutes(),
  ]);

  return (!currentUser.loading && <CurrentUserContext.Provider value={ { currentUser, setCurrentUser } }><RouterProvider router={router} /></CurrentUserContext.Provider>)
}

export default Navigation
