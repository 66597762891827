import React from "react"

function Header2({ styles, children }: { styles?: string, children: string }) {

  return (
    <h2 className={`text-almost-black text-2xl font-med ${styles}`}>
      { children }
    </h2>
  )
}

export default Header2
