import React from 'react'
import FaqAccordion from '../FaqAccordion.tsx'
import Header1 from "../../components/atoms/text/Header1"
import Header2 from "../../components/atoms/text/Header2"
import Paragraph1 from "../../components/atoms/text/Paragraph1"


const About = () => {

  return (
    <React.Fragment>
      <section className="w-[1024px]">
        <Header1 styles={'mt-8'}>About Us</Header1>
        <article className={'mt-4 p-4 border border-gray text-justify bg-transparent-white'}>
          <Header2 styles={'mb-4'}>Welcome to MadeMeBuyIt!</Header2>
          <Paragraph1 styles={'mb-2'}>
            We saw how difficult it was for ecommerce brands to get into national retailers; the discovery process, the onboarding process, the shipments and the payments.
          </Paragraph1>
          <Paragraph1 styles={'mb-2'}>
            We also spoke with national retailers, and learned that it was difficult for them to discover, source and purchase from amazing brands and products found on TikTok and Instagram.
          </Paragraph1>
          <Paragraph1 styles={'mb-2'}>
            With MadeMeBuyIt, we make it ridiculously easy for brands to sell to national retailers; just sign up, create an account for free, and upload your SKU’s. You’ll then get notified from retailers who want to buy your products.
          </Paragraph1>
          <Paragraph1 styles={'mb-2'}>
            Additionally, we make it <em>even</em> easier for retailers to discover new brands; just sign up, create an account for free, and discover all of the amazing brands on TikTok and Instagram in one easy place.
          </Paragraph1>
          <Paragraph1>
            Email me with any questions! <a href={'mailto: Ben@mademebuyit.io'} className={'text-link-blue underline'}>Ben@mademebuyit.io</a>
          </Paragraph1>
        </article>
        <FaqAccordion />
      </section>
    </React.Fragment>
  )
}

export default About
