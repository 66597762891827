import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const Checkbox = ({ label, checked, ...props }) => {
  const [field, meta] = useField(props)
  let [err, setErr] = useState(meta.touched && meta.error)

  useEffect(() => {
    setErr(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  return (
    <div className={'flex items-center'}>
      <label htmlFor={props.id || props.name} className={`text-sm font-reg my-2 ${err && 'text-red-600 font-bold flex items-center'}`}>
        {label}
        {err &&
          <div className={'flex items-center'}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mx-2`} />
            <p className="text-sm font-reg text-red-600">{meta.error}</p>
          </div>
        }
      </label>
      <input type={'checkbox'} checked={checked} className={`!appearance-none ml-2 border border-almost-black checked:border-blue checked:bg-blue ${err ? 'border-red-600' : 'border-gray'}`}  {...field} {...props} />
    </div>
  )
}

export default Checkbox
