import React from 'react'
import Header1 from '../atoms/text/Header1'

export default function BrandSelector({brands, setSelectedBrand, selectedBrand}) {
  return (
    <>
      <Header1>Choose Brand</Header1>
      <select onChange={(e) => setSelectedBrand(brands.find(brand => brand.id === e.target.value))}>
        {brands.map(brand => <option key={brand.id} value={brand.id} selected={selectedBrand && selectedBrand.id === brand.id}>{brand.name}</option>)}
      </select>
    </>
  )
}
