import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  let [err, setErr] = useState(meta.touched && meta.error)

  useEffect(() => {
    setErr(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  return (
    <React.Fragment>
      <label htmlFor={props.id || props.name} className={`text-sm font-reg my-2 ${err && 'text-red-600 font-bold flex items-center'}`}>
        {label}
        {err &&
          <div className={'flex items-center'}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mx-2`} />
            <p className="text-sm font-reg text-red-600">{meta.error}</p>
          </div>
        }
      </label>
      <textarea className={`w-full border ${err ? 'border-red-600' : 'border-gray'} text-sm font-reg overflow-y-scroll ${props.resize || 'resize-none'} ${props.height || 'h-24'}`}  {...field} {...props} />
    </React.Fragment>
  )
}

export default TextAreaInput
