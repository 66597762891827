import React from 'react'
import Header1 from '../atoms/text/Header1'

export default function PicksTable({picks, setPicks, removePick}) {
  return (
    <>
    { picks && picks.length > 0 ? (
    <div className='mt-5'>
      <Header1 styles='ml-10'>Picks</Header1>
      <table className='ml-10'>
        <thead>
          <tr>
            <th className='min-w-10'>Product</th>
            <th className='min-w-10'>Brand</th>
            <th className='min-w-10'>MSRP</th>
            <th></th>
            <th className='min-w-10'>Remove</th>
          </tr>
        </thead>
        <tbody>
          {picks.map((product) => (
            <tr key={product.id}>
              <td className='px-10'>{product.name}</td>
              <td className='px-10'>{product.brand.name}</td>
              <td className='px-10'>{product.msrp_cents / 100}</td>
              <td className='px-10'><img className='h-20 w-20' src={product.photo_1_url} alt={product.name} /></td>
              <td className='px-10'><button className='cursor-pointer' onClick={() => removePick(product)}><u>Remove</u></button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    ) : <div></div> }
    </>
  )
}
