import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Arrow from '../../../entrypoints/images/arrow-right.svg'
import HeadingText from "../molecules/HeadingText.tsx"
import TextInput from "../../molecules/inputs/TextInput.tsx"
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import Overline from "~/components/atoms/text/Overline";
import Header1 from "~/components/atoms/text/Header1";
import Paragraph1 from "~/components/atoms/text/Paragraph1";
import BuyerCategoryMultiSelect from "~/components/molecules/inputs/BuyerCategoryMultiSelect";

const RetailerInquiryForm = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let navigate = useNavigate()
  let [selectedBuyerCategories, setSelectedBuyerCategories] = useState([])
  let [customBuyerCategories, setCustomBuyerCategories] = useState([])

  const createRetailerProfile = (values, setSubmitting) => {
    axios.post("/profiles", values).then(
      (r) => {
        setSubmitting(false)
        navigate('/retailer_inquiry/submitted')
      }
    )
  }

  useEffect(() => {
    axios.get("/favorites").then(
      (r) => {
        console.log(r.data)
      }
    )
    axios.get("/tags").then(
      (r) => {
        console.log("tags: ",r.data)
      }
    )
  }, [])

  return (
    <section className='w-full sm:w-[496px] mx-4'>
      <header className={'mt-4 flex flex-col items-center'}>
        <Overline>Retailers</Overline>
        <Header1 styles={'text-center'}>discover gen z brands and products</Header1>
        <Paragraph1 styles={'text-center'}>Unlock wholesale prices for the most viral and trending brands and products on TikTok and Instagram</Paragraph1>
      </header>
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          retailer_name: '',
        }}
        validationSchema={Yup.object({
          full_name: Yup.string()
            .required('Required'),
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('Required'),
          retailer_name: Yup.string()
            .required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            axios.post('/custom_buyer_categories', { buyer_categories: customBuyerCategories.map((c) => c.label) } ).then((res) => {
              values.buyer_category_ids = selectedBuyerCategories.map((r) => r.value).concat(res.data.map((r) => r.id))
              createRetailerProfile({profile: values}, setSubmitting)
            })
          }, 400);
        }}
      >
        <Form>
          <TextInput
            label="Your Name*"
            name="full_name"
            type="text"
            placeholder=""
          />

          <TextInput
            label="Email Address*"
            name="email"
            type="email"
            placeholder=""
          />

          <TextInput
            label="Retailer Company Name*"
            name="retailer_name"
            type="text"
            placeholder=""
          />

          <label for={'buyer-categories-select'} className={'text-sm font-reg my-2'}>Buying Categories (Select all that apply)*</label>
          <BuyerCategoryMultiSelect selected={selectedBuyerCategories} setSelected={setSelectedBuyerCategories} custom={customBuyerCategories} setCustom={setCustomBuyerCategories}/>

          <div className={'mt-4 mb-24 w-full'}>
            <CtaButton id={'retailer-inquiry-submit-button'} submit={true}>Submit</CtaButton>
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default RetailerInquiryForm
