//create a 'one-pager' teaser for the Daily Leads product, allowing users to receive a tailored lead to their email every day for $99/month. show an example lead with sample information - profile photo, about section, interests, suggest hand written introduction and product sample, a small gift relevant to lead, etc, and contact information including phone, email, mailing address, linkedin profile.
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
export default function DailyLeadsSettings() {
  return (
    <div className="w-11/12">
      <h1 className="text-2xl font-bold">Daily Leads</h1>
      <div className='w-11/12 mx-auto'>
        <p className="text-3xl font-bold text-center my-5 text-almost-black uppercase">Receive a top quality, custom-tailored lead to your email every day for $99/month!</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-11/12 p-5 border rounded-xl bg-white text-sm font-reg">
          <h2 className="text-xl font-bold mb-5">Example Lead</h2>
          <img src="/woman.jpg" alt="profile photo" className='w-full mx-auto rounded-xl' />
          <p className='block mt-5'>
            <h2 className="text-pink text-3xl font-bold mb-2">Jane Doe - Target Buyer</h2>
            <p>Meet <b>Jane Doe</b>, an accomplished buyer at Target with a robust 15-year background in retail management and product sourcing. She holds an MBA from Columbia University, where she specialized in Business Strategy and Operations. Throughout her career, Jane has fine-tuned her expertise in market research, contract negotiation, inventory management, and supplier relationship management.</p><br />
            <p>Jane's passion lies in driving innovation and sustainability within the retail sector. Regularly contributing to industry publications, she shares insights on the transformative impact of eco-friendly practices in retail operations. Her dedication to sustainability is not just professional; Jane is also an advocate for sustainable living in her personal life.</p><br />
            <p>Jane's professional network at Target spans a diverse range of roles, from Junior Buyers to Senior Executive Managers. She is known for her collaborative approach, often engaging with cross-functional teams to ensure seamless execution of retail strategies.</p><br />
            <p>What sets her apart? Jane is approachable, genuine, and deeply committed to helping others achieve their professional goals. Her leadership style is characterized by authenticity, empathy, and a genuine interest in the development of her team members.</p><br />
            <p className='font-bold text-pink'><b>Why send Jane a product sample?</b></p>
            <p>Given her extensive and influential network within Target, sending Jane a thoughtful gift could pave the way for your products to gain significant visibility. Imagine receiving a personalized, handwritten note from someone who not only recognizes the value of your brand but is also genuinely excited about your products!</p><br />
            <p>Take this unique opportunity to send her a product sample that resonates with her interests and values, particularly if your products align with sustainability and innovation. This gesture could be the catalyst for new business opportunities and invaluable connections in the retail world!</p>
          </p>
          <p>
            <h3 className='mt-5 text-xl font-bold text-pink'>Details:</h3>
            <ul className='text-lg ml-5'>
              <li><b className='font-bold'>Name:</b> Jane Doe</li>
              <li><b className='font-bold'>Company:</b> Target</li>
              <li><b className='font-bold'>Position:</b> Buyer</li>
              <li><b className='font-bold'>Experience:</b> 15 years</li>
              <li><b className='font-bold'>Education:</b> MBA</li>
              <li><b className='font-bold'>Interests:</b> Leadership development, trend analysis, sales</li>
            </ul>
          </p>
          <p>
            <h3 className='mt-5 text-xl font-bold text-pink'>Contact Information:</h3>
            <ul className='text-lg ml-5'>
              <li><b className='font-bold'>Email: </b>Jane.Doe@Target.com</li>
              <li><b className='font-bold'>Phone: </b>(555)-555-5555</li>
              <li><b className='font-bold'>Office Address: </b>1313 Mockingbird Lane, Beverly Hills, California, 90210</li>
              <li><b className='font-bold'>LinkedIn: </b><a href='#'>https://www.linkedin.com/in/example-lead</a></li>
            </ul>
          </p>
        </div>
        <div className={`mt-5 h-full w-1/2 border-2 border-pink rounded-full bg-light-pink font-med text-dark-gray text-xl transform translate-x-px transitional-all hover:transition-all hover:-translate-x-px hover:translate-y-px grid place-content-center disabled:bg-gray disabled:text-almost-black disabled:border-none`}>
        <Link to="/dashboard/daily_leads_settings">
          <p className='p-5 font-almost-black uppercase'>
          Subscribe Now!
          </p>
        </Link>
        </div>
      </div>
    </div>
  )
}
