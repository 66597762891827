import React, {useState, useEffect, useRef} from 'react'
import EmailSelector from './molecules/EmailSelector'
import PicksTable from './molecules/PicksTable'
import axios from 'axios'
import Header1 from './atoms/text/Header1'

export default function CuratePicks() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [selectedEmail, setSelectedEmail] = useState(null)
  let [copyEmail, setCopyEmail] = useState(null)
  let [emails, setEmails] = useState([])
  let [picks, setPicks] = useState([])
  let [copyEnabled, setCopyEnabled] = useState(false)
  let [note, setNote] = useState('')
  let [saved, setSaved] = useState(false)

  useEffect(() => {
    getEmails()
  }, [])

  const getEmails = () => {
    axios.get('/pre_picks/emails', { withCredentials: true }).then((response) => {
      setEmails(response.data)
    })
  }

  const getNote = () => {
    axios.get(`/pre_picks/note/${selectedEmail}`, { withCredentials: true }).then((response) => {
      setNote(response.data.note)
    })
  }

  useEffect(() => {
    if (selectedEmail) {
      getNote()
      getPicks()
    } else {
      setNote('')
      setPicks([])
    }
  }, [selectedEmail])

  const getPicks = () => {
    axios.get(`/pre_picks/${encodeURIComponent(selectedEmail)}`, { withCredentials: true }).then((response) => {
      console.log(response.data.products)
      setPicks(response.data.products)
    })
  }

  const removePick = (product) => {
    axios.delete(`/pre_picks/${selectedEmail}/${product.id}`, { withCredentials: true }).then(() => {
      setPicks(picks.filter((p) => p.id !== product.id))
    })
  }

  let urlRef = useRef(null)
  const addProduct = (product_url, e) => {
    let productId = product_url.split('/').pop()
    axios.post('/pre_picks', { product_id: productId, email: selectedEmail }, { withCredentials: true }).then(() => {
      urlRef.current.value = ''
      getPicks()
      getEmails()
    })
  }

  const handleChange = (e) => {
    setSelectedEmail(e.target.value)
  }

  const handleCopySubmit = (e) => {
    axios.post('/pre_picks/copy', { email: selectedEmail, copy_email: copyEmail }, { withCredentials: true }).then(() => {
      getEmails()
      setCopyEmail(null)
      setCopyEnabled(false)
    })
  }

  const handleNoteUpdate = (e) => {
    axios.post('/pre_picks/note', { email: selectedEmail, note: note }, { withCredentials: true }).then(() => {
      getEmails()
      setSaved(true)
    })
  }

  useEffect(() => {
    setSaved(false)
  }, [note])

  return (
    <div>
      <EmailSelector emails={emails} setSelectedEmail={setSelectedEmail} />
      <Header1 styles='mt-5 ml-10'>Selected Email</Header1>
      <input type='text' class='ml-10' onChange={handleChange} value={selectedEmail} />
      {selectedEmail && <div className='ml-10 mt-5'>
        <Header1>Note</Header1>
        <input type='text' className='w-80' value={note} onChange={(e) => setNote(e.target.value)}/>
        <button className='ml-5 border border-1 h-10 px-5' onClick={handleNoteUpdate}>Save Note</button>
        { saved ? <><span className='ml-5 text-green-500'>Saved</span></> : <></> }
      </div>}
      <PicksTable picks={picks} setPicks={setPicks} removePick={removePick} />
      <input className='w-80 ml-10 mt-5' type="text" id="product_url" ref={urlRef} />
      <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={(e) => { addProduct(document.getElementById('product_url').value, e)} }>Add Product</button>
      <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={() => { setCopyEnabled(!copyEnabled) }}>Copy</button>
      {copyEnabled &&
        <div>
          <input className='w-80 ml-10 mt-5' type="text" id="copy_email" value={copyEmail} onChange={(e) => { setCopyEmail(e.target.value) }} />
          <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={handleCopySubmit}>Copy</button>
        </div>
      }
    </div>
  )
}

