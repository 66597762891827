export default function emojiButtons(user) {

  let buttons =
    [
    {
      text: '🛍️ All Products', tag: 'All Products'
    }, {
      text: '🦄 Top Sellers', tag: 'Top Sellers'
    },
    {
      text: '😜 Novelty',
      tag: 'Novelty'
    },
    {
      text: '💪🏻 Consumables',
      tag: 'Consumables'
    },
    {text: '💵 Under $10', tag: 'Under $10'},
    {text: '🤳 1M+ Followers', tag: '1M+ Followers'},
    {text: '🚀 $5M+ Sales', tag: '$5M+ Sales'},
    {
      text: '🧸 Toys & Games',
      tag: 'Toys'
    },
    {
      text: '🌊 Wellness',
      tag: 'Wellness'
    },
    {
      text: '🧴 Skin & Beauty',
      tag: 'Beauty'
    },
    {
      text: '🏠 Home',
      tag: 'Home'
    },
    {
      text: '🍼 Baby',
      tag: 'Baby'
    },
    {
      text: '🐶 Pets',
      tag: 'Pet'
    },
    {
      text: '🔨 Tools',
      tag: 'Tools'
    },
    { text: '⚡️ Electronics', tag: 'Electronics' },
    {
      text: '🖊️ Stationery', tag: 'Stationery'
    },
    {
      text: '🏋️ Exercise',
      tag: 'Exercise'
    },
  ]

  let myFavorites =
    {
      text: '❤️ My Favorites', tag: "My Favorites"
    }

  user?.retailer && buttons.splice(1, 0, myFavorites)

  return buttons
}
