import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ref } from 'yup'
import Arrow from '../../../entrypoints/images/arrow-right.svg'
import HeadingText from "../molecules/HeadingText.tsx"
import TextInput from "../../molecules/inputs/TextInput.tsx"
import TextAreaInput from "../molecules/TextAreaInput.tsx"
import FollowersSelectInput from "../../molecules/inputs/FollowersSelectInput.tsx"
import ImageUpload from "../molecules/ImageUpload.tsx"
import instaIcon from '../../../entrypoints/images/insta-icon.svg'
import tikTokIcon from '../../../entrypoints/images/tiktok-icon.svg'
import axios from 'axios'
import Cookies from 'js-cookie'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import { CurrentUserContext } from "../../../contexts/CurrentUser.js"

import { followerCounts, makeOption } from "../../../utils/FormSelectOptions.jsx";
import CategoriesSelect from "../CategoriesSelect.jsx";
import RetailerCategoriesSelect from "../RetailerCategoriesSelect.jsx";
import UsRetailerMultiSelect from "../../molecules/inputs/UsRetailerMultiSelect.tsx";

const CreateBrandProfile = (props) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  const handleSubmit = (v) => {
    axios.put('/brand', v, { withCredentials: true }).then((res) => {
      props.submitted(true)
      let user = currentUser
      user.brand = res.data
      setCurrentUser(user)
    })
  }

  const [tiktok, setTiktok] = useState('')
  const [insta, setInsta] = useState('')

  useEffect(() => {
    axios.get('/profile').then((r) => {
      setTiktok(r.data.tiktok_handle)
      setInsta(r.data.insta_handle)
    })
  }, [])

  const categories = [
    'Stationery',
    'Novelty',
    'Accessories',
    'Home Goods',
    'Electronics',
    'Beauty',
    'Wellness',
    'Consumables',
    'Toys',
    'Tools',
  ]
  // list of 10 categories that encompass majority of categories of retailers
  const retailerCategories = [
    'Grocery',
    'Pharmacy',
    'Mass Merchandiser',
    'Specialty',
    'Department Store',
    'Home Improvement',
    'Apparel',
    'Pet',
    'Sporting Goods',
    'Office Supply',
  ]

  return (
    <section className='w-full px-3'>
      <HeadingText text="build profile" />
      <p className={'text-sm font-reg text-almost-black mb-6 text-center'}>Get discovered by massive national retailers</p>

      <Formik
        initialValues={{
          brandDescription: '',
          isTikTok: !!tiktok,
          tikTokFollowers: '',
          isInsta: !!insta,
          instaFollowers: '',
          brandLogo: '',
          brandBanner: '',
          categories: '',
        }}
        validationSchema={Yup.object({
          brandDescription: Yup.string()
            .min(100, 'Minimum 100 characters')
            .max(550, 'Maximum 550 characters')
            .required('Required'),
          isTikTok: Yup.boolean(),
          tikTokFollowers: Yup.string().when("isTikTok", {
            is: (value) => value === true,
            then: () =>
              Yup.string()
                .oneOf(followerCounts, "Required",)
                .required('Required'),
            otherwise: () =>
              Yup.string()
              .notRequired(),
          }),
          isInsta: Yup.boolean(),
          instaFollowers: Yup.string().when("isInsta", {
            is: (value) => value === true,
            then: () =>
              Yup.string()
                .oneOf(followerCounts, "Required",)
                .required('Required'),
            otherwise: () =>
              Yup.string()
              .notRequired(),
          }),
          brandLogo: Yup.string()
            .required('Required'),
          brandBanner: Yup.string()
            .notRequired(),
          categories: Yup.string()
            .notRequired(),
        })}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values)
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form>
          <CategoriesSelect categories={categories} values='{}'/>
          <RetailerCategoriesSelect categories={retailerCategories} values='{}'/>

          <h2 className='text-sm font-reg mb-2'>Are there any specific retailers you would like to work with? (choose all that apply)</h2>
          <UsRetailerMultiSelect />
          <div className='block mt-5'>
          </div>

          <TextAreaInput
            label="Brand Description*"
            name="brandDescription"
            placeholder="Take at least 100 characters to help retailers understand your brand's unique story."
            height='h-44'
          />

          { tiktok &&
          <div className='flex flex-col sm:flex-row justify-between w-full'>
            <div className='w-full sm:w-3/5'>
              <label className='text-sm font-reg block mt-2 mb-px'>Tik Tok Handle</label>
              <article className={'w-full h-10 border-r sm:border-r-0 border-l border-t border-b border-gray flex items-center bg-white'}>
                <img src={tikTokIcon} alt={'tik tok logo'} className={'h-4 mx-4'} />
                <p className={'text-sm font-reg'}>{tiktok}</p>
              </article>
            </div>
            <div className='w-full sm:w-2/5 flex items-end'>
              <FollowersSelectInput label="Follower Count*" name="tikTokFollowers" selectOptions={followerCounts} />
            </div>
          </div>
          }

          { insta &&
          <div className='my-4 flex flex-col sm:flex-row justify-between w-full'>
            <div className='w-full sm:w-3/5'>
              <label className='text-sm font-reg block mt-2 mb-px'>Instagram Handle</label>
              <article className={'w-full h-10 border-r sm:border-r-0  border-l border-t border-b border-gray flex items-center bg-white'}>
                <img src={instaIcon} alt={'instagram logo'} className={'h-4 mx-4'} />
                <p className={'text-sm font-reg'}>{insta}</p>
              </article>
            </div>
            <div className='w-full sm:w-2/5 flex items-end'>
              <FollowersSelectInput label="Follower Count*" name="instaFollowers" selectOptions={followerCounts} />
            </div>
          </div>
          }

          <ImageUpload
            label='Brand Logo*'
            name='brandLogo'
            recommendedSize={'200x200 pixels'}
          />

          <ImageUpload
            label='Brand Banner Image'
            name='brandBanner'
            recommendedSize={'1165x300 pixels'}
          />

          <div className={'mt-6 mb-24'}>
            <CtaButton id={'build-profile-submit-button'} submit={true}>Continue</CtaButton>
          </div>
          <div className='block mt-12'>
            &nbsp;
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default CreateBrandProfile
