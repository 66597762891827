import React from "react"

function Overline({ styles, children }: { styles?: string, children: string }) {

  return (
    <p className={`text-2xl font-bold uppercase text-pink ${styles}`}>
      { children }
    </p>
  )
}

export default Overline
