import React from "react"
import { Link, useNavigate,useSearchParams } from "react-router-dom"
import Logo from "../../../entrypoints/images/logo240.webp"
import UserSquare from '../../../entrypoints/images/user-square-pink.svg'
export default function DummyHeader() {
  const getHomeLinkPath = () => {
    return '/'
  }
  const openSignInModal = () => {
    console.log('open sign in modal')
  }
  return (
    <React.Fragment>
      <header className="fixed top-0 w-screen flex border-b-2 border-gray h-20 px-2 xl:px-8 bg-white z-50">
        <img
          src={Logo}
          alt={"made me buy it logo"}
          id={"header-logo"}
          className="w-52 my-1 cursor-pointer"
          onClick={() => { window.location.href = '/' }}
        />

        <div
          className={
            "h-full w-2/3 xl:w-3/5 flex flex-col justify-center justify-center px-3 relative"
          }
        >
        </div>

        <div className={'h-full w-1/6 xl:w-1/5 flex items-center justify-end'}>
          {/*(
            <CTA type={'button'} id={'header-sign-in-button'} buttonType={'button'} action={() => {openSignInModal()}} width={'w-24 lg:w-32'}>
              <img src={UserSquare} alt={"user-icon"} className={"h-6 w-6 mr-2 hidden lg:inline"} />
              <p className="w-fit">Sign In</p>
            </CTA>
            )*/}
        </div>
      </header>
    </React.Fragment>
  )
}
