import React, {useState, useEffect, useRef} from 'react'
import UserSelector from './molecules/UserSelector'
import PicksTable from './molecules/PicksTable'
import Header1 from './atoms/text/Header1'
import axios from 'axios'

export default function CuratePicks() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [selectedUser, setSelectedUser] = useState(null)
  let [copyUser, setCopyUser] = useState(null)
  let [users, setUsers] = useState([])
  let [picks, setPicks] = useState([])
  let [copyEnabled, setCopyEnabled] = useState(false)
  let [note, setNote] = useState('')
  let [saved, setSaved] = useState(false)

  useEffect(() => {
    getBuyers()
  }, [])

  const getBuyers = () => {
    axios.get('/admin/buyers', { withCredentials: true }).then((response) => {
      setUsers(response.data)
    })
  }

  useEffect(() => {
    if (selectedUser) {
      setNote(selectedUser.note || '')
      getBuyers()
      getPicks()
    }
  }, [selectedUser])

  const getPicks = () => {
    axios.get(`/admin/buyers/${selectedUser.id}/picks`, { withCredentials: true }).then((response) => {
      setPicks(response.data.products)
    })
  }

  const removePick = (product) => {
    axios.delete(`/curated_picks/${selectedUser.id}/${product.id}`, { withCredentials: true }).then(() => {
      setPicks(picks.filter((p) => p.id !== product.id))
    })
  }

  let urlRef = useRef(null)
  const addProduct = (product_url, e) => {
    let productId = product_url.split('/').pop()
    axios.post('/curated_picks', { product_id: productId, user_id: selectedUser.id }, { withCredentials: true }).then(() => {
      urlRef.current.value = ''
      getPicks()
    })
  }

  const copyPicks = () => {
    axios.post('/curated_picks/copy', { user_id: selectedUser.id, copy_user_id: copyUser.id }, { withCredentials: true }).then(() => {
      setCopyEnabled(false)
      setCopyUser(null)
      getPicks()
    })
  }

  const handleNoteUpdate = (e) => {
    axios.post(`/admin/buyers/${selectedUser.id}/add_note`, { note: note }, { withCredentials: true }).then(() => {
      setSaved(true)
    })
  }

  useEffect(() => {
    setSaved(false)
  }, [note])

  return (
    <div>
      <UserSelector users={users} setSelectedUser={setSelectedUser} />
      {selectedUser && <div className='ml-10 mt-5'>
        <Header1>Note</Header1>
        <input type='text' className='w-80' value={note} onChange={(e) => setNote(e.target.value)}/>
        <button className='ml-5 border border-1 h-10 px-5' onClick={handleNoteUpdate}>Save Note</button>
        { saved ? <><span className='ml-5 text-green-500'>Saved</span></> : <></> }
      </div>}
      <PicksTable picks={picks} setPicks={setPicks} removePick={removePick} />
      <input className='w-80 ml-10 mt-5' type="text" id="product_url" ref={urlRef}/>
      <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={(e) => { addProduct(document.getElementById('product_url').value, e)} }>Add Product</button>
      <button className='ml-5 mt-5 border border-1 h-10 px-5' onClick={() => { setCopyEnabled(!copyEnabled) }}>Copy</button>
      { copyEnabled &&
      <div>
        <UserSelector users={users} setSelectedUser={setCopyUser} />
        <button className='ml-10 mt-5 border border-1 h-10 px-5' onClick={() => { copyPicks() }}>Copy Picks</button>
      </div>
      }
    </div>
  )
}

