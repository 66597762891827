import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons"
import Paragraph1 from "../components/atoms/text/Paragraph1"

function AccordionItem({ question, answer }) {
  const [active, setActive] = useState(false)
  const properCaret = () => {
    return active ? faAngleUp : faAngleDown
  }

  return (
    <div className={'w-full mb-2 pb-2 border-b border-blue'}>
        <button className={'w-full flex justify-between'} onClick={() => setActive(!active)}>
          <Paragraph1 styles={active ? 'text-almost-black' : 'text-medium-gray'}>{question}</Paragraph1>
            <FontAwesomeIcon className="h-4 w-4 py-2" icon={properCaret()} />
        </button>
        {active && answer && (
          <Paragraph1 styles={'px-8'}>{answer}</Paragraph1>
        )}
    </div>
  )
}

export default AccordionItem
