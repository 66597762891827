import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import Header1 from './atoms/text/Header1.tsx'
import Logo from "../entrypoints/images/logo240.webp"
import DummyHeader from './organisms/headers/DummyHeader.jsx'
import axios from 'axios'

export default function SamplesSignUp() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    sample_brand_categories: [],
    sample_retailer_categories: [],
    selected_retailers: []
  })

  const [currentUser, setCurrentUser] = useState(null)
  const [formPage, setFormPage] = useState(1)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedRetailerCategories, setSelectedRetailerCategories] = useState([])
  const [selected, setSelected] = useState([])

  const handleSubmit = (e) => {
    if (formPage === 1) {
      setFormPage(2)
    } else {
      formData.sample_brand_categories = selectedCategories
      formData.sample_retailer_categories = selectedRetailerCategories
      formData.selected_retailers = selected.map((retailer) => retailer.value)
      axios.post('/samples/sign_up', formData).then((r) => {
        if (r.data.success) {
          window.location.href = r.data.link
        } else {
          setFormPage(1)
          if (r.data.errors.length) {
            if (r.data.errors.includes('Email has already been taken')) {
              alert('Email is in use. If you have an existing account, login and navigate to the "Samples" tab to subscribe.')
              return false
            }
            if (r.data.errors.find((e) => e.includes('assword'))) {
              alert(r.data.errors.join('\n'))
              return false
            }
          } else {
            alert('There was an error with your submission. Please try again.')
            return false
          }
        }
      })
    }
    e.preventDefault()
  }

  const updateProfile = (e) => {
    formData.sample_brand_categories = selectedCategories
    formData.sample_retailer_categories = selectedRetailerCategories
    formData.selected_retailers = selected.map((retailer) => retailer.value)
    axios.post('/samples/sign_up', formData).then((r) => {
      if (r.data.success) {
        alert('Profile updated successfully!')
        window.location.reload()
      } else {
        alert('There was an error with your submission. Please try again.')
      }
    })
    e.preventDefault()
  }

  const getCurrentUser = () => {
    axios.get('/me').then((r) => {
      setCurrentUser(r.data)
    })
  }

  const handleCategoriesSelect = (e) => {
    const selectedCategory = e.target.value
    let newSelectedCategories
    if (selectedCategories.includes(selectedCategory)) {
      newSelectedCategories = selectedCategories.filter(
        (category) => category !== selectedCategory
      )
    } else {
      newSelectedCategories = [...selectedCategories, selectedCategory]
    }
    setSelectedCategories(newSelectedCategories)
  }

  const handleRetailerCategoriesSelect = (e) => {
    const selectedRetailerCategory = e.target.value
    let newSelectedRetailerCategories
    if (selectedRetailerCategories.includes(selectedRetailerCategory)) {
      newSelectedRetailerCategories = selectedRetailerCategories.filter(
        (category) => category !== selectedRetailerCategory
      )
    } else {
      newSelectedRetailerCategories = [...selectedRetailerCategories, selectedRetailerCategory]
    }
    setSelectedRetailerCategories(newSelectedRetailerCategories)
  }

  const categories = [
    'Stationery',
    'Novelty',
    'Accessories',
    'Home Goods',
    'Electronics',
    'Beauty',
    'Wellness',
    'Consumables',
    'Toys',
    'Tools',
  ]

  const retailerCategories = [
    'Grocery',
    'Pharmacy',
    'Mass Merchandiser',
    'Specialty',
    'Department Store',
    'Home Improvement',
    'Apparel',
    'Pet',
    'Sporting Goods',
    'Office Supply',
  ]

  const [usRetailers, setUsRetailers] = useState([])
  const [usRetailersLoading, setUsRetailersLoading] = useState(false)

  const getUSRetailerList = () => {
    axios.get('/profiles/us_retailers').then((r) => {
      let list = r.data.all_retailers.map((retailer) => {
        return { label: retailer, value: retailer }
      })
      setUsRetailers(list)
    })
  }

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions)
  }

  const navToStripe = () => {
    window.location.href = 'https://billing.stripe.com/p/login/3cs6ql0aXg9Z6ze6oo'
  }

  useEffect(() => {
    getUSRetailerList()
    getCurrentUser()
  }, [])

  useEffect(() => {
    usRetailers.length ? setUsRetailersLoading(false) : setUsRetailersLoading(true)
  }, [usRetailers])

  useEffect(() => {
    if (currentUser && !currentUser.sample_subscribed) {
      setFormPage(2)
    }
  }, [currentUser])

  useEffect(() => {
    if (formPage === 1) {
      document.getElementById('sample-sign-up-form-page-2').style.display = 'none'
      document.getElementById('sample-sign-up-form-page-1').style.display = 'block'
    } else {
      document.getElementById('sample-sign-up-form-page-1').style.display = 'none'
      document.getElementById('sample-sign-up-form-page-2').style.display = 'block'
    }
  }, [formPage])


  if (currentUser?.sample_subscribed) {
    return (
      <div className="sm:block fixed h-screen w-screen top-0 right-0 overflow-y-scroll bg-[url('/entrypoints/images/background-colors.png')]" id='fixed-home'>
        <DummyHeader/>
        <main
          className={
            'mt-20 min-h-[calc(100%-176px)] flex flex-col items-center pb-6'
          }
        >
          <section className="w-full max-w-[1080px]">
            <div className='grid grid-cols-12'>
              <div className='col-span-12 text-center'>
                <Header1 styles='text-[2.5rem] max-w-[75%] mx-auto mt-5'>You're all set to receive daily leads emails for <u>National Buyers!</u></Header1>
                <p className='font-reg text-lg mt-5'>You will receive an email with a different buyer's shipping, contact, and background information every day.</p>
                <p className='font-reg text-lg mt-5'>At any time, you can modify your leads matching profile below:</p>
              </div>
              <div className='col-span-12 py-10 px-40'>
                <h2 className="text-sm font-reg mb-2 text-center">Current Profile:</h2>
                <div className='col-span-12 w-full grid grid-cols-12'>
                  <div className='col-span-3'></div>
                  <div className='col-span-6 border-2 border-pink rounded-xl p-5 shadow-xl'>
                    <p className='font-reg text-lg'><u>Product Categories:</u> <br/><span className='ml-4 mt-2 block'>{currentUser.sample_brand_categories.join(', ')}</span></p>
                    <p className='font-reg text-lg mt-4'><u>Retailer Categories:</u> <br/><span className='ml-4 mt-2 block'>{currentUser.sample_retailer_categories.join(', ')}</span></p>
                    <p className='font-reg text-lg mt-4'><u>Preferred Retailers:</u> <br/><span className='ml-4 mt-2 block'>{currentUser.selected_retailers.join(', ')}</span></p>
                  </div>
                  <div className='col-span-3'></div>
                </div>
              </div>
              <div className='col-span-12 mt-5 pb-10 px-40'>
                <div id='sample-sign-up-form-page-2'>
                  <h2 className="text-sm font-reg mb-2">Which of the following categories do your products belong to?</h2>
                  <div className="flex flex-wrap ml-1 mb-5">
                    {categories.map((category) => (
                      <div key={category} className="w-1/2">
                        <label className="block text-sm font-reg">
                          <input
                            type="checkbox"
                            value={category}
                            checked={selectedCategories.includes(category)}
                            onChange={handleCategoriesSelect}
                            className="mr-2"
                          />
                          {category}
                        </label>
                      </div>
                    ))}
                    <h2 className="text-sm font-reg mb-2 mt-5">What types of retailers do you want to work with?</h2>
                    <div className="flex flex-wrap ml-1 mb-5">
                      {retailerCategories.map((category) => (
                        <div key={category} className="w-1/2">
                          <label className="block text-sm font-reg">
                            <input
                              type="checkbox"
                              value={category}
                              checked={selectedRetailerCategories.includes(category)}
                              onChange={handleRetailerCategoriesSelect}
                              className="mr-2"
                            />
                            {category}
                          </label>
                        </div>
                      ))}
                    </div>
                    <h2 className='text-sm font-reg mb-2'>Are there any specific retailers you would like to work with? (choose all that apply)</h2>
                    <div className='col-span-12 w-full'>
                      <Select
                        name={'us-retailers-select'}
                        value={selected}
                        onChange={handleChange}
                        isMulti
                        isLoading={usRetailersLoading}
                        options={usRetailers}
                        classNamePrefix="react-select"
                        placeholder={'Begin typing to search...'}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            "min-height": '40px',
                            "min-width" : '100%',
                            border: '1px solid #EAEAEA',
                            'border-radius': '0px',
                            'font-family': 'Poppins-Regular',
                            'box-shadow': 'none',
                            "control:hover": {
                              border: '1px solid #EAEAEA',
                            },
                            "control:focus": {
                              border: '1px solid #EAEAEA',
                            }
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            'font-family': 'Poppins-Regular',
                            color: '#050000',
                            'background-color': state.isFocused ? 'rgba(19, 241, 255, 0.1)' : '#FFFFFF',
                          }),
                          placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            margin: 'none',
                          }),
                          multiValue: (baseStyles, state) => ({
                            ...baseStyles,
                            margin: 'none',
                            padding: 'none',
                          }),
                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            "input:focus": {
                              'box-shadow': 'none',
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                  <button className='w-full p-2 border-2 border-pink rounded-xl bg-pink text-white font-bold mt-5' onClick={updateProfile}>Update Profile</button>
                  <div className='grid grid-cols-12 my-5'>
                    <div className='col-span-5'><hr className='border-1 border-pink mt-3' /></div>
                    <div className='col-span-2 text-center'><p className='font-reg text-lg text-pink'>or</p></div>
                    <div className='col-span-5'><hr className='border-1 border-pink mt-3' /></div>
                  </div>
                  <button className='w-full p-2 border-2 border-pink rounded-xl bg-pink text-white font-bold' onClick={navToStripe}>Manage Subscription</button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  } else {
    return (
      <div className="sm:block fixed h-screen w-screen top-0 right-0 overflow-y-scroll bg-[url('/entrypoints/images/background-colors.png')]" id='fixed-home'>
        <DummyHeader/>
        <main
          className={
            'mt-20 min-h-[calc(100%-176px)] flex flex-col items-center pb-6'
          }
        >
          <section className="w-full max-w-[1080px]">
            <div className='grid grid-cols-12'>
              <div className='col-span-12 text-center'>
                <Header1 styles='text-[3rem] max-w-[75%] mx-auto mt-5'>Get your products in front of <u>National Buyers!</u></Header1>
              </div>
              <div className='col-span-5 p-10'>
                <div className='col-span-12 border-2 border-pink rounded-xl p-5 shadow-xl'>
                  <Header1 styles='text-2xl text-almost-black text-center mb-5'>
                    <div className='shadow-md rounded-full border-b-2 border-pink px-3 py-1'>1</div>
                  </Header1>
                  <p className='font-reg text-lg text-center'>
                    Answer a few simple questions regarding your products and retailers you want to send samples to.
                  </p>
                </div>
                <div className='col-span-12 border-2 border-pink rounded-xl p-5 mt-10 shadow-xl'>
                  <Header1 styles='text-2xl text-almost-black text-center mb-5'>
                    <div className='shadow-md rounded-full border-b-2 border-pink px-3 py-1'>2</div>
                  </Header1>
                  <p className='font-reg text-lg text-center'>
                    We send you a daily email with a different buyer's shipping, contact, and background information.
                  </p>
                </div>
                <div className='col-span-12 border-2 border-pink rounded-xl p-5 mt-10 shadow-xl'>
                  <Header1 styles='text-2xl text-almost-black text-center mb-5'>
                    <div className='shadow-md rounded-full border-b-2 border-pink px-3 py-1'>3</div>
                  </Header1>
                  <p className='font-reg text-lg text-center'>
                    You send a handwritten card along with your samples to buyers.
                  </p>
                </div>
                <div className='col-span-12'>
                  <hr className='border-2 border-pink mt-10' />
                </div>
                <div className='col-span-12 border-2 border-pink rounded-xl p-5 mt-10 shadow-xl bg-transparent-blue'>
                  <Header1 styles='text-2xl text-almost-black text-center mb-5'>
                    <div className='shadow-md rounded-full border-b-2 border-pink px-3 py-1'>Sign Up Now!</div>
                  </Header1>
                  <p className='font-reg text-lg'>
                    <form id='sample-sign-up-form' className='col-span-12' onSubmit={handleSubmit} onChange={(e) => setFormData({...formData, [e.target.name]: e.target.value})}>
                      <div id='sample-sign-up-form-page-1'>
                        <label htmlFor='name' className='text-pink font-bold'>Name</label>
                        <input type='text' name='name' className='w-11/12 p-2 border-2 border-pink rounded-xl mb-5' />
                        <label htmlFor='email' className='text-pink font-bold'>Email</label>
                        <input type='email' name='email' className='w-11/12 p-2 border-2 border-pink rounded-xl mb-5' />
                        <label htmlFor='password' className='text-pink font-bold'>Password</label>
                        <input type='password' name='password' placeholder='Password' className='w-11/12 p-2 border-2 border-pink rounded-xl mb-5' />
                        <label htmlFor='password_confirmation' className='text-pink font-bold'>Confirm Password</label>
                        <input type='password' name='password_confirmation' className='w-11/12 p-2 border-2 border-pink rounded-xl mb-5' />
                        <button className='w-11/12 p-2 border-2 border-pink rounded-xl bg-pink text-white font-bold'>Next</button>
                      </div>
                      <div id='sample-sign-up-form-page-2'>
                        <h2 className="text-sm font-reg mb-2">Which of the following categories do your products belong to?</h2>
                        <div className="flex flex-wrap ml-1 mb-5">
                          {categories.map((category) => (
                            <div key={category} className="w-1/2">
                              <label className="block text-sm font-reg">
                                <input
                                  type="checkbox"
                                  value={category}
                                  checked={selectedCategories.includes(category)}
                                  onChange={handleCategoriesSelect}
                                  className="mr-2"
                                />
                                {category}
                              </label>
                            </div>
                          ))}
                          <h2 className="text-sm font-reg mb-2 mt-5">What types of retailers do you want to work with?</h2>
                          <div className="flex flex-wrap ml-1 mb-5">
                            {retailerCategories.map((category) => (
                              <div key={category} className="w-1/2">
                                <label className="block text-sm font-reg">
                                  <input
                                    type="checkbox"
                                    value={category}
                                    checked={selectedRetailerCategories.includes(category)}
                                    onChange={handleRetailerCategoriesSelect}
                                    className="mr-2"
                                  />
                                  {category}
                                </label>
                              </div>
                            ))}
                          </div>
                          <h2 className='text-sm font-reg mb-2'>Are there any specific retailers you would like to work with? (choose all that apply)</h2>
                          <div className='col-span-12 w-full'>
                            <Select
                              name={'us-retailers-select'}
                              value={selected}
                              onChange={handleChange}
                              isMulti
                              isLoading={usRetailersLoading}
                              options={usRetailers}
                              classNamePrefix="react-select"
                              placeholder={'Begin typing to search...'}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  "min-height": '40px',
                                  "min-width" : '100%',
                                  border: '1px solid #EAEAEA',
                                  'border-radius': '0px',
                                  'font-family': 'Poppins-Regular',
                                  'box-shadow': 'none',
                                  "control:hover": {
                                    border: '1px solid #EAEAEA',
                                  },
                                  "control:focus": {
                                    border: '1px solid #EAEAEA',
                                  }
                                }),
                                menu: (baseStyles, state) => ({
                                  ...baseStyles,
                                }),
                                option: (baseStyles, state) => ({
                                  ...baseStyles,
                                  'font-family': 'Poppins-Regular',
                                  color: '#050000',
                                  'background-color': state.isFocused ? 'rgba(19, 241, 255, 0.1)' : '#FFFFFF',
                                }),
                                placeholder: (baseStyles, state) => ({
                                  ...baseStyles,
                                  margin: 'none',
                                }),
                                multiValue: (baseStyles, state) => ({
                                  ...baseStyles,
                                  margin: 'none',
                                  padding: 'none',
                                }),
                                input: (baseStyles, state) => ({
                                  ...baseStyles,
                                  "input:focus": {
                                    'box-shadow': 'none',
                                  }
                                })
                              }}
                            />
                          </div>
                        </div>
                        <p className='text-center mt-5 text-sm'>Upon signing up, you will be redirected to a Stripe payment page to complete your subscription.</p>
                        <button className='w-11/12 p-2 border-2 border-pink rounded-xl bg-pink text-white font-bold mt-5'>Sign Up</button>
                      </div>
                    </form>
                  </p>
                </div>
              </div>
              <div className='col-span-7 mt-10'>
                <div className="w-11/12 p-5 border rounded-xl bg-white text-sm font-reg">
                  <h2 className="text-xl font-bold mb-5">Example Lead</h2>
                  <img src="/woman.jpg" alt="profile photo" className='w-full mx-auto rounded-xl' />
                  <p className='block mt-5'>
                    <h2 className="text-pink text-3xl font-bold mb-2">Jane Doe - Target Buyer</h2>
                    <p>Meet <b>Jane Doe</b>, an accomplished buyer at Target with a robust 15-year background in retail management and product sourcing. She holds an MBA from Columbia University, where she specialized in Business Strategy and Operations. Throughout her career, Jane has fine-tuned her expertise in market research, contract negotiation, inventory management, and supplier relationship management.</p><br />
                    <p>Jane's passion lies in driving innovation and sustainability within the retail sector. Regularly contributing to industry publications, she shares insights on the transformative impact of eco-friendly practices in retail operations. Her dedication to sustainability is not just professional; Jane is also an advocate for sustainable living in her personal life.</p><br />
                    <p>Jane's professional network at Target spans a diverse range of roles, from Junior Buyers to Senior Executive Managers. She is known for her collaborative approach, often engaging with cross-functional teams to ensure seamless execution of retail strategies.</p><br />
                    <p>What sets her apart? Jane is approachable, genuine, and deeply committed to helping others achieve their professional goals. Her leadership style is characterized by authenticity, empathy, and a genuine interest in the development of her team members.</p><br />
                    <p className='font-bold text-pink'><b>Why send Jane a product sample?</b></p>
                    <p>Given her extensive and influential network within Target, sending Jane a thoughtful gift could pave the way for your products to gain significant visibility. Imagine receiving a personalized, handwritten note from someone who not only recognizes the value of your brand but is also genuinely excited about your products!</p><br />
                    <p>Take this unique opportunity to send her a product sample that resonates with her interests and values, particularly if your products align with sustainability and innovation. This gesture could be the catalyst for new business opportunities and invaluable connections in the retail world!</p>
                  </p>
                  <p>
                    <h3 className='mt-5 text-xl font-bold text-pink'>Details:</h3>
                    <ul className='text-lg ml-5'>
                      <li><b className='font-bold'>Name:</b> Jane Doe</li>
                      <li><b className='font-bold'>Company:</b> Target</li>
                      <li><b className='font-bold'>Position:</b> Buyer</li>
                      <li><b className='font-bold'>Experience:</b> 15 years</li>
                      <li><b className='font-bold'>Education:</b> MBA</li>
                      <li><b className='font-bold'>Interests:</b> Leadership development, trend analysis, sales</li>
                    </ul>
                  </p>
                  <p>
                    <h3 className='mt-5 text-xl font-bold text-pink'>Contact Information:</h3>
                    <ul className='text-lg ml-5'>
                      <li><b className='font-bold'>Email: </b>Jane.Doe@Target.com</li>
                      <li><b className='font-bold'>Phone: </b>(555)-555-5555</li>
                      <li><b className='font-bold'>Office Address: </b>1313 Mockingbird Lane, Beverly Hills, California, 90210</li>
                      <li><b className='font-bold'>LinkedIn: </b><a href='#'>https://www.linkedin.com/in/example-lead</a></li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }
}
