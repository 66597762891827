import React from "react"
import { Outlet, useLocation } from 'react-router-dom'
import LogoHeader from "../components/organisms/headers/LogoHeader.tsx"
import BrandInquiryForm from "../components/onboarding/templates/BrandInquiryForm"
import RetailerInquiryForm from "../components/onboarding/templates/RetailerInquiryForm"
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

const Inquiry = () => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  }, [location])

  const mdScreen = window.matchMedia("(min-width: 768px)")

  return (
    <section className={'h-screen flex flex-col'}>
      <LogoHeader />
      <main className='flex h-[calc(100%-56px)]'>
        {
          mdScreen.matches &&
          <aside className={'w-2/5 flex justify-center px-3 bg-transparent-blue'}>
            <div className={'flex xl:hidden h-full justify-center items-start'}>
              <video
                autoPlay
                muted
                loop
                className="rounded-3xl my-8"
              >
                <source
                  src="https://vid.cdn-website.com/9ee7672b/videos/h6B1CKSlTyq1k7iDWpBP_export_1672769931354-v.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <video
              autoPlay
              muted
              loop
              className="hidden xl:block rounded-3xl my-8"
            >
              <source
                src="https://vid.cdn-website.com/9ee7672b/videos/h6B1CKSlTyq1k7iDWpBP_export_1672769931354-v.mp4"
                type="video/mp4"
              />
            </video>
          </aside>
        }
        <section className={'bg-[url("/entrypoints/images/background-colors.svg")] bg-contain bg-center bg-no-repeat w-full md:w-3/5 flex overflow-y-scroll overflow-x-hidden justify-center'}>
           <Outlet />
        </section>
      </main>
    </section>
  )
}

export default Inquiry
