import React, { useRef, useState, useEffect } from 'react'
import SortDropDown from "~/components/molecules/inputs/SortDropDown";
import RangeSlider from "~/components/RangeSlider";
import FilterButton from './atoms/buttons/FilterButton.tsx'
import FiltersModal from './organisms/modals/FiltersModal.tsx'
import { useLocation } from 'react-router-dom'

const SortAndFilterBar = ({ filters, setFilters, sort, handleSortChange, numProducts }) => {
  let location = useLocation()

  let [filteredProductCount, setFilteredProductCount] = useState(null)

  let msrpRef = useRef(null)
  let followersRef = useRef(null)
  let salesRef = useRef(null)
  let leadtimeRef = useRef(null)
  let retailersRef = useRef(null)

  let filterValues = {
    msrp: ['$0', '$5', '$10', '$15', '$20', '$30', 'max'],
    followers: ["<100K", "100K+", "500K+", "1M+"],
    sales: ["<$1M", "$1-5M", "$5M+"],
    leadtime: ['1w', '2w', '4w', '6w', '8w', '10w', '12w'],
    retailers: ['Walmart', 'Target', 'Amazon.com'],
  }

  let [msrpModified, setMsrpModified] = useState(false)
  let [followersModified, setFollowersModified] = useState(false)
  let [salesModified, setSalesModified] = useState(false)
  let [leadtimeModified, setLeadtimeModified] = useState(false)
  let [retailersModified, setRetailersModified] = useState(false)

  let [msrpOpen, setMsrpOpen] = useState(false)
  let [followersOpen, setFollowersOpen] = useState(false)
  let [salesOpen, setSalesOpen] = useState(false)
  let [leadtimeOpen, setLeadtimeOpen] = useState(false)
  let [retailersOpen, setRetailersOpen] = useState(false)
  let [filtersModalOpen, setFiltersModalOpen] = useState(false)

  // let handleMsrpOpen = () => {
  //   msrpRef.current.hidden = !msrpRef.current.hidden
  //   setMsrpOpen(!msrpOpen)
  // }
  // let handleFollowersOpen = () => {
  //   followersRef.current.hidden = !followersRef.current.hidden
  //   setFollowersOpen(!followersOpen)
  // }
  // let handleSalesOpen = () => {
  //   salesRef.current.hidden = !salesRef.current.hidden
  //   setSalesOpen(!salesOpen)
  // }
  // let handleLeadtimeOpen = () => {
  //   leadtimeRef.current.hidden = !leadtimeRef.current.hidden
  //   setLeadtimeOpen(!leadtimeOpen)
  // }
  // let handleRetailersOpen = () => {
  //   retailersRef.current.hidden = !retailersRef.current.hidden
  //   setRetailersOpen(!retailersOpen)
  // }

  const setMsrpFilters = (min, max) => {
    if (min !== filters.msrp.min || max !== filters.msrp.max) {
      setFilters({...filters, msrp: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setMsrpModified(false)
    } else {
      setMsrpModified(true)
    }
  }

  const setFollowersFilters = (min, max) => {
    if (min !== filters.followers.min || max !== filters.followers.max) {
      setFilters({...filters, followers: { min: min, max: max}})
    }
    if (min === 0 && max === 3) {
      setFollowersModified(false)
    } else {
      setFollowersModified(true)
    }
  }
  const setSalesFilters = (min, max) => {
    if (min !== filters.sales.min || max !== filters.sales.max) {
      setFilters({...filters, sales: { min: min, max: max}})
    }
    if (min === 0 && max === 2) {
      setSalesModified(false)
    } else {
      setSalesModified(true)
    }
  }

  const setLeadtimeFilters = (min, max) => {
    if (min !== filters.leadtime.min || max !== filters.leadtime.max) {
      setFilters({...filters, leadtime: { min: min, max: max}})
    }
    if (min === 0 && max === 6) {
      setLeadtimeModified(false)
    } else {
      setLeadtimeModified(true)
    }
  }

  const setRetailersFilters = (retailerList) => {
    setFilters({...filters, retailers: retailerList})
    if (retailerList.length === 0) {
      setRetailersModified(false)
    } else {
      setRetailersModified(true)
    }
  }

  const resetFilters = () => {
    let initFilters = {
      msrp: {
        min: 0,
        max: 6,
      },
      followers: {
        min: 0,
        max: 3,
      },
      sales: {
        min: 0,
        max: 2,
      },
      leadtime: {
        min: 0,
        max: 6,
      },
      retailers: [],
    }
    setFilters(initFilters)
    handleSortChange('newest')
    setMsrpModified(false)
    setFollowersModified(false)
    setSalesModified(false)
    setLeadtimeModified(false)
    setRetailersModified(false)
  }

  // let [filtersApplied, setFiltersApplied] = useState(false)
  let [appliedFilterCount, setAppliedFilterCount] = useState(0)

  useEffect(() => {
    // (msrpModified || followersModified || salesModified || leadtimeModified || retailersModified) ? setFiltersApplied(true) : setFiltersApplied(false)

    let fCount = 0;
    [msrpModified, followersModified, salesModified, leadtimeModified, retailersModified].forEach((f) => {
      f && fCount++
    })
    setAppliedFilterCount(fCount)
  }, [filters])

  useEffect(() => {
    if (!msrpModified && !followersModified && !salesModified && !leadtimeModified && !retailersModified) {
      setFilteredProductCount('Show All Products')
    } else if (!numProducts) {
      setFilteredProductCount('No matching products')
    } else {
      setFilteredProductCount(`Show ${numProducts} Products`)
    }
  }, [numProducts])

  useEffect(() => {
    if (location.search === '?filter=My%20Favorites') {
      resetFilters()
    }
  }, [location])

  return (
    <section className={'h-full w-full flex justify-end items-center gap-2 xl:gap-4 flex-wrap'}>
      <FilterButton
        openFiltersModal={() => setFiltersModalOpen(true)}
        clearFilters={resetFilters}
        numFiltersSet={appliedFilterCount}
      />
      <SortDropDown
        sortValue={sort}
        setSort={handleSortChange}
      />
      <FiltersModal
        isOpen={filtersModalOpen}
        filters={filters}
        setIsOpen={setFiltersModalOpen}
        setMsrp={setMsrpFilters}
        setFollowers={setFollowersFilters}
        setSales={setSalesFilters}
        setLeadtime={setLeadtimeFilters}
        setRetailers={setRetailersFilters}
        sortValue={sort}
        setSort={handleSortChange}
        numProducts={filteredProductCount}
        reset={resetFilters}
      />
    </section>
  )
}

export default SortAndFilterBar
