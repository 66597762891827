import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Paragraph1 from "~/components/atoms/text/Paragraph1.tsx";

export default function DashboardStats() {
  let location = useLocation()

  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [stats, setStats] = useState({
    product_count: '',
    purchase_order_count: '',
    action_items_count: '',
    retailer_count: '',
    store_count: ''
  })

  const getStats = () => {
    axios.get('/stats').then((r) => {
      setStats(r.data)
    })
  }

  // useEffect(() => {
  //   getStats()
  // },[])

  useEffect(() => {
    getStats()
  },[location.pathname])

  return(
    <React.Fragment>
      <section className='w-full h-24 grid grid-cols-5 bg-white'>

        <article className='grid place-items-center border border-gray py-5'>
          <Paragraph1>{stats.product_count}</Paragraph1>
          <Paragraph1>All Products Listed</Paragraph1>
        </article>
        <article className='grid place-items-center border border-gray py-5'>
          {/*<Paragraph1>{stats.purchase_order_count}</Paragraph1>*/}
          <Paragraph1>0</Paragraph1>
          <Paragraph1>Number of PO's</Paragraph1>
        </article>
        <article className='grid place-items-center border border-gray py-5'>
          <Paragraph1>0</Paragraph1>
          <Paragraph1>Number of Action Items</Paragraph1>
        </article>
        <article className='grid place-items-center border border-gray py-5'>
          {/*<Paragraph1>{stats.retailer_count}</Paragraph1>*/}
          <Paragraph1>0</Paragraph1>
          <Paragraph1>Number of Retailers</Paragraph1>
        </article>
        <article className='grid place-items-center border border-gray py-5'>
          {/*<Paragraph1>{stats.store_count}</Paragraph1>*/}
          <Paragraph1>0</Paragraph1>
          <Paragraph1>Number of Stores</Paragraph1>
        </article>

      </section>
    </React.Fragment>
  )
}
