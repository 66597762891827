import React, { useState, useEffect } from 'react'
import Creatable from 'react-select/creatable'
import axios from 'axios'

const BuyerCategoryMultiSelect = ({ selected, setSelected, custom, setCustom }: { selected?: string[], setSelected?: Function, custom?: string[], setCustom?: Function }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [buyerCategories, setBuyerCategories] = useState([])
  let [buyerCategoriesLoading, setBuyerCategoriesLoading] = useState(false)

  const getBuyerCategories = () => {
    axios.get('/buyer_categories').then((r) => {
      let list = r.data.map((c) => {
        return { label: c.name, value: c.id }
      })
      setBuyerCategories(list)
    })
  }

  const handleChange = (selectedOptions, e) => {
    if (e.action === 'remove-value' || e.action === 'pop-value') {
      setCustom(custom.filter((c) => c !== e.removedValue))
    }
    setSelected(selectedOptions.filter((o) => buyerCategories.map((b) => b.label).includes(o.label)))
  }

  useEffect(() => {
    getBuyerCategories()
  }, [])

  useEffect(() => {
    buyerCategories.length ? setBuyerCategoriesLoading(false) : setBuyerCategoriesLoading(true)
  }, [buyerCategories])

  return (
    <Creatable
      onCreateOption={(v) => setCustom([...custom, { label: v, value: v }])}
      name={'buyer-categories-select'}
      value={selected.concat(custom)}
      onChange={handleChange}
      isMulti
      isLoading={buyerCategoriesLoading}
      options={buyerCategories}
      classNamePrefix="react-select"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          "min-height": '40px',
          border: '1px solid #EAEAEA',
          'border-radius': '0px',
          'font-family': 'Poppins-Regular',
          'box-shadow': 'none',
          "control:hover": {
            border: '1px solid #EAEAEA',
          },
          "control:focus": {
            border: '1px solid #EAEAEA',
          }
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          'font-family': 'Poppins-Regular',
          color: '#050000',
          'background-color': state.isFocused ? 'rgba(19, 241, 255, 0.1)' : '#FFFFFF',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          margin: 'none',
        }),
        multiValue: (baseStyles, state) => ({
          ...baseStyles,
          margin: 'none',
          padding: 'none',
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          "input:focus": {
            'box-shadow': 'none',
          }
        })
      }}
    />
  )
}

export default BuyerCategoryMultiSelect
