import React from 'react'
import Header1 from '../atoms/text/Header1'

export default function UserSelector({users, setSelectedUser}) {
  return (
    <>
    <Header1 styles='ml-10 mt-10'>Select User</Header1>
    <div className='ml-10'>
      <select onChange={(e) => setSelectedUser(users.find((user) => user.id === e.target.value))}>
        <option value="">Select a user</option>
        {users.map((user) => <option key={user.id} value={user.id}>{user.email}</option>)}
      </select>
    </div>
    </>
  )
}
