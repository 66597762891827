import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { string, object } from 'yup'
import Header1 from "../../../../components/atoms/text/Header1.tsx"
import ProfileSection from '../molecules/ProfileSection.tsx'
import ImageUpload from '../../../../components/onboarding/molecules/ImageUpload.tsx'
import { brandCategories, salesNumbers, followerCounts, makeOption } from "../../../../utils/FormSelectOptions.jsx"
import TextInput from "../../../molecules/inputs/TextInput"
import SelectInput from "../../../../components/onboarding/molecules/SelectInput"
import TextAreaInput from "../../../../components/onboarding/molecules/TextAreaInput"
import instaIcon from '../../../../entrypoints/images/insta-icon.svg'
import tikTokIcon from '../../../../entrypoints/images/tiktok-icon.svg'
import Header2 from "~/components/atoms/text/Header2"
import { mockTeamMembers } from '../../../../utils/mockData.js'
import axios from 'axios'
import CtaButton from '../../../atoms/buttons/CtaButton.tsx'
import UsRetailerMultiSelect from "~/components/molecules/inputs/UsRetailerMultiSelect"
import SocialsInput from '../../../molecules/inputs/SocialsInput.jsx'

const DashProfile = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let [brand, setBrand] = useState({})
  let [selectedRetailers, setSelectedRetailers] = useState([])

  useEffect(() => {
    axios.get(`/marketplace/brands/current_brand`).then((response) => {
      setBrand(response.data)
    })

    axios.get('/profile', { withCredentials: true}).then((res) => {
      let prev = res.data.current_retailer_relationships.map((v) => {
        return  { label: v, value: v }
      })
      setSelectedRetailers(prev)
    })
  }, [])

  const handleSubmit = (v, c) => {
    axios.put('/marketplace/brands/update', { brand: v }).then((response) => {
      alert('Profile updated successfully')
    })
    axios.put('/profile', { profile: { current_retailer_relationships: c }}, { withCredentials: true }).catch((err) => {
      console.log("err", err)
    })
  }

  let tableStyles = {
    columnOne: 'w-6/12 text-left pl-3',
    columnTwo: 'w-3/12 text-right',
    columnThree: 'w-3/12 text-right pr-3',
  }

  const getTeamMemberRows = () => {
    let teamMemberRows = [].map(tm => {
      return (
        <tr className={"h-12 font-med text-sm text-medium-gray border-b border-gray"}>
          <td className={tableStyles.columnOne}>{tm.name}</td>
          <td className={tableStyles.columnTwo}>{tm.status}</td>
          <td className={tableStyles.columnThree}>{tm.accepted_date}</td>
        </tr>
      )
    })
    return teamMemberRows
  }

  return (
    <section className={"w-2/3 flex flex-col"}>
      <Header1 styles={"mb-4"}>Brand Profile</Header1>
      <Formik
        initialValues={{
          logo: brand.logo,
          banner: brand.banner,
          name: brand.name,
          tiktok: {
            handle: brand.tiktok_handle || "",
            count: brand.tiktok_count || "N/A",
          },
          insta: {
            handle: "@" + brand.insta_handle || "",
            count: brand.instagram_count || "N/A",
          },
          annual_sales: brand.annual_sales || "",
          description: brand.description || "",
        }}
        enableReinitialize={true}
        validationSchema={object({
          logo: string().required("Required"),
          banner: string().required("Required"),
          name: string().required("Required"),
          tiktok: object({
            handle: string()
              .matches(
                /^\s$|^@([\w.]{1,23}\w)$/,
                "Invalid TikTok handle, please start with @",
              )
              .notRequired(),
            count: string().when("handle", {
              is: (value) => value?.length > 2,
              then: () =>
                string().oneOf(
                  followerCounts,
                  "Please provide a valid TikTok follower count",
                ),
              otherwise: () =>
                string().oneOf(
                  ["N/A"],
                  'If you do not have a TikTok account, follower count should be "N/A"',
                ),
            }),
          }),
          insta: object({
            handle: string()
              .matches(
                /^\s$|^@([\w.]{1,30})$/,
                "Invalid Instagram handle, please start with @",
              )
              .notRequired(),
            count: string().when("handle", {
              is: (value) => value?.length > 1,
              then: () =>
                string().oneOf(
                  followerCounts,
                  "Please provide a valid Instagram follower count",
                ),
              otherwise: () =>
                string().oneOf(
                  ["N/A"],
                  'If you do not have an Instagram account, follower count should be "N/A"',
                ),
            }),
          }),
          annual_sales: string()
            .oneOf(salesNumbers, "Invalid annual sales")
            .required("Required"),
          description: string()
            .max(550, "Maximum 550 characters")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            let newValues = {
              logo: values.logo,
              banner:  values.banner,
              name: values.name,
              tiktok_handle: values.tiktok.handle,
              tiktok_count: values.tiktok.count === "N/A" ? "" : values.tiktok.count,
              insta_handle: values.insta.handle,
              instagram_count: values.insta.count === "N/A" ? "" : values.insta.count,
              annual_sales: values.annual_sales,
              description: values.description,
            }

            let crr = selectedRetailers.map((r) => {
              return r.value
            })
            handleSubmit(newValues, crr)
            setSubmitting(false)
          }, 400)
        }}
      >
        <Form>
          <ProfileSection headerText={"Uploads"}>
            <div className={"flex justify-between mb-1.5"}>
              <div className={"w-80"}>
                <ImageUpload
                  label="Brand Logo"
                  name="logo"
                  recommendedSize={"200x200 pixels"}
                  initialPreview={brand.logo}
                />
              </div>
              <div className={"w-80"}>
                <ImageUpload
                  label="Brand Banner Image"
                  name="banner"
                  recommendedSize={"1165x300 pixels"}
                  initialPreview={brand.banner}
                />
              </div>
            </div>
          </ProfileSection>
          <ProfileSection headerText={"Brand Details"}>
            <TextInput
              label="Brand Name"
              name="name"
              type="text"
              placeholder=""
            />

            {/*Add tiktok and insta icons*/}
            <div className={"w-full flex justify-between mt-2"}>
              <div className={"w-1/2 mr-1"}>
                <SocialsInput label={"TikTok"} name={"tiktok"} />
              </div>
              <div className={'w-1/2 ml-1'}>
                <SocialsInput
                  label={'Instagram'}
                  name={'insta'}
                />
              </div>
            </div>
            <div className={"mt-2"}>
              <SelectInput
                label="Annual Sales"
                name="annual_sales"
                selectOptions={salesNumbers}
              />
            </div>
            <div className={"mt-2"}>
              <TextAreaInput
                label="Brand Description*"
                name="description"
                placeholder=""
                resize="resize-y"
              />
            </div>
          </ProfileSection>
          <ProfileSection headerText={"My Current Retailer Relationships"}>
            <UsRetailerMultiSelect
              selected={selectedRetailers}
              setSelected={setSelectedRetailers}
            />
          </ProfileSection>
          <article className={"w-full bg-transparent-white border border-gray"}>
            <header
              className={
                "h-12 w-full flex justify-between items-center border-b border-gray"
              }
            >
              <Header2
                styles={"h-12 flex items-center border-b border-gray pl-3"}
              >
                Manage Team Members
              </Header2>
              {/*<Link to={'/dashboard/brand_profile'} className={'font-reg text-sm text-link-blue pr-3'}>Invite Members</Link>*/}
            </header>
            <section className={"w-full"}>
              <table className={"w-full"}>
                <tr
                  className={
                    "h-12 w-11/12 bg-white font-med text-sm text-almost-black border-b border-gray"
                  }
                >
                  <th className={tableStyles.columnOne}>Member Name</th>
                  <th className={tableStyles.columnTwo}>Status</th>
                  <th className={tableStyles.columnThree}>Accepted Date</th>
                </tr>
                {getTeamMemberRows()}
              </table>
            </section>
          </article>
          <div
            className={
              "bg-transparent-white border-x border-b border-gray p-5 flex justify-center"
            }
          >
            <div className={'w-80'}>
              <CtaButton id={"brand-profile-edit-save-button"} submit={true}>Save Brand Settings</CtaButton>
            </div>
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default DashProfile
