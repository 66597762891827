import React, { useContext, useEffect, useRef } from 'react'
import Banner from '../organisms/Banner.tsx'
import Deck from '../organisms/Deck.tsx'
import axios from 'axios'
import { CurrentUserContext } from "../../contexts/CurrentUser.js"
import RetailerCards from "../../components/RetailerCards.tsx"
import { ProductsContext } from "../../contexts/Products.js"
import { useLocation } from "react-router-dom"

const Marketplace = () => {
  let location = useLocation()
  let {
    products, setProducts,
    setBrands, brands,
    favorites, setFavorites,
  } = useContext(ProductsContext)
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser } = useContext(CurrentUserContext)

  const getBrands = () => {
    axios.get('/brands').then((resp) => {
      setBrands(resp.data)
    })
  }

  useEffect(() => {
    if (currentUser.retailer) {
      getFavorites()
    }
  }, [currentUser])

  const getFavorites = () => {
    if (currentUser?.email) {
      axios.get('/favorites').then((resp) => {
        let favs = resp.data.products
        favs.map((fav) => { fav.favorited = true })
        setFavorites(favs)
      })
    }
  }

  const mergeFavorites = () => {
    let favoriteTrending = [...trendingProducts]
    favoriteTrending.map((prod) => {
      prod.favorited = favorites.map((fav) => fav.id).includes(prod.id)
    })
    setTrendingProducts(favoriteTrending)

    let favoriteNew = [...newProducts]
    favoriteNew.map((prod) => {
      prod.favorited = favorites.map((fav) => fav.id).includes(prod.id)
    })
    setNewProducts(favoriteNew)

    let favoriteTikTok = [...tikTokProducts]
    favoriteTikTok.map((prod) => {
      prod.favorited = favorites.map((fav) => fav.id).includes(prod.id)
    })
    setTikTokProducts(favoriteTikTok)
  }

  useEffect(() => {
    if (favorites?.length) {
      mergeFavorites()
    }
  }, [favorites])

  useEffect(() => {
    if (location.pathname === "/marketplace" || location.pathname === "/") {
      getBrands()
      getTrendingProducts()
      getNewProducts()
      getTikTokProducts()
      getFavorites()
    }
  }, [location])

  let [trendingProducts, setTrendingProducts] = React.useState([])
  let [newProducts, setNewProducts] = React.useState([])
  let [tikTokProducts, setTikTokProducts] = React.useState([])

  const getTrendingProducts = () => {
    axios.post(
      '/marketplace/products/homepage_products',
      {
        search: {
          tags: [
            "Trending"
          ]
        },
      }
    ).then((resp) => {
      let prods = resp.data.products
      setTrendingProducts(prods)
    })
  }

  const getNewProducts = () => {
    axios.post(
      '/marketplace/products/homepage_products',
      {
        search: {
          tags: [
            "New"
          ]
        },
      }
    ).then((resp) => {
      let prods = resp.data.products
      setNewProducts(prods)
    })
  }

  const getTikTokProducts = () => {
    axios.post(
      '/marketplace/products/homepage_products',
      {
        search: {
          tags: [
            "TikTok"
          ]
        },
      }
    ).then((resp) => {
      let prods = resp.data.products
      setTikTokProducts(prods)
    })
  }

  return (
    <React.Fragment>
      <Banner />
      <section className={'h-full w-full max-w-[1024px] m-4 relative'}>
        { brands?.length && <Deck headerText={'Viral Brands on TikTok'} brands={brands} linkText={'Show all'} linkPath={'/all_brands'}/> }
        <Deck headerText={'Our Retailers'} cards={RetailerCards()}/>
        <Deck headerText={'Trending Products'} linkText={'Show all'} linkPath={'/all_products?filter=Current Trending'} products={trendingProducts}/>
        <Deck headerText={'New Products'} linkText={'Show all'} linkPath={'/all_products?filter=New Products'} products={newProducts}/>
        <Deck headerText={'As Seen on TikTok'} linkText={'Show all'} linkPath={'/all_products?filter=As Seen On TikTok'} products={tikTokProducts}/>
        {
          currentUser?.email && currentUser?.retailer && !!(favorites.length) && <Deck headerText={'Your Favorited'} linkText={'Show all'} linkPath={'/all_products?filter=My Favorites'} products={favorites}/>
        }
      </section>
    </React.Fragment>
  )
}

export default Marketplace
