import React from "react"
import AccordionItem from "./AccordionItem.tsx"
import Header1 from "~/components/atoms/text/Header1";
import Paragraph1 from "~/components/atoms/text/Paragraph1";
import Header2 from "~/components/atoms/text/Header2";

function FaqAccordion() {
  let accordionData = [
    {
      question: "What is MadeMeBuyIt?",
      answer:
        "We are a marketplace connecting big box retailers with ecommerce brands from TikTok, Instagram and Shopify.",
    },
    {
      question: "How much does it cost as a brand?",
      answer: "It's free to join. We only make money when you make money. We charge brands 5%-10% of the total PO value.",
    },
    {
      question: "How does my brand get into big box retail?",
      answer:
        "Simply sign up and apply as a brand.  Once accepted, you can easily upload your products, pricing and images- and begin selling to massive big box retailers immediately.",
    },
    {
      question: "How many retailers are using MadeMeBuyIt?",
      answer:
        "We are currently in hundreds of retailers across the country. Just one order from one of these big box retailers could change your business.",
    },
    {
      question: "Why do I want to get into big box retail?",
      answer:
        "Retailers provide you with massive scale, huge orders for your products, requires $0 from you for ad spend AND can grow your brand both in store and online.",
    },
    {
      question: "Can I decide which retailers can carry my products?",
      answer:
        "Yes, it is 100% your choice as to which retailer can purchase from you and sell your products.",
    },
    {
      question: "How long does it take to sign up?",
      answer:
        "It takes 30 seconds to sign up and join our waitlist. We will then review your information and get back to you ASAP.",
    },
    {
      question: "What kind of brands should sign up for MadeMeBuyIt?",
      answer:
        "Any brand that is ecommerce first, Shopify based, DTC, viral on TikTok or Instagram, etc.. If your audience is Gen Z, that is great too.",
    },
    {
      question: "Can I list all of my items?",
      answer: "Yes, you choose whichever products you want to list.",
    },
    {
      question: "Do you provide financing?",
      answer:
        "Yes, we partnered with the top ecommerce financing company in the country.",
    },
    {
      question: "Can I invite other brands to MadeMeBuyIt?",
      answer:
        "Yes. We will offer you premium placement on our marketplace in exchange for brand sign ups.",
    },
    {
      question: "Is there a waitlist?",
      answer:
        "Yes, we will review your application and get back to you in 24 hours with a response.",
    },
    {
      question: "Why was I rejected?",
      answer:
        "We curate the brands in our marketplace to ensure they have strong sales, products, branding and are ready for retail.",
    },
  ]
  return (
    <article className={'mt-4 p-4 border border-gray text-justify bg-transparent-white'}>
      <Header2 styles={'mb-4'}>FAQ</Header2>
      {accordionData.map(({ question, answer }) => (
        <AccordionItem question={question} answer={answer} key={question} />
      ))}
    </article>
  )
}

export default FaqAccordion
