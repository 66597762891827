import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Overline from '../../../components/atoms/text/Overline.tsx'
import Header1 from '../../../components/atoms/text/Header1.tsx'
import Paragraph1 from '../../../components/atoms/text/Paragraph1.tsx'

import Arrow from '../../../entrypoints/images/arrow-right.svg'
import HeadingText from "../molecules/HeadingText.tsx"
import TextInput from "../../molecules/inputs/TextInput.tsx"
import TextAreaInput from "../molecules/TextAreaInput.tsx"
import Checkbox from "../molecules/Checkbox.tsx"
import SelectInput from "../molecules/SelectInput.tsx"
import { brandCategories, salesNumbers, makeOption } from '../../../utils/FormSelectOptions.jsx'
import CtaButton from '../../atoms/buttons/CtaButton.tsx'
import UsRetailerMultiSelect from "~/components/molecules/inputs/UsRetailerMultiSelect";

const makeOption = (item) => {
  return <option value={item}>{item}</option>
}

const BrandInquiryForm = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  let navigate = useNavigate()
  let [selectedRetailers, setSelectedRetailers] = useState([])

  const createBrandProfile = (values, setSubmitting) => {
    axios.post("/profiles", values).then(
      (r) => {
        setSubmitting(false)
        navigate('/brand_inquiry/submitted')
      }
    )
  }

  return (
    <section className='w-full md:w-[464px] mx-4'>
      <header className={'mt-4 flex flex-col items-center'}>
        <Overline>Brands</Overline>
        <Header1 styles={'text-center'}>Grow Your Wholesale Business</Header1>
        <Paragraph1>Let's start with a few details</Paragraph1>
      </header>
      <Formik
        initialValues={{
          brand_name: '',
          email: '',
          website: 'https://',
          brand_category: 'Toys',
          annual_sales: '',
          current_retailer_relationships: '',
//          agreed_to_terms_on: '',
        }}
        validationSchema={Yup.object({
          brand_name: Yup.string()
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          website: Yup.string()
            .url('Invalid url')
            .required('Required'),
          brand_category: Yup.string()
            .oneOf(brandCategories, 'Invalid category')
            .notRequired(),
          annual_sales: Yup.string()
            .oneOf(salesNumbers, 'Invalid annual sales')
            .required('Required'),
          tiktok_handle: Yup.string()
            .matches(/^@([\w.]{1,23}\w)$/, 'Invalid TikTok handle, please start with @')
            .notRequired(),
          insta_handle: Yup.string()
            .matches(/^@([\w.]{1,30})$/, 'Invalid Instagram handle, please start with @')
            .notRequired(),
          current_retailer_relationships: Yup.string()
            .notRequired(),
//         agreed_to_terms_on: Yup.boolean()
//           .required('Required')
//           .oneOf([true], 'You must accept the terms and conditions.')
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            values.current_retailer_relationships = selectedRetailers.map((r) => r.value)
            createBrandProfile(values, setSubmitting)
          }, 400);
        }}
      >
        <Form>
          <TextInput
            label="Brand Name*"
            name="brand_name"
            type="text"
            placeholder=""
          />

          <TextInput
            label="Email Address*"
            name="email"
            type="email"
            placeholder=""
          />

          <TextInput
            label="Website*"
            name="website"
            type="text"
            placeholder=""
          />

          <SelectInput label="Brand Category*" name="brand_category" selectOptions={brandCategories} id={'brand-inquiry-category-button'} />

          <SelectInput label="Annual Sales*" name="annual_sales" selectOptions={salesNumbers} id={'brand-inquiry-sales-button'} />

          <TextInput
            label="TikTok Handle"
            name="tiktok_handle"
            type="text"
            placeholder="@"
          />

          <TextInput
            label="Instagram Handle"
            name="insta_handle"
            type="text"
            placeholder="@"
          />

          <label for={'us-retailers-select'} className={'text-sm font-reg my-2'}>Current Retailer Relationships</label>

          <UsRetailerMultiSelect selected={selectedRetailers} setSelected={setSelectedRetailers} />

          {/*<Checkbox name="agreed_to_terms_on">
            I agree to the MadeMeBuyIt terms and conditions.
            </Checkbox>*/}

          <div className={'mt-6 mb-12'}>
            <CtaButton id={'brand-inquiry-submit-button'} submit={true} >Submit</CtaButton>
          </div>

          <div className='block mt-12'>
            &nbsp;
          </div>
        </Form>
      </Formik>
    </section>
  )
}

export default BrandInquiryForm
