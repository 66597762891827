import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Header2 from "../../../components/atoms/text/Header2.tsx"
import Arrow from "../../../entrypoints/images/arrow-right-dark.svg"
import Paragraph1 from "../../../components/atoms/text/Paragraph1.tsx"
import Map from '../../../entrypoints/images/us-map.svg'

export default function DashboardStoresWidget() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [storesData, setStoresData] = useState({
    store_count: '',
    state_count: '',
  })

  const getStoresData = () => {
    axios.get('/stores_data').then((r) => {
      setStoresData(r.data)
    })
  }

  useEffect(() => {
    getStoresData()
  },[])

  return(
    <article className={'w-full h-80 bg-transparent-white border border-gray ml-2'}>
      <header className={'h-12 w-full flex justify-between items-center border-b border-gray px-3'}>
        <Header2>My Stores</Header2>
        <img src={Arrow} alt={'right arrow'}/>
      </header>
      <section className={'p-3'}>
        <img src={Map} alt={'united states map'} className={'h-44 w-full mb-2'}/>
        <div className={'h-14 w-full flex'}>
          <div className={'w-1/2 grid place-items-center border-r border-gray'}>
            <Paragraph1>Total Stores</Paragraph1>
            <Paragraph1>{storesData.store_count}</Paragraph1>
          </div>
          <div className={'w-1/2 grid place-items-center'}>
            <Paragraph1>States</Paragraph1>
            <Paragraph1>{storesData.state_count}</Paragraph1>
          </div>
        </div>
      </section>
    </article>
  )
}
