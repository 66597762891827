import React, { useEffect, useState, useRef } from 'react'
import { useField } from 'formik'
import DownArrow from '../../../entrypoints/images/chevron-right.svg'
import UpArrow from '../../../entrypoints/images/Path.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons"
import { followerCounts } from '../../../utils/FormSelectOptions.jsx'
import Paragraph1 from "~/components/atoms/text/Paragraph1"

const SelectInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const [countDropdownOpen, setCountDropdownOpen] = useState(false)
  let [err, setErr] = useState(null)
  let [socialHandleValue, setSocialHandleValue] = useState(meta.initialValue.handle)
  let [initialHandleValue, setInitialHandleValue] = useState(meta.initialValue.handle)
  let [socialCountValue, setSocialCountValue] = useState(meta.initialValue.count)
  let [initialCountValue, setInitialSocialCountValue] = useState(meta.initialValue.count)

  let socialRef = useRef(null)

  const options = ['N/A'].concat(followerCounts).map((o) => {
    return (
      <button
        type={'button'}
        value={o}
        className={`h-10 w-full border border-gray font-reg flex items-center px-3 hover:border-2 hover:border-blue hover:text-almost-black hover:bg-transparent-blue text-medium-gray`}
        onClick={() => { setSocialCountValue(o); setCountDropdownOpen(false) }}
      >
        { o }
      </button>
    )
  })

  const handleDropdownClick = () => {
    setCountDropdownOpen(!countDropdownOpen)
  }

  useEffect(() => {
    if (meta.touched && (meta.error?.handle || meta.error?.count)) {
      setErr(meta.error?.handle || meta.error?.count)
    } else {
      setErr(null)
    }

    if (meta.initialValue.handle !== initialHandleValue) {
      setSocialHandleValue(meta.initialValue.handle)
      setInitialHandleValue(meta.initialValue.handle)
    }
    if (meta.initialValue.count !== initialCountValue) {
      setSocialCountValue(meta.initialValue.count)
      setInitialSocialCountValue(meta.initialValue.count)
    }
  }, [meta])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (!e.target.parentNode.matches(`.social-dropdown-${props.name}`)) {
        setCountDropdownOpen(false)
      }
    })
  }, [socialRef])

  useEffect(() => {
    setValue({...meta.value, handle: socialHandleValue})
  }, [socialHandleValue])

  useEffect(() => {
    setValue({...meta.value, count: socialCountValue})
  }, [socialCountValue])

  return (
    <div className={'w-full'}>
      <div className={'w-full flex'}>
        <label htmlFor={props.name?.handle} className={`w-3/5 text-sm font-reg`}>{`${label} Handle`}</label>
        <label htmlFor={props.name?.count} className={`w-2/5 text-sm font-reg ${err && 'text-red-600 font-bold flex items-center'}`}>{`Follower Count`}</label>
      </div>
      <div className={'w-full flex'}>
        <input id={props.name?.handle} className={'w-3/5 text-sm font-reg h-10 border border-gray'} placeholder={'@'} value={socialHandleValue} onChange={(e) => {setSocialHandleValue(e.target.value)}} />

        <div className={`relative w-2/5`}>
          <button type={'button'} onClick={handleDropdownClick} className={`w-full h-10 flex justify-between items-center border ${err ? 'border-red-600' : 'border-gray'} bg-white text-sm font-reg social-dropdown-${props.name}`} id={props.id || props.name}>
            <div className={'h-full w-full px-3 flex justify-start items-center'}>{ socialCountValue }</div>
            <img src={countDropdownOpen ? UpArrow : DownArrow} alt={countDropdownOpen ? 'close options' : 'open options'} className={'h-full w-7 pr-3'} />
          </button>
          {
            countDropdownOpen &&
            <div className={`w-full overflow-y-scroll border border-gray bg-white flex flex-col absolute top-10 z-40 h-40 social-dropdown-${props.name}`} ref={socialRef}>
              { options }
            </div>
          }
        </div>
      </div>
      <div>
        { err &&
          <div className={'flex justify-start items-center'}>
            <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mr-2`} />
            <p className={'text-sm font-reg text-red-600'}>{ meta.error?.handle || meta.error?.count }</p>
          </div>
        }
      </div>
    </div>
  )
}
export default SelectInput
