import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const DecimalInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { precision, unit } = props

  let [err, setErr] = useState(meta.touched && meta.error)

  useEffect(() => {
    setErr(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  useEffect(() => {
    let val = field.value.toString()
    let decimals = val.split(".")[1]
    if (decimals && decimals.length > precision) {
      helpers.setValue(field.value.toFixed(precision))
    }
  }, [field])

  return (
    <React.Fragment>
        <label htmlFor={props.id || props.name} className={`text-sm font-reg my-2 ${err && 'text-red-600 font-bold flex items-center'}`}>
          {label}
          {err &&
            <div className={'flex items-center'}>
              <FontAwesomeIcon icon={faTriangleExclamation} className={`h-3 w-3 text-red-600 mx-2`} />
              <p className="text-sm font-reg text-red-600">{meta.error}</p>
            </div>
          }
        </label>
        <div className={`flex relative text-sm bg-white font-reg w-full h-10 border ${err ? 'border-red-600' : 'border-gray'} w-full items-center`}>
          <p className='absolute left-2'>{unit}</p>
          <input {...field} {...props} className={`border-y ${err ? 'border-red-600' : 'border-gray'} border-x-0 w-full pl-5`} type={'number'} onWheel={(e) => { e.target.blur() }}/>
        </div>
    </React.Fragment>
  )
}

export default DecimalInput
