import React, { useEffect, useState } from 'react'
import axios from 'axios'
import FormatMoney from '../../../utils/FormatMoney'
import Header2 from '../../atoms/text/Header2.tsx'
import Header3 from '../../atoms/text/Header3.tsx'

export default function DashboardSalesWidget() {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [salesData, setSalesData] = useState({
    total_cents: 0
  })

  const getSalesData = () => {
    axios.get('/sales_data').then((r) => {
      setSalesData(r.data)
    })
  }

  useEffect(() => {
    getSalesData()
  },[])

  return(
    <article className={'w-full h-80 bg-transparent-white border border-gray mr-2'}>
      <Header2 styles={'h-12 w-full flex items-center border-b border-gray pl-3'}>Total PO Sales Volume</Header2>
      <section className={'p-3'}>
        <select name="volume-timeframe" id="volume-timeframe" className={'w-36 bg-inherit border-none text-sm font-reg text-medium-gray p-0'}>
          <option value="Total Sales 2023">Total Sales 2023</option>
        </select>
        <Header2 styles={'mb-3'}>{`$${FormatMoney(salesData.total_cents)}`}</Header2>
        <Header3 styles={'mb-1'}>Sales over time</Header3>
        <div className={'h-40 w-full text-red-600'}></div>
      </section>
    </article>
  )
}
