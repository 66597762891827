import React, { useState } from 'react'


const ProgressBar = ( { passwordComplete, profileComplete }) => {
  return (
    <section className={'flex flex-col items-center'}>
      <div className={'mt-7 h-10 flex'}>
        <div className={'mx-2 w-8 h-8 bg-dark-gray rounded-full border border-dark-gray grid place-content-center'}>
          <p className={'text-white text-sm font-med'}>1</p>
        </div>

        <div className={'w-12 sm:w-20 h-8 grid place-content-center'}>
          <div className={'w-12 sm:w-20 h-0 border-t border-gray'}></div>
        </div>

        <div className={`mx-2 w-8 h-8 rounded-full border border-dark-gray grid place-content-center ${passwordComplete ? 'bg-dark-gray' : 'bg-none'}`}>
          <p className={`text-sm font-med ${passwordComplete ? 'text-white' : 'text-dark-gray'}`}>2</p>
        </div>

        <div className={'w-12 sm:w-20 h-8 grid place-content-center'}>
          <div className={'w-12 sm:w-20 h-0 border-t border-gray'}></div>
        </div>

        <div className={`mx-2 w-8 h-8 rounded-full border border-dark-gray grid place-content-center ${profileComplete ? 'bg-dark-gray' : 'bg-none'}`}>
          <p className={`text-sm font-med ${profileComplete ? 'text-white' : 'text-dark-gray'}`}>3</p>
        </div>
      </div>

      <div className={'w-72 sm:w-96 col-span-5 grid grid-cols-3'}>
        <p className={'text=[#05000] text-sm font-reg flex justify-center'}>Password</p>
        <p className={`text-sm font-reg flex justify-center ${passwordComplete ? 'text=[#050000]' : 'text-[#999999]'}`}>Profile</p>
        <p className={`text-sm font-reg flex justify-center ${profileComplete ? 'text=[#050000]' : 'text-[#999999]'}`}>Products</p>
      </div>
    </section>
  )
}

export default ProgressBar
