import React from 'react'
import Header2 from "../../../../components/atoms/text/Header2"

const profileSection = ({ headerText, children }: { headerText: string, children: object }) => {
  return (
    <article className={"w-full bg-transparent-white border border-gray"}>
      <header
        className={
          "h-12 w-full flex justify-between items-center border-b border-gray"
        }
      >
        <Header2 styles={'h-12 w-full flex items-center border-b border-gray pl-3'}>{headerText}</Header2>
      </header>
      <section className={"w-full p-3"}>
        {children}
      </section>
    </article>
  )
}

export default profileSection
