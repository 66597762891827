import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import axios from 'axios'
import NothingUploaded from "../../../entrypoints/images/nothing-uploaded.svg"
import Header3 from '../../atoms/text/Header3.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const ImageUpload = ({ label, width, ...props }) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const [loading, setLoading] = useState(false)
  const [imagePreview, setImagePreview] = useState(NothingUploaded)
  const [fileName, setFileName] = useState("...")
  const [actionText, setActionText] = useState("Choose image")
  const fileInput = React.useRef()

  const handleClick = () => {
    fileInput.current.click()
  }

  const handleFileInput = (e) => {
    setLoading(true)
    setFileName("...")
    setActionText("Uploading")
    uploadFile(e.target.files[0])
  }

  const uploadFile = async (f) => {
    axios.post("/presigned_urls").then((r) => {
      let headers = r.data.aws_headers
      headers["Content-Type"] = f.type
      let fileKey = r.data.file_key
      axios.put(r.data.aws_url, f, { headers: headers }).then((res) => {
        setValue(fileKey)
        axios
          .post("/presigned_urls", {
            operation: "get_object",
            key: fileKey,
          })
          .then((res) => {
            setImagePreview(res.data.aws_url)
            setLoading(false)
            setFileName(f.name)
            setActionText("Change image")
          })
      })
    })
  }

  useEffect(() => {
    if (props.initialPreview) {
      setActionText("Change image")
      setLoading(true)
      axios.post("/presigned_urls", {
        operation: 'get_object',
        key: props.initialPreview
      }).then((res) => {
        setImagePreview(res.data.aws_url)
        setLoading(false)
        setFileName("...")
      })
    } else {
      setLoading(false)
    }
  }, [props.initialPreview])

  return (
    <article className={`${width || ''} flex flex-col overflow-hidden`}>
      <label
        htmlFor={props.id || props.name}
        className="text-sm font-reg block my-2"
      >
        {label}
      </label>
      <div
          className={`w-full border border-gray bg-white ${
            props.height || "h-40"
          } flex flex-col items-center justify-between cursor-pointer`}
          onClick={handleClick}
        >
        {
          loading ? <FontAwesomeIcon icon={faSpinner} spinPulse className={`my-4 m-0 h-14 -w-14 text-pink`} /> :
            <img src={imagePreview} alt={'image preview'} className={`my-4 m-0 max-h-14 max-w-16`} />
        }
        <p className={"font-reg text-xs text-[#999]"}>{ fileName }</p>

        <Header3>{actionText}</Header3>

        <p className={"font-reg text-xxs text-[#999]"}>Recommended Size:</p>
        <p className={"font-reg text-xxs text-[#999]"}>{props.recommendedSize}</p>

        <input
          className={`hidden file:w-full file:bg-inherit file:border-none file:font-reg file:text-sm `}
          type="file"
          accept="image/*"
          ref={fileInput}
          id={props.name}
          onChange={handleFileInput}
        />
      </div>
      {meta.touched && meta.error ? (
        <p className="text-xs font-reg text-red-600">{meta.error}</p>
      ) : null}
    </article>
  )
}

export default ImageUpload
