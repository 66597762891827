import React from 'react'
import { Link } from 'react-router-dom'
import Header1 from '../../../atoms/text/Header1.tsx'
import Header2 from '../../../atoms/text/Header2.tsx'
import ConnectShopifyWidget from '../../../molecules/ConnectShopifyWidget.tsx'
import UploadCsvWidget from '../../../molecules/UploadCsvWidget.tsx'

const UploadProducts = () => {

  return (
    <section className={"w-2/3 flex flex-col"}>
      <header className={'flex flex-col'}>
        <Header1 styles={'mb-2'}>Add Products</Header1>
        <Header2>Choose how you would like to add products</Header2>
      </header>
      <section className={'w-full grid place-items-center'}>
        <UploadCsvWidget position={'w-1/2 my-4'} />
        <Link
          to="/dashboard/manage_products/new"
          className={"flex justify-center border border-gray p-3 bg-white"}
        >
          <p className="text-sm font-reg text-dark-gray">+ Add Products Manually</p>
        </Link>
      </section>
    </section>
  )
}

export default UploadProducts
