import React, { useState } from 'react'
import ProgressBar from '../molecules/ProgressBar.tsx'
import CreatePassword from "~/components/onboarding/organisms/CreatePassword.tsx";
import CreateBrandProfile from '../organisms/CreateBrandProfile.tsx'
import AddProductsForm from "~/components/onboarding/organisms/AddProductsForm.tsx";

const BrandProfileForm = () => {
  let [passwordSubmitted, setPasswordSubmitted] = useState(false)
  let [profileSubmitted, setProfileSubmitted] = useState(false)

  return (
    <section className={'w-full md:w-[464px] mx-4'}>
      <ProgressBar passwordComplete={passwordSubmitted} profileComplete={profileSubmitted}/>
      {!!!passwordSubmitted && <CreatePassword submitted={setPasswordSubmitted}/>}
      {passwordSubmitted && !!!profileSubmitted && <CreateBrandProfile submitted={setProfileSubmitted}/>}
      {profileSubmitted && <AddProductsForm />}
    </section>
  )
}

export default BrandProfileForm
