import React from 'react'
import Header1 from '../../../atoms/text/Header1.tsx'
import SalesWidget from '../SalesWidget.jsx'
import StoresWidget from '../StoresWidget.jsx'
import OrdersWidget from '../OrdersWidget.jsx'

const DashOverview = () => {
  return(
    <section className={"w-2/3 flex flex-col"}>
      <Header1 styles={'mb-4'}>Overview</Header1>
      <div className={'w-full flex justify-between'}>
        <SalesWidget />
        <StoresWidget />
      </div>
      <OrdersWidget />
    </section>
  )
}

export default DashOverview
