import React, { useState, useEffect, useContext, useRef } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import Deck from '../organisms/Deck.tsx'
import Paragraph1 from "../../components/atoms/text/Paragraph1"
import Overline from "../../components/atoms/text/Overline.tsx"
import Header1 from "../../components/atoms/text/Header1.tsx"
import Header2 from "../../components/atoms/text/Header2.tsx"
import FavHeart from '../../entrypoints/images/favorited-heart.svg'
import UnfavHeart from '../../entrypoints/images/unfavorited-heart.svg'
import FormatMoney from "../../utils/FormatMoney"
import { ProductsContext } from "../../contexts/Products"
import { CurrentUserContext } from '../../contexts/CurrentUser.js'
import axios from 'axios'
import CtaButton from '../atoms/buttons/CtaButton.tsx'
import TikTok from "~/entrypoints/images/tiktok-icon.svg";
import Insta from "~/entrypoints/images/insta-icon.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUpRightFromSquare, faDollarSign, faStore} from '@fortawesome/free-solid-svg-icons'
import RequestForSample from '../molecules/RequestForSample.tsx'
import ProductNav from '../molecules/ProductNav.jsx'
import ProductDetailsImages from '../molecules/ProductDetailsImages.jsx'

const ProductDetails = () => {
  let [product, setProduct] = useState()
  let location = useLocation()
  let { currentUser } = useContext(CurrentUserContext)

  let params = useParams()

  let { favorites, setFavorites } = useContext(ProductsContext)
  let [products, setProducts] = useState([])
  let [brand, setBrand] = useState(null)
  let [retailersOpen, setRetailersOpen] = useState(false)

  let favorite = () => {
    if (product.favorited) {
      axios.delete('/favorites/' + product.id).then(() => {
        product.favorited = false
        setFavorites(favorites.filter((f) => f.id !== product.id))
      })
    } else {
      axios.post(`/favorites`, {id: product.id}).then(() => {
        product.favorited = !product.favorited
        setFavorites([...favorites, product])
      })
    }
  }

  const getProduct = () => {
    axios.get(`/marketplace/products/${params.product_id}`).then((pr) => {
      if (currentUser?.retailer) {
        axios.get('/favorites').then((fr) => {
          let faves = fr.data.products.map((f) => f.id)
          let prod = pr.data
          prod.favorited = faves.includes(pr.data.id)
          setProduct(prod)
        })
      } else {
        setProduct(pr.data)
      }
      getBrand(pr.data.brand.id)
    })
  }

  const getProducts = () => {
    axios.get(`/marketplace/products/for_brand/${params.brand_id}`).then((pr) => {
      axios.get('/favorites').then((fr) => {
        let ps = pr.data
        let fs = fr.data.products
        if (currentUser && !!!currentUser.brand?.id) {
          ps = ps.map((p) => {
            if (fs.map((f) => f.id).includes(p.id)) {
              p.favorited = true
            }
            return p
          })
        }
        setProducts(ps)
        setFavorites(fs)
        getProduct(params.product_id)
        let p = ps.find((p) => p.id === params.product_id)
      })
    })
  }

  const getBrand = (id) => {
    axios.get(`/marketplace/brands/${id}`).then((r) => {
      setBrand(r.data)
    })
  }

  useEffect(() => {
    setProduct(null)
    getProducts()
  }, [location])

  return (
    <section className={'mt-4 px-12 h-full w-full 2xl:w-[1440px] 2xl:mx-auto 2xl:p-0'}>
      <ProductNav productName={product?.name} />

      <div className={'flex'}>
        <section className={'w-1/2 lg:w-3/5 mr-2'}>
          <ProductDetailsImages images={product?.images} />
          {/* Description */}
          <div className={'mt-4 bg-white p-3 rounded-2xl'}>
            <div className={'border-b border-blue mb-2'}>
              <Header2>Product Description</Header2>
            </div>
            <p className='text-sm font-reg' dangerouslySetInnerHTML={{__html: product?.description}}></p>
          </div>
        </section>
        <section className={'w-1/2 lg:w-2/5 bg-white p-3 rounded-2xl ml-2'}>
          {/* Details */}
          <section className={''}>
              <header className={'w-full pb-2 mb-2 border-b border-blue'}>
                <div className={'w-full flex justify-between mb-2'}>
                  <Header1 styles={''}>{product?.name}</Header1>
                  { currentUser?.retailer &&
                    <button className={'ml-2 h-10 w-10 shrink-0 flex justify-start'} onClick={() => {favorite()}}>
                      <img src={ product?.favorited ? FavHeart : UnfavHeart } title={ product?.favorited ? 'Remove from favorites' : 'Add to favorites'} alt={ product?.favorited ? 'favorited' : 'not favorited'} className={`h-10 w-10 ${!product?.favorited && 'animate-pulse'}`}/>
                    </button>
                  }
                </div>
                <div className={'flex justify-between items-end'}>
                  <div>
                    <Link to={`/all_brands/${product?.brand.id}`} className={'flex'}>
                      <Header2>{product?.brand?.name}</Header2>
                    </Link>
                    <div className={'flex'}>
                      <p className={'text-almost-black text-xl font-med mr-2'}>MSRP:</p>
                      <p className={'text-almost-black text-xl font-reg mr-6'}>{`$${FormatMoney(product?.msrp_cents)}`}</p>
                      {
                        currentUser?.retailer &&
                        <React.Fragment>
                          <p className={'text-almost-black text-xl font-med mr-2'}>IMU:</p>
                          <p className={'text-almost-black text-xl font-reg'}>{(product?.brand?.curated && product?.brand?.name == 'MadeMeBuyIt') ? '70%' : '~60%'}</p>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </header>
              {/* CTAs */}
              {
                currentUser?.retailer &&
                <div className={'flex flex-wrap mt-4'}>
                  <div className={'w-80 mb-2'}>
                    <RequestForSample brandId={product?.brand_id} productId={product?.id}/>
                  </div>
                  <div className={'w-80'}>
                    <CtaButton id={'product-display-favorites-button'} onclick={() => favorite()} width={'w-full'}>{product?.favorited ? 'Remove from Favorites' : "I'm interested!"}</CtaButton>
                  </div>
                </div>
              }

            <div className={'flex flex-wrap'}>

              <div className={'my-4'}>
                <Header2>Product Details</Header2>
                <div className={'grid grid-cols-2 gap-x-2 gap-y-1 border-t pt-2 border-blue'}>
                  { product?.length && product?.width && product?.height && window.matchMedia("(min-width: 1024px").matches ?
                    <p className={'w-44 text-almost-black text-sm font-bold'}>Dimensions (L x W x H):</p>
                    : product?.length && product?.width && product?.height &&
                    <div>
                      <p className={'w-44 text-almost-black text-sm font-bold'}>Dimensions</p>
                      <p className={'w-44 text-almost-black text-sm font-bold'}>(L x W x H):</p>
                    </div>
                  }
                  { product?.length && product?.width && product?.height && <Paragraph1>{`${product?.length} x ${product?.width} x ${product?.height} inches`}</Paragraph1> }

                  { product?.weight && <p className={'w-44 text-almost-black text-sm font-bold'}>Weight:</p> }
                  { product?.weight && <Paragraph1>{`${product?.weight}`} {product?.weight_unit}</Paragraph1> }

                  { product?.inner_quantity && <p className={'w-44 text-almost-black text-sm font-bold'}>Inner Quantity:</p> }
                  { product?.inner_quantity && <Paragraph1>{product?.inner_quantity}</Paragraph1> }

                  { product?.inner_count && <p className={'w-44 text-almost-black text-sm font-bold'}>Number of Inners:</p> }
                  { product?.inner_count && <Paragraph1>{product?.inner_count}</Paragraph1> }

                  { product?.outer_quantity && <p className={'w-44 text-almost-black text-sm font-bold'}>Outer Quantity:</p> }
                  { product?.outer_quantity && <Paragraph1>{product?.outer_quantity}</Paragraph1> }

                  { product?.lead_time_weeks && <p className={'w-44 text-almost-black text-sm font-bold'}>Lead Time:</p> }
                  { product?.lead_time_weeks &&  <Paragraph1>{`${product?.lead_time_weeks} week${product?.lead_time_weeks === 1 ? "" : "s"}`}</Paragraph1> }

                </div>
              </div>
              <div className={'mb-4'}>
                <Link to={`/all_brands/${product?.brand.id}`} className={'flex items-center'}>
                  <Header2 styles={'mr-4'}>Brand Details</Header2>
                  <FontAwesomeIcon icon={faUpRightFromSquare} className={'h-4 w-4 text-pink'} />
                </Link>
                <div className={'grid grid-cols-2 gap-x-2 gap-y-1 pt-2 border-t border-blue'}>
                  <Link to={`https://www.tiktok.com/${brand?.tiktok_handle}`} target={'_blank'} className={'w-44 flex items-center'}>
                    <img src={TikTok} alt={'tiktok logo'} className={'h-4 w-4 mr-2'}/>
                    <p className={'text-almost-black text-sm font-bold'}>TikTok:</p>
                  </Link>
                  <Paragraph1>{`${brand?.tiktok_count} followers`}</Paragraph1>

                  <Link to={`https://www.instagram.com/${brand?.insta_handle}`} target={'_blank'} className={'w-44 flex items-center'}>
                    <img src={Insta} alt={'tiktok logo'} className={'h-4 w-4 mr-2'}/>
                    <p className={'text-almost-black text-sm font-bold'}>Instagram</p>
                  </Link>
                  <Paragraph1>{`${brand?.instagram_count} followers`}</Paragraph1>

                  <div className={'w-44 flex items-center'}>
                    <FontAwesomeIcon icon={faDollarSign} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>Annual Sales:</p>
                  </div>
                  <Paragraph1>{brand?.annual_sales}</Paragraph1>

                  <div className={'w-44 flex items-start'}>
                    <FontAwesomeIcon icon={faStore} className={'h-4 w-4 mr-2'} />
                    <p className={'text-almost-black text-sm font-bold'}>Sold In:</p>
                  </div>
                  <div>
                    {
                      brand?.current_retailer_relationships.length ?
                        // <Paragraph1>{brand.current_retailer_relationships.join(", ")}</Paragraph1>
                        brand?.current_retailer_relationships.map((crr) => {
                          return <Paragraph1>{crr}</Paragraph1>
                        })
                        :
                        <Paragraph1>Undisclosed</Paragraph1>
                    }
                  </div>
                </div>
              </div>

            </div>

          </section>
        </section>
      </div>
      {/* More Products */}
      <div className={'mt-4 py-4 bg-white bg-opacity-60 grid place-content-center'}>
        <Deck headerText={'More from this brand'} products={products} />
      </div>
    </section>
  )
}

export default ProductDetails
