import React from 'react'
import { Link } from 'react-router-dom'

const CTA = ({ id, link, children }: { id:string, link:string, children:any }) => {

  return (
    <div className={`h-12 w-full border-b-4 border-pink rounded-full bg-pink hover:bg-inherit`}>
      <Link to={link || '/'} id={id} >
        <div className={`
        h-12 w-full flex items-center justify-center border-2 border-pink rounded-full bg-white text-pink text-xl font-med transform -translate-y-px transitional-all
        hover:transition-all hover:-translate-x-px hover:translate-y-px
        `}>
        { children }
        </div>
      </Link>
    </div>

  )
}

export default CTA
