import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ elem }: {elem?: string}) => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation()

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    elem ? elem.scrollTo(0, 0) : window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop;
