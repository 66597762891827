import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const ProductNav = ({ productName }) => {

  const lgScreen = window.matchMedia("(min-width: 1024px")
  const xlScreen = window.matchMedia("(min-width: 1280px")
  const xxlScreen = window.matchMedia("(min-width: 1536px")

  let [clippedName, setClippedName] = useState(productName)

  useEffect(() => {
    setClippedName(productName)
    let maxChars = (xxlScreen.matches) ? 320 : (xlScreen.matches) ? 135 : (lgScreen.matches) ? 100 : 60
    if (productName?.length > maxChars + 3) {
      setClippedName(productName.slice(0, maxChars) + "...")
    }
  }, [productName])

  return (
    <nav className={'flex text-almost-black text-sm font-reg mb-2'}>
      <Link to={'/marketplace'} className={'hover:underline'}>Home</Link>
      <p className={'mx-2'}>/</p>
      <Link to={'/all_products'} className={'hover:underline'}>All Products</Link>
      <p className={'mx-2'}>/</p>
      <p title={productName}>{clippedName}</p>
    </nav>
  )
}

export default ProductNav
