import React from 'react'

const CtaButton = ({ id, submit, onclick, disabled, children }: { id:string, submit?:boolean, onclick?:Function, disabled?:boolean, children?:any }) => {
  return (
    <div className={`h-12 w-full border-b-4 border-blue rounded-full bg-blue`}>
        <button
          id={id}
          type={submit ? 'submit' : ''}
          onClick={onclick}
          className={`
            h-full w-full border-2 border-pink rounded-full bg-light-pink font-med text-dark-gray text-xl transform translate-x-px transitional-all
            hover:transition-all hover:-translate-x-px hover:translate-y-px
            disabled:bg-gray disabled:text-almost-black disabled:border-none
          `}
          disabled={disabled || false}
        >
          { children }
        </button>
    </div>
  )
}

export default CtaButton
