import React from 'react'
import Header1 from '../atoms/text/Header1'

export default function EmailSelector({emails, setSelectedEmail}) {
  return (
    <>
    <Header1 styles='ml-10 mt-10'>Select Email</Header1>
    <div className='ml-10'>
      <select onChange={(e) => setSelectedEmail(e.target.value)}>
        <option value="">Select an Email</option>
        {emails.map((email) => <option key={email} value={email}>{email}</option>)}
      </select>
    </div>
    </>
  )
}
