import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header3 from '../../atoms/text/Header3.tsx'
import Header2 from '../../atoms/text/Header2.tsx'
import Paragraph1 from '../../atoms/text/Paragraph1.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft, faCircleRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Logo from "~/entrypoints/images/logo240.webp";
import Header1 from "~/components/atoms/text/Header1";
import ReactGA from 'react-ga4'
import { CurrentUserContext } from '../../../contexts/CurrentUser.js'


const RetailerWelcome = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] =
    document.querySelector("[name=csrf-token]").content

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  const location = useLocation()
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
  }, [location])

  const navigate = useNavigate()

  let steps = [
    {
      header: 'Step 1 of 3: Guide to Shopping on MadeMeBuyIt',
      title: 'Discover Trending Gen Z and TikTok Brands',
      description: 'Browse products and brands by scrolling through the Marketplace and All Products tabs, or use the search bar to find trending items.',
      gif: 'https://ik.imagekit.io/mmbi/static/step1.gif?tr=w-960,h-540'
    }, {
      header: 'Step 2 of 3: Guide to Shopping on MadeMeBuyIt',
      title: "'Favorite' products that you're interested in",
      description: '',
      gif: 'https://ik.imagekit.io/mmbi/static/step2.gif?tr=w-960,h-540'
    }, {
      header: 'Step 3 of 3: Guide to Shopping on MadeMeBuyIt',
      title: 'Get a quote',
      description: "Click 'Get Me a a Quote' to receive pricing in 24 hours.",
      gif: 'https://ik.imagekit.io/mmbi/static/step3.gif?tr=w-960,h-540'
    }
  ]

  let [currentStep, setCurrentStep] = useState(0)
  let [link, setLink] = useState(null)

  let moveBackward = () => {
    setCurrentStep(currentStep - 1)
  }
  let moveForward = () => {
    console.log("CURRENT STEP: ", currentStep)
    console.log("Steps length", steps.length)
    if (currentStep === steps.length - 1) {
      navigate(link)
    } else if (currentStep === steps.length - 2 && currentUser?.email) {
       statusUserOnboarded()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const statusUserOnboarded = () => {
    console.log("function")
    axios.put('/me', {status: 'onboarded'}).then(() => {
      console.log("then")
      let user = currentUser
      user.status = "onboarded"
      setCurrentUser(user)
      setCurrentStep(currentStep + 1)
    })
  }

  useEffect(() => {
    axios.get('/me').then((res) => {
      res.data.email ? setLink('/all_products') : setLink('/')
    }).catch((err) => {
      setLink('/')
    })
  }, [])

  useEffect(() => {
    console.log("link: ", link)
  }, [link])

  return (
    <section className={'h-screen flex flex-col'}>
      <header className="flex border-b-2 border-gray h-14">
        <Link to={'/'} className={'w-1/4 flex justify-start items-center'}>
          <img src={Logo} alt={"made me buy it logo"} className='w-32 ml-6'/>
        </Link>
        <div className={'w-2/4 grid place-content-center'}>
          <Header1>{ steps[currentStep].header }</Header1>
        </div>
      </header>
      <main className='flex h-[calc(100%-56px)]'>
        <section className={'h-full w-full bg-[url("/entrypoints/images/background-colors.svg")] bg-contain bg-center bg-no-repeat overflow-y-scroll overflow-x-hidden'}>
          <section className={'h-full w-full mx-auto mt-6 flex flex-col items-center'}>
            <Header2 styles={'w-[960px] text-center'}>{ steps[currentStep].title }</Header2>
            <Paragraph1 styles={'h-10 w-[960px] text-center mt-6 mb-2'}>{ steps[currentStep].description }</Paragraph1>
            <div className={'flex items-center'}>
              <div className={`h-64 w-36 grid place-items-center relative ${currentStep === 0 && 'invisible'}`}>
                <button onClick={moveBackward} className={`h-24 w-24 rounded-full bg-blue grid place-content-center`}>
                  <FontAwesomeIcon icon={faCircleLeft} className={'h-24 w-24 transform -translate-x-1.5 -translate-y-1.5 transition-transform hover:translate-x-0 hover:translate-y-0 bg-white rounded-full text-pink'}/>
                </button>
                <Header3 styles={'absolute transform translate-y-16 text-center'}>{`Previous: Step ${currentStep}`}</Header3>
              </div>
              <img src={steps[currentStep].gif} className={'h-[540px] w-[960px] mx-6 shadow-xl'} />
              <div className={'h-64 w-36 grid place-items-center relative'}>
                <button onClick={moveForward} id={'retailers-welcome-next-button'} className={`h-24 w-24 rounded-full bg-blue grid place-content-center`}>
                  <FontAwesomeIcon icon={faCircleRight} className={'h-24 w-24 transform -translate-x-1.5 -translate-y-1.5 transition-transform hover:translate-x-0 hover:translate-y-0 bg-white rounded-full text-pink'}/>
                </button>
                <Header3 styles={'absolute transform translate-y-16 text-center'}>{`${currentStep === steps.length - 1 ? 'To All Products' : `Next: Step ${currentStep + 2}`}`}</Header3>
              </div>
            </div>
            <p className={'text-almost-black text-2xl font-reg mt-2'}>{`${currentStep + 1} of ${steps.length}`}</p>
          </section>
        </section>
      </main>
    </section>
  )
}

export default RetailerWelcome
