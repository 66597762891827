import React from "react"

function Header3({ styles, children }: { styles?: string, children: string }) {

  return (
    <h3 className={`text-almost-black text-base font-bold ${styles}`}>
      { children }
    </h3>
  )
}

export default Header3
