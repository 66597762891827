import React from 'react'

const CTA = ({ id, submit, onclick, disabled, children }: { id:string, submit?:boolean, onclick?:Function, disabled?:boolean, children?:any }) => {

  return (
    <div className={`h-12 w-full border-b-4 border-pink rounded-full bg-pink hover:bg-inherit`}>
        <button
          id={id}
          type={submit ? 'submit' : ''}
          onClick={onclick}
          className={`
            h-12 w-full flex items-center justify-center border-2 border-pink rounded-full bg-white text-pink text-xl font-med transform -translate-y-px transitional-all
            hover:transition-all hover:-translate-x-px hover:translate-y-px
          `}
          disabled={disabled || false}
        >
          { children }
        </button>
    </div>

  )
}

export default CTA
